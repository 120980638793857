import React, { useState } from 'react'
import styled from 'styled-components'

const Menu = styled.div`
  margin-bottom: 1rem;
  font-size: 14px;
  font-family: ${props => props.theme.type.new};
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  text-transform: uppercase;
  border-top: 1px solid rgba(0,0,0,0.12);
  padding: 1rem 0 0;
`;

const Content = styled.div`
  max-height: ${props => props.expanded ? `500px` : 0 };
  transition: 0.2s all ease-in-out;
  overflow: hidden;
  font-size: 13px;
`;

const ExpandableMenu = ({ children, title, border }) => {

  const [expanded, setExpanded] = useState(false)

  return (
    <Menu>
      <Title border={border} onClick={() => setExpanded(!expanded)}>{title}<span>{expanded ? `-` : `+` }</span></Title>
      <Content expanded={expanded}>{children}</Content>
    </Menu>
  )
}

export default ExpandableMenu;
