import { CREATE_CHECKOUT, ADD_TO_CART, FETCH_CHECKOUT, UPDATE_ITEMS, REMOVE_ITEMS } from '../actions/CartActions';

export default function( state = {}, action) {
  switch(action.type) {
    case CREATE_CHECKOUT:
      return action.payload
    case ADD_TO_CART:
      return action.payload
    case FETCH_CHECKOUT:
      return action.payload
    case UPDATE_ITEMS:
      return action.payload
    case REMOVE_ITEMS:
      return action.payload
    default:
      return state;
  }
}
