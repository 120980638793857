import React from "react";
import styled from "styled-components";
import Moment from "react-moment";
import Button from "./Button";
import { device } from "../utils/devices";
import ReactPlayer from "react-player";

const Wrapper = styled.div`
  height: 60vh;
  position: relative;

  @media (orientation: landscape) {
    height: 100vh;
  }

  @media ${device.laptop} {
    height: 60vh;
    display: flex;
    justify-content: space-between;
  }

  picture {
    height: 80vh;
    text-align: right;

    @media ${device.laptop} {
      height: 60vh;
      width: 50vw;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      opacity: 0.85;

      @media ${device.laptop} {
        opacity: 1;
      }
    }
  }
`;

const Info = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 60vh;
  z-index: 2;
  color: white;
  padding: 5vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (orientation: landscape) {
    height: 100vh;
    width: 100% !important;
    transform: none;
  }

  @media ${device.laptop} {
    height: 60vh;
    position: relative;
    padding: 2rem;
    background: white;
    width: 50vw !important;
    flex-shrink: 0;
    color: rgb(51, 51, 51);
  }

  h2 {
    font-family: ${props => props.theme.type.heading};
    text-transform: uppercase;
    font-size: 36px;
    line-height: 36px;
    margin: 0;

    @media ${device.laptop} {
      font-size: 10vh;
      line-height: 10vh;
      max-width: 50vw;
    }
  }

  span {
    font-family: ${props => props.theme.type.new};
    padding: 2rem 0;
    display: block;
    font-size: 24px;
    text-transform: uppercase;
    letter-spacing: 0.25em;

    @media ${device.laptop} {
      font-size: 20px;

      &:nth-of-type(2n) {
        padding-top: 0;
      }
    }
  }
`;

const Video = styled.div`
  /* background: black; */

  div {
    width: 100% !important;
    height: 100% !important;

    video {
      object-fit: cover;
    }
  }
`;

const Event = ({ venue, image, title, time, link, video }) => (
  <Wrapper>
    <Info>
      <span>
        <Moment format="llll">{time}</Moment>
      </span>
      <h2>{title}</h2>
      <span>{venue}</span>
      {link && (
        <a target="_blank" rel="noopener noreferrer" href={link.url}>
          <Button>Tickets &nbsp; &nbsp; ↗</Button>
        </a>
      )}
    </Info>
    {video ? (
      <Video>
        <ReactPlayer
          playsInline
          playsinline
          loop
          playing
          muted
          config={{
            file: {
              attributes: {
                playsInline: true
              }
            }
          }}
          url={video.url}
        />
      </Video>
    ) : (
      <picture>
        <img src={image} alt={title} />
      </picture>
    )}
  </Wrapper>
);

export default Event;
