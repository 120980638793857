import { FETCH_BLOG_POSTS, FETCH_BLOG_CATEGORY, FETCH_MORE_BLOG_POSTS } from '../actions/DiaryActions'

export default function( state = [], action) {
  switch(action.type) {
    case FETCH_BLOG_POSTS:
      return action.payload;
    case FETCH_MORE_BLOG_POSTS:
      return [...state, ...action.payload];
    case FETCH_BLOG_CATEGORY:
      return action.payload
    default:
      return state;
  }
}
