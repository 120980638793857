import {
  TOGGLE_CART,
  OPEN_CART,
  CLOSE_CART,
  TOGGLE_NAV,
  OPEN_NAV,
  CLOSE_NAV
} from "../actions/NavActions";

export default function(state = { cartOpen: false, navOpen: false }, action) {
  switch (action.type) {
    case TOGGLE_CART:
      return { cartOpen: !state.cartOpen, navOpen: state.navOpen };
    case OPEN_CART:
      return { cartOpen: true, navOpen: false };
    case CLOSE_CART:
      return { cartOpen: false, navOpen: state.navOpen };
    case TOGGLE_NAV:
      return { navOpen: !state.navOpen, cartOpen: state.cartOpen };
    case OPEN_NAV:
      return { navOpen: true, cartOpen: false };
    case CLOSE_NAV:
      return { navOpen: false, cartOpen: state.cartOpen };
    default:
      return state;
  }
}
