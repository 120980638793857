import React from 'react'
import styled from 'styled-components'
import { device } from '../utils/devices'

const TeamList = styled.div`
  width: 90vw;
  margin: 2rem auto;
  font-family: ${props => props.theme.type.new};

  @media ${device.laptop}{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2rem;
  }

  h2 {
    grid-column: span 4;
    text-transform: uppercase;
    font-size: 36px;
    text-align: center;
  }
`;

const Member = styled.div`
  text-align: center;
  margin-bottom: 2rem;

  @media ${device.laptop}{
    margin: 0;

    &:hover {
      img {
        transform: scale(0.8);
      }

      h5, p {
        opacity: 1;
        transform: translate(0, -2rem);
      }
    }
  }

  img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    will-change: transform;
    transition: 0.2s all ease-in-out;
    margin-bottom: 0.5rem;

    @media ${device.laptop}{
      height: 30vw;
      margin: 0;
    }
  }

  h5 {
    font-size: 24px;
    margin: 0 0 1rem;
  }

  h5, p {
    transition: 0.2s all ease-in-out;

    @media ${device.laptop}{
      opacity: 0;
    }
  }
`;

const Team = ({ data }) => (
  <TeamList>
    <h2>Our Team</h2>
    {data.map((member, index) => {
      const { name, title, photo } = member;
      return (
        <Member key={index}>
          <img src={photo.url} alt={name} />
          <h5>{name[0].text}</h5>
          <p>{title[0].text}</p>
        </Member>
      )
    })}
  </TeamList>
);

export default Team;
