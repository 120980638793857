import React, { useState } from "react";
import styled from "styled-components";
import ReactPlayer from "react-player";
import Swiper from "react-id-swiper";
import ProjectNext from "./ProjectNext";
import { useDispatch, useSelector } from "react-redux";
import { device } from "../utils/devices";
import { CLOSE_NAV } from "../redux/actions/NavActions";
import { Picture } from "react-responsive-picture";

const Wrapper = styled.div`
  display: flex;

  .swiper-container {
    height: 100vh;
    width: 100%;

    .swiper-slide {
      width: auto !important;
    }
  }
`;

const Video = styled.div`
  .player {
    width: auto !important;
    height: auto !important;
    background: black;
    cursor: ${props => (props.active ? `auto` : `pointer`)};

    video {
      object-fit: cover;
      pointer-events: ${props => (props.active ? `auto` : `none`)};
      width: 90vw !important;
      height: 100vh !important;
      opacity: ${props => (props.active ? 1 : 0.8)};
      transition: 0.2s all ease-in-out;
      cursor: pointer;
    }
  }
`;

const Title = styled.div`
  color: ${props => (props.shown ? `white` : `transparent`)};
  position: fixed;
  transition: 0.2s all ease-in-out;
  top: 2rem;
  pointer-events: none;
  left: 0;
  z-index: 9999;
  display: flex;
  text-shadow: ${props =>
    props.shown ? `0px 0px 20px rgba(0,0,0,0.2)` : `none`};
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  text-transform: uppercase;
  box-sizing: border-box;
  padding: 2rem;
  width: 40vw;

  @media ${device.laptop} {
    top: 0;
  }

  h1 {
    font-size: 12vh;
    line-height: 12vh;
    font-family: ${props => props.theme.type.heading};
    margin: 0;
  }

  span {
    font-size: 5vh;
    font-family: ${props => props.theme.type.new};
    margin-bottom: 2rem;

    @media ${device.laptop} {
      font-size: 2.5vh;
    }
  }
`;

const Spacer = styled.div`
  height: 100vh;

  span {
    display: block;
    background: red;
    width: 40vw !important;
  }
`;

const TextSlide = styled.div`
  padding: 4rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
`;

const Text = styled.div`
  width: 40vw;
  font-family: ${props => props.theme.type.new};
  min-width: 500px;

  span {
    text-transform: uppercase;
  }

  p {
    margin: 0;
    line-height: 1.3em;
  }

  h2 {
    font-size: 4vw;
    line-height: 4vw;
  }
`;

const GallerySlide = styled.div`
  cursor: zoom-in;

  img {
    height: 100vh;
  }
`;

const Progress = styled.progress`
  position: fixed;
  top: 72px;
  width: 420px;
  left: calc(50% - 210px);
  box-sizing: border-box;
  height: 5px;
  z-index: 800;
  appearance: none;
  pointer-events: none;

  &::-webkit-progress-bar {
    background: transparent;
  }

  &::-webkit-progress-value {
    background: #5bc0eb;
  }
`;

const Details = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 1000;
  left: 0;
  width: 100vw;
  height: 100vh;
  cursor: zoom-out;
  transition: 0.3s all ease-in-out;
  transform: ${props => (props.open ? `translate(0,0)` : `translate(100%,0)`)};

  img {
    width: 100%;
    display: block;
    margin: 0;
  }
`;

const ProjectBody = ({ video, body, title, sku, next }) => {
  const dispatch = useDispatch();

  const nav = useSelector(state => state.nav);

  const [imagesReady, setImagesReady] = useState(false);
  const [videoActive, activateVideo] = useState(false);
  const [progress, setProgress] = useState(0);
  const [videoPlaying, playVideo] = useState(true);
  const [titleShown, hideTitle] = useState(true);
  const [muted, setMute] = useState(true);
  const [previewVisible, togglePreview] = useState(false);
  const [detailsOpen, toggleDetails] = useState(false);
  const [detailsImage, setDetailsImage] = useState("");
  const [swiper, setSwiper] = useState(null);

  const handleActivateVideo = () => {
    if (!videoActive) {
      setMute(false);
    }
    activateVideo(true);
    hideTitle(false);
    playVideo(true);
  };

  const handleImageZoom = src => {
    setDetailsImage(src);
    toggleDetails(true);
  };

  const renderGallery = items => {
    return items.map((image, index) => {
      const { small, medium, large } = image.gallery_image;
      return (
        <GallerySlide
          className="swiper-slide"
          key={index}
          onClick={() => handleImageZoom(image.gallery_image.url)}
        >
          <picture>
            <Picture
              onLoad={() => {
                swiper.update();
              }}
              src={`${image.gallery_image.small.url} 480w,
                ${image.gallery_image.medium.url} 960w,
                ${image.gallery_image.large.url} 1440w,
                ${image.gallery_image.url}`}
              sizes="480w, 960w, 1440w, 2000w"
            />
          </picture>
        </GallerySlide>
      );
    });
  };

  const renderText = items => {
    return items.map((node, index) => {
      switch (node.type) {
        case "heading2":
          return <h2 key={index}>{node.text}</h2>;
        default:
          return <p key={index}>{node.text === "" ? <br /> : node.text}</p>;
      }
    });
  };

  const renderBody = () => {
    return body.map((node, index) => {
      switch (node.slice_type) {
        case "text":
          return (
            <TextSlide className="textblock" key={index}>
              <Text>
                <span>
                  {sku} / {title}
                </span>
                {renderText(node.primary.text)}
              </Text>
            </TextSlide>
          );
        case "gallery":
          return renderGallery(node.items);
        case "video":
          return (
            <Video key={index} playing={videoPlaying} active={videoActive}>
              <ReactPlayer
                className="player"
                playsInline={!videoPlaying}
                playsinline={!videoPlaying}
                onClick={() => handleActivateVideo()}
                playing={videoPlaying}
                loop
                controls={videoActive}
                url={node.primary.video.url}
                muted={muted}
                config={{
                  file: {
                    attributes: {
                      onContextMenu: e => e.preventDefault(),
                      controlsList: "nodownload"
                    }
                  }
                }}
              />
            </Video>
          );
        default:
          return false;
      }
    });
  };

  const swiperParams = {
    freeMode: true,
    direction: "horizontal",
    slidesPerView: "auto",
    spaceBetween: 0,
    roundLengths: true,
    preloadImages: true,
    updateOnImagesReady: true,
    mousewheel: {
      releaseOnEdges: true
    },
    observer: true,
    getSwiper: swiper => setSwiper(swiper),
    on: {
      slideChange: () => {
        activateVideo(false);
        togglePreview(false);
      },
      imagesReady: () => {
        setImagesReady(true);
      },
      // reachBeginning: () => {
      //   hideTitle(true);
      // },
      progress: progress => {
        setMute(true);
        setProgress(progress);

        dispatch({ type: CLOSE_NAV });

        if (progress > 0.08 && titleShown) {
          hideTitle(false);
        } else {
          hideTitle(true);
        }

        if (progress > 0.97 && !previewVisible) {
          togglePreview(true);
        }

        if (progress < 0.97) {
          togglePreview(false);
        }
      }
    }
  };

  return (
    <Wrapper videoActive={videoActive} playing={videoPlaying}>
      <Progress className="progress" value={progress} max="1" />
      <Details onClick={() => toggleDetails(false)} open={detailsOpen}>
        <img src={detailsImage} alt="active" />
      </Details>
      {body.length > 0 && (
        <>
          <Title shown={titleShown}>
            <span>{sku}</span>
            <h1>{title}</h1>
            <span>Scroll Right &rarr;</span>
          </Title>
          <Swiper {...swiperParams}>
            {renderBody()}
            <Spacer>
              <span />
            </Spacer>
          </Swiper>
          {typeof next !== "undefined" && (
            <ProjectNext
              visible={previewVisible}
              url={`/projects/${next.uid}`}
              sku={next.data.sku.padStart(3, "0")}
              image={next.data.featured_image.url}
              title={next.data.title[0].text}
            />
          )}
        </>
      )}
    </Wrapper>
  );
};

ProjectBody.propTypes = {};

export default ProjectBody;
