import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import CartItem from "./CartItem";
import Button from "./Button";
import { device } from "../utils/devices";
import { removeLineItem } from "../redux/actions/CartActions";

const Wrapper = styled.div`
  width: calc(100vw - 6rem);
  position: fixed;
  padding: 2rem;
  left: 1rem;
  top: calc(${props => props.theme.layout.headerHeight} + 2px);
  background: ${props => props.theme.color.primary};
  text-align: left;
  opacity: 0;
  transition: 0.2s all ease-in-out;
  box-shadow: 0px 1rem 10px rgba(0, 0, 0, 0.12);

  button {
    width: 100%;
  }

  @media (orientation: landscape) {
    top: calc(56px + 1rem);
    width: calc(420px - 4rem);
    left: calc(50% - 210px);
  }

  &.tray-enter {
    opacity: 0;
  }

  &.tray-enter-done {
    opacity: 1;
  }

  &.tray-exit {
    opacity: 0;
    background: red;
  }
`;

const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
  text-align: center;
  overflow: scroll;
  max-height: 60vh;

  @media (orientation: landscape) {
    max-height: 25vh;
  }

  @media ${device.laptop} {
    max-height: 50vh;
  }

  p {
    color: #717171;
  }
`;

const Total = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin: 2rem 0;
`;

class CartTray extends Component {
  state = {
    isLoaded: false
  };

  removeItem = item => {
    this.props.removeLineItem(this.props.cart.id, item);
  };

  renderItems() {
    return this.props.cart.lineItems.map((item, index) => {
      if (!this.state.isLoaded) {
        this.setState({ isLoaded: true });
      }
      return <CartItem key={index} removeItem={this.removeItem} item={item} />;
    });
  }

  render() {
    const { lineItems } = this.props.cart;

    return (
      <Wrapper {...this.props}>
        <List>
          {lineItems && lineItems.length > 0 ? (
            this.renderItems()
          ) : this.state.isLoaded ? (
            <p>
              Um, your cart is empty …{" "}
              <span aria-label="shrug" role="img">
                🤷
              </span>
            </p>
          ) : (
            <p>Loading …</p>
          )}
        </List>
        {lineItems && lineItems.length > 0 ? (
          <div>
            <Total>Total: ${this.props.cart.totalPrice}</Total>
            <a href={this.props.cart.webUrl}>
              <Button>Checkout</Button>
            </a>
          </div>
        ) : null}
      </Wrapper>
    );
  }
}

CartTray.propTypes = {
  cart: PropTypes.array.isRequired
};

export default connect(
  state => ({
    cart: state.cart,
    cartTray: state.cartTray
  }),
  { removeLineItem }
)(CartTray);
