import { FETCH_POSTS, FETCH_PROJECTS } from '../actions/PostActions';

export default function( state = {}, action) {
  switch(action.type) {
    case FETCH_PROJECTS:
      return action.payload;
    case FETCH_POSTS:
      return action.payload;
    default:
      return state;
  }
}
