import { useEffect, useState } from 'react'
import Prismic from 'prismic-javascript'

const apiEndpoint = 'https://foul-studios.cdn.prismic.io/api/v2'

const apiToken = 'MC5XNmZsdnljQUFDVUE0NHJv.77-977-977-9He-_vUdVfBnvv73vv70BD--_vRN8b--_vTbvv73vv70RMO-_ve-_ve-_vStZ77-977-9OGM';

const Client = Prismic.client(apiEndpoint, { apiToken })

const useEvents = () => {

  const [events, setEvents] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [pageSize, setPageSize] = useState(3)

  useEffect(() => {
    const fetchEvents = async () => {
      const response = await Client.query(
        Prismic.Predicates.at('document.type', 'event'),
        { orderings : '[my.event.time]', pageSize : pageSize }
      )

      if(response){
        setEvents(response.results)
        setLoaded(true)
      }
    }

    fetchEvents()
  }, [pageSize])

  return {
    loaded,
    events,
    pageSize,
    setPageSize
  }
}

export default useEvents
