import React from 'react';
import styled from 'styled-components';

const Icon = styled.div`
  width: 20px;
  height: 100%;
`;

const CartIcon = () => {
  return (
    <Icon>
      <svg viewBox="0 0 14 17" fill='#000'>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <g id="MSP_Bag_Empty" data-name="MSP Bag Empty">
            <path
              className="cls-1"
              d="M1.5 3A1.5 1.5 0 0 0 0 4.5v11A1.5 1.5 0 0 0 1.5 17h11a1.5 1.5 0 0 0 1.5-1.5v-11A1.5 1.5 0 0 0 12.5 3zM13 15.5a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 .5.5z"
              id="MSP_Bag_Empty-2"
              data-name="MSP Bag Empty"
            />
            <path
              className="cls-1"
              d="M10.75 4h-7.5v-.5A3.65 3.65 0 0 1 7 0a3.65 3.65 0 0 1 3.75 3.5zM4.3 3h5.4A2.72 2.72 0 0 0 7 1a2.72 2.72 0 0 0-2.7 2z"
            />
          </g>
        </g>
      </g>
    </svg>
    </Icon>
  )
}

export default CartIcon;
