import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { fetchBlog, fetchBlogFilter } from "../redux/actions/DiaryActions";
import DiaryPost from "../components/DiaryPost";
import DiaryFeature from "../components/DiaryFeature";
import { device } from "../utils/devices";
import { isBrowser } from "react-device-detect";
import { withRouter } from "react-router-dom";
import DiarySelect from "../components/DiarySelect";
import { Helmet } from "react-helmet";
import Button from "../components/Button";
import nah from "../assets/404.jpg";
import Footer from "../components/Footer";

const Wrapper = styled.div`
  min-height: 100vh;
`;

const DiaryFeatureLoader = styled.div`
  background: url(${nah});
  background-size: cover;
  color: white;
  font-family: ${props => props.theme.type.new};
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;

  @media ${device.laptop} {
    height: 80vh;
  }

  div {
    text-align: center;

    h1 {
      text-transform: uppercase;
    }
  }

  @media ${device.laptop} {
    height: 80vh;
  }
`;

const Feed = styled.div`
  padding: 0 1rem;
  margin-top: 8rem;

  @media (orientation: landscape) {
    margin: 8rem auto 5rem;
    display: grid;
    padding: 0 2rem;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5vw;
  }

  @media ${device.laptop} {
    margin: 8rem auto 5rem;
    display: grid;
    padding: 0 2rem;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2rem;
  }
`;

const LoadMore = styled.div`
  display: flex;
  justify-content: center;
  margin: 4rem 0;

  @media (orientation: landscape) {
    grid-column: span 2;
  }

  @media ${device.laptop} {
    grid-column: span 4;

    button {
      width: 29vw;
    }
  }
`;

const FilterList = styled.ul`
  display: flex;
  justify-content: center;
  padding: 0 0 2rem;
  list-style: none;
  grid-column: span 4;
  font-size: 18px;
  flex-wrap: wrap;
  padding: 0 1rem;
  line-height: 2em;

  @media ${device.laptop} {
    font-size: 1.5vw;
    font-weight: 200;
    font-family: ${props => props.theme.type.new};
    text-transform: uppercase;
    margin: 2rem 0 0 0;
    padding: 0 0 2rem 0;
  }

  li {
    margin-right: 1rem;
    cursor: pointer;
    font-weight: bold;
    transition: 0.2s all ease-in-out;

    &:last-of-type {
      margin: 0;
    }

    &:hover {
      transform: translate(0, -2px);
      color: ${props => props.hoverColor};
    }

    @media ${device.laptop} {
      margin-right: 4vw;
    }
  }
`;

class Diary extends Component {
  state = {
    posts: [],
    isLoaded: false,
    filter: "",
    page: 1,
    selectOpen: false
  };

  componentDidMount() {
    const colors = [
      `rgb(62,163,134)`,
      `rgb(178,103,0)`,
      `rgb(255,197,58)`,
      `rgb(92,135,65)`,
      `rgb(228,94,97)`
    ];

    const hoverColor = colors[Math.floor(Math.random() * colors.length)];

    this.props.fetchBlog(1).then(posts => {
      this.setState({ isLoaded: true, hoverColor: hoverColor });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.filter !== this.state.filter) {
      if (this.state.filter === "") {
        this.props.fetchBlog().then(posts => {
          console.log(posts);
          this.setState({ isLoaded: true });
        });
      } else {
        this.props.fetchBlogFilter(this.state.filter);
      }
    }
  }

  renderFeed = () => {
    return this.props.diaryPosts.map((post, index) => {
      const { data } = post;
      const { body } = data;

      let video = null;
      let embedObj = body.find(x => x.slice_type === "embed");

      if (embedObj) {
        video = embedObj.primary.url.embed_url;
      }

      let videoObj = body.find(x => x.slice_type === "video");
      if (videoObj) {
        video = videoObj.primary.video.url;
      }

      return (
        <DiaryPost
          key={index}
          uid={post.uid}
          date={post.first_publication_date}
          thumbnail={post.data.thumbnail}
          title={post.data.title[0].text}
          category={post.data.category}
          video={video}
        />
      );
    });
  };

  filterGrid = filter => {
    this.setState({ filter: filter });
  };

  toggleSelect = () => {
    console.log("clicked");
    this.setState({ selectOpen: !this.state.selectOpen });
  };

  handleLoadMore = () => {
    this.props.fetchBlog(this.state.page + 1).then(() => {
      this.setState({ page: this.state.page + 1 });
    });
  };

  render() {
    return (
      <Wrapper>
        <Helmet title="Random — Foul Studios" />
        {this.state.isLoaded && (
          <>
            {this.props.diaryPosts.length > 0 ? (
              <DiaryFeature post={this.props.diaryPosts[0]} />
            ) : (
              <DiaryFeatureLoader>
                <div>
                  <h1>Nothing here … Yet</h1>
                  <p>Check back soon</p>
                </div>
              </DiaryFeatureLoader>
            )}
            {isBrowser ? (
              <FilterList hoverColor={this.state.hoverColor}>
                <li
                  onClick={() => {
                    this.filterGrid("");
                  }}
                >
                  All
                </li>
                <li
                  onClick={() => {
                    this.filterGrid("Events");
                  }}
                >
                  Events
                </li>
                <li
                  onClick={() => {
                    this.filterGrid("Music");
                  }}
                >
                  Music
                </li>
                <li
                  onClick={() => {
                    this.filterGrid("News");
                  }}
                >
                  News
                </li>
                <li
                  onClick={() => {
                    this.filterGrid("Art & Design");
                  }}
                >
                  Art & Design
                </li>
                <li
                  onClick={() => {
                    this.filterGrid("Photography");
                  }}
                >
                  Photography
                </li>
                <li
                  onClick={() => {
                    this.filterGrid("Travel");
                  }}
                >
                  Travel
                </li>
                <li
                  onClick={() => {
                    this.filterGrid("Releases");
                  }}
                >
                  Releases
                </li>
              </FilterList>
            ) : (
              <DiarySelect
                open={this.state.selectOpen}
                current={this.state.filter}
                openSelect={this.toggleSelect}
                handleFilter={this.filterGrid}
              />
            )}
            {this.state.isLoaded && (
              <Feed>
                {this.renderFeed()}
                {this.props.diaryPosts.length > 0 &&
                  this.props.diaryPosts.length % 8 === 0 && (
                    <LoadMore>
                      <Button onClick={this.handleLoadMore}>
                        Load More Posts
                      </Button>
                    </LoadMore>
                  )}
              </Feed>
            )}
          </>
        )}
        <Footer padded />
      </Wrapper>
    );
  }
}

export default withRouter(
  connect(
    state => ({
      diaryPosts: state.diaryPosts
    }),
    { fetchBlog, fetchBlogFilter }
  )(Diary)
);
