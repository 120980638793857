import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { device } from "../utils/devices";
import Share from "./Share";
import Moment from "react-moment";
import ReactPlayer from "react-player";
import ArticleGallery from "./ArticleGallery";

const Wrapper = styled.article`
  padding: 0;

  @media ${device.laptop} {
    padding: 4rem 2rem;
  }
`;

const Intro = styled.div`
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url(${props => props.image});
  background-size: cover;
  background-position: center center;

  @media (orientation: landscape) {
    height: 80vh;
  }

  @media ${device.laptop} {
    height: 80vh;
    background-attachment: fixed;
  }
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: 600;
  color: white;
  font-family: ${props => props.theme.type.heading};
  text-transform: uppercase;
  margin: 0;
  text-align: center;

  @media ${device.laptop} {
    font-size: 4vw;
  }
`;

const Date = styled.span`
  display: block;
  color: white;
  margin: 1.5rem 0 0.5rem;
  font-family: ${props => props.theme.type.new};

  @media ${device.laptop} {
    font-size: 1.5vw;
  }
`;

const Body = styled.div`
  picture {
    margin: 0;
    display: block;

    @media ${device.laptop} {
      grid-column: span 1;
    }

    img {
      max-width: 100%;
      margin: 0 auto;
      display: block;
      cursor: pointer;
    }
  }
`;

const Embed = styled.div`
  margin: 4rem 0;
  outline: 0;

  .player {
    margin: 0 auto;
    width: 90vw !important;
    height: 50.625vw !important;
    outline: 0;

    @media ${device.laptop} {
      width: 66.6vw !important;
      height: 37.46vw !important;
    }

    video {
      outline: 0;
    }
  }
`;

const Text = styled.div`
  width: 90vw;
  margin: 0 auto;

  @media ${device.laptop} {
    width: calc(60vw - 2rem);
  }

  @media ${device.desktop} {
    width: 66vw;
  }

  p {
    font-size: 18px;
    line-height: 1.5em;
    font-family: ${props => props.theme.type.secondary};
    font-smoothing: antialiased;

    img {
      display: block;
      width: 100%;
      margin: 1rem auto 2rem;
      cursor: pointer;

      @media ${device.laptop} {
        margin: 3rem auto 4rem;
      }
    }
  }
`;

const Gallery = styled.div`
  font-family: ${props => props.theme.type.secondary};
  margin: 2rem auto;
  width: 90vw;

  @media (orientation: landscape) {
    width: 90vw;
    display: grid;
    grid-template-columns: ${props =>
      props.rows && `repeat(${props.rows}, 1fr)`};
    grid-gap: 2rem;
    margin: 4rem auto;
  }

  @media ${device.laptop} {
    width: auto;
    margin: 4rem 0;
  }

  img {
    width: 100%;
    cursor: pointer;
  }

  span {
    display: block;
    color: rgba(51, 51, 51, 0.5);
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 2rem;
    padding: 0.5rem 0;

    @media ${device.laptop} {
      font-size: 16px;
      padding: 1rem 0;
    }
  }
`;

const Quote = styled.div`
  width: 90vw;
  margin: 2rem auto;
  font-size: 24px;
  font-family: ${props => props.theme.type.secondary};

  @media ${device.laptop} {
    width: calc(50vw - 2rem);
    margin: 4rem auto;
    font-size: 48px;
    line-height: 48px;
  }

  @media ${device.desktop} {
    width: calc(33.3vw - 2rem);
  }

  blockquote {
    padding: 0;
    margin: 0;

    @media ${device.laptop} {
      text-indent: -23px;
    }

    cite {
      font-style: normal;
      font-size: 16px;

      @media ${device.laptop} {
        font-size: 24px;
      }
    }
  }
`;

const Credit = styled.div`
  width: 90vw;
  margin: 6rem auto 4rem;
  text-align: center;
  font-family: ${props => props.theme.type.new};
  font-size: 16px;
  color: rgb(51, 51, 51);
`;

const ArticleBody = props => {
  const [galleryLoaded, setGalleryLoaded] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [galleryIndex, setGalleryIndex] = useState(2);

  useEffect(() => {
    const buildGallery = () => {
      return props.body.map(node => {
        switch (node.slice_type) {
          case "text":
            return node.primary.text.map(paragraph => {
              if (paragraph.url) {
                return setGalleryImages(prevValues => [
                  ...prevValues,
                  paragraph.url
                ]);
              }
            });
            break;
          case "gallery":
            return node.items.map(item => {
              return setGalleryImages(prevValues => [
                ...prevValues,
                item.image.url
              ]);
            });
          default:
            return true;
        }
      });
    };

    if (!galleryLoaded) {
      buildGallery();
    }
  }, [galleryLoaded]);

  const handleOpenGallery = useCallback(e => {
    const index = Number(e.target.dataset.index);
    setGalleryIndex(index - 1);
    props.handleGalleryOpen();
  }, []);

  const renderBody = () => {
    let counter = 0;
    return props.body.map((node, index) => {
      switch (node.slice_type) {
        case "text":
          return (
            <Text key={index}>
              {node.primary.text.map((paragraph, paragraphIndex) => {
                switch (paragraph.type) {
                  case "image":
                    counter++;
                    return (
                      <p key={paragraphIndex}>
                        <img
                          src={paragraph.url}
                          data-index={counter}
                          alt={paragraph.alt || props.title}
                          onClick={e => handleOpenGallery(e)}
                        />
                      </p>
                    );
                  default:
                    return <p key={index}>{paragraph.text}</p>;
                }
              })}
            </Text>
          );
        case "gallery":
          return (
            <Gallery
              key={index}
              rows={node.items.length > 4 ? 4 : node.items.length}
            >
              {node.items.map((item, itemIndex) => {
                counter++;
                return (
                  <div key={itemIndex}>
                    <img
                      src={item.image.url}
                      data-index={counter}
                      alt={item.image.alt || props.title}
                      onClick={e => handleOpenGallery(e)}
                    />
                    {item.image.alt && <span>{item.image.alt}</span>}
                  </div>
                );
              })}
            </Gallery>
          );
        case "quote":
          return (
            <Quote>
              <blockquote>
                {node.primary.quote.map(paragraph => (
                  <p>{paragraph.text}</p>
                ))}
                <cite>{node.primary.name_of_the_author[0].text}</cite>
              </blockquote>
            </Quote>
          );
        case "video":
          console.log(node);
          return (
            <Embed>
              <ReactPlayer
                className="player"
                controls
                url={node.primary.video.url}
              />
            </Embed>
          );
        // default = embed
        default:
          return (
            <Embed key={index}>
              <ReactPlayer
                className="player"
                url={node.primary.url.embed_url}
              />
            </Embed>
          );
      }
    });
  };

  return (
    <>
      {props.galleryOpen && (
        <ArticleGallery
          images={galleryImages}
          startAt={galleryIndex}
          onProgress={props.handleGalleryProgress}
          handleClose={() => {
            setGalleryIndex(0);
            props.handleGalleryClose();
          }}
        />
      )}
      <Intro image={props.image}>
        <Date>
          <Moment format="MMMM DD, Y">{props.date}</Moment>
        </Date>
        <Title>{props.title}</Title>
      </Intro>
      <Wrapper>
        <Body>{renderBody()}</Body>
        <Credit>
          {props.credit &&
            props.credit.map((node, index) => (
              <p key={index}>{node.text === "" ? <br /> : node.text}</p>
            ))}
        </Credit>
        <Share image={props.image} title={props.title} />
      </Wrapper>
    </>
  );
};

ArticleBody.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.array.isRequired
};

export default ArticleBody;
