import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import CartIcon from './CartIcon'
import styled from 'styled-components'

const Wrapper = styled.div`
  height: 100%;
  right: 0;
  z-index: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  transition: 0.15s all ease-in-out;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  padding: 1rem 1rem 1rem 0;
  align-items: center;

  a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
`;

const Count = styled.span`
  position: absolute;
  top: 0;
  width: 20px;
  height: 100%;
  margin-top: 2px;
  font-size: 14px;
  font-family: Helvetica, Arial;
  display: flex;
  align-items: center;
  justify-content: center;
`;

class Cart extends Component {

  state = {
    isLoaded: false
  }

  calculateCart = () => {
    let items = this.props.cart.lineItems;

    var initialValue = 0;
    var sum = items.reduce(function (accumulator, currentValue) {
        return accumulator + currentValue.quantity;
    },initialValue)

    if(sum > 0){
      return <span>{sum}</span>
    }
  }

  render(){

    const { lineItems } = this.props.cart;

    return (
      <Wrapper {...this.props}>

        <CartIcon/>
        <Count>
          {lineItems
            ?
              this.calculateCart()
            :
              null
          }
        </Count>
      </Wrapper>
    )
  }
}

Cart.propTypes = {
  cart: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
}

export default connect(state => ({
  cart: state.cart
}), {  })(Cart);
