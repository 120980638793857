import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import {
  createCheckout,
  addToCart,
  fetchCheckout
} from "../redux/actions/CartActions";
import { fetchSingleProduct } from "../redux/actions/ProductActions";
import { openCart } from "../redux/actions/NavActions";
import { withRouter } from "react-router-dom";
import SizeChart from "../components/SizeChart";
import DeliveryInfo from "../components/DeliveryInfo";
import ProductInfo from "../components/ProductInfo";
import { device } from "../utils/devices";
import { isBrowser } from "react-device-detect";
import moment from "moment";
import ReactPlayer from "react-player";
import Footer from "../components/Footer";
import RelatedProducts from "../components/RelatedProducts";
import PinchZoomPan from "react-responsive-pinch-zoom-pan";
import MobileProductSlider from "../components/MobileProductSlider";
import { withOrientationChange } from "react-device-detect";

const Wrapper = styled.div`
  background: url(${props => props.background});
  background-attachment: fixed;
  background-size: ${props =>
    props.backgroundSize === "Cover" ? `cover` : `auto`};
  background-repeat: ${props =>
    props.backgroundSize === "Cover" ? `no-repeat` : `repeat`};
`;

const ProductWrapper = styled.div`
  width: 90vw;
  padding-top: 6rem;
  margin: 0 auto 2rem;

  @media (orientation: landscape) {
    width: 95vw;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "image info" "image ." "image .";
    grid-gap: 2rem;
  }

  @media ${device.laptop} {
    width: auto;
    min-height: calc(100vh - 5vh - 2rem);
    grid-template-columns: 6fr 4fr;
    grid-gap: 2rem;
  }

  .slick-slider {
    margin-bottom: 1rem;

    .slick-track {
      height: 40vh;
    }

    .slick-track > div {
      height: 100%;

      & > div {
        height: 100%;
      }
    }

    .image_container {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .item {
      height: 100%;
      box-sizing: border-box;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .slick-dots {
    display: flex !important;
    padding: 0;
    margin: 2rem 0;
    justify-content: center;

    li {
      list-style: none;
      margin-right: 6px;

      &:last-of-type {
        margin: 0;
      }

      &.slick-active {
        button {
          opacity: 1;
        }
      }

      button {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        padding: 0;
        text-indent: -9999em;
        background: black;
        border: 0;
        opacity: 0.3;
      }
    }
  }
`;

const Video = styled.div`
  cursor: ${props => (props.zoomed ? "zoom-out" : "zoom-in")};
  height: ${props => (props.zoomed ? `56.25vw` : `100%`)};

  .player {
    height: calc(100vw - 4rem) !important;
    width: ${props =>
      props.zoomed ? `calc(100vw - 4rem) !important` : `100% !important`};

    @media ${device.laptop} {
      height: 100% !important;
      width: ${props =>
        props.zoomed ? `100vw !important` : `calc(100vh - 8rem) !important`};
      margin: 2rem auto 2rem;
    }

    video {
      object-fit: cover;

      @media ${device.laptop} {
        object-fit: contain;
      }
    }
  }
`;

const Images = styled.ul`
  margin: ${props => (props.zoomed ? "0" : "2rem 0")};
  left: 0;
  padding: ${props => (props.zoomed ? "0" : "0 0 0 2rem")};
  list-style: none;
  position: ${props => (props.zoomed ? "fixed" : "static")};
  width: ${props => (props.zoomed ? "100vw" : "auto")};
  height: ${props => (props.zoomed ? "100vh" : "auto")};
  overflow: ${props => (props.zoomed ? "scroll" : "auto")};
  background: white;
  cursor: ${props => (props.zoomed ? "zoom-out" : "zoom-in")};
  box-sizing: border-box;
  z-index: ${props => (props.zoomed ? 999 : "auto")};
  top: 0;

  @media (orientation: landscape) {
    padding: 0;
  }

  span {
    position: fixed;
    right: 2.5rem;
    top: 1rem;
    font-size: 36px;
    font-family: ${props => props.theme.type.new};
    cursor: pointer;
  }

  li {
    margin-bottom: 2rem;

    &:last-of-type {
      margin: 0;
    }

    .image_container {
      transition: 0.2s all ease-in-out;
      overflow: hidden;

      img {
        width: 100%;
        object-fit: cover;
      }
    }
  }
`;

const ImageLoader = styled.div`
  width: 100%;
  height: 70vh;
  background: rgba(0, 0, 0, 0.1);
`;

const Details = styled.div`
  @media ${device.laptop} {
    grid-area: info;
    position: sticky;
    top: 80px;
    align-self: flex-start;
    height: calc(100vh - 90px);
    display: flex;
    align-items: start;
    top: 100px;
    overflow: scroll;
    height: calc(100vh - 5vh - 2rem);
  }

  @media ${device.laptopL} {
    top: 120px;
  }
`;

const MobileZoom = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: white;
  z-index: 999;

  span {
    position: fixed;
    right: 2.5rem;
    top: 1rem;
    font-size: 36px;
    font-family: ${props => props.theme.type.new};
    cursor: pointer;
    z-index: 999;
  }
`;

const MobileInfo = styled.div`
  width: 90vw;
  margin: 2rem auto;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 1rem 0;

  @media (orientation: landscape) {
    border: 0;
    width: calc(46.5vw - 0.5rem);
    margin: 1rem 2.5vw 0 auto;
    box-sizing: border-box;
    min-width: calc(90vh + 0.5rem);

    .desc {
      font-size: 14px;
      margin-bottom: 1rem;
    }
  }

  p {
    line-height: 1.8em;
  }
`;

class Product extends Component {
  constructor(props) {
    super(props);
    this.items = [];

    this.state = {
      product: [],
      sku: "",
      isLoaded: false,
      releaseDate: null,
      image: 0,
      zoomed: "",
      size: "",
      sizeSelected: false,
      color: "",
      variant: "",
      video: "",
      videoPlaying: true,
      variantSelected: false,
      hide_related: false
    };
  }

  handleZoom = () => {
    this.setState({ zoomed: !this.state.zoomed });
  };

  componentDidMount() {
    this.props.fetchSingleProduct(this.props.match.params.handle).then(res => {
      let releaseDate = null;
      if (moment(res.release_date).valueOf() > Date.now()) {
        releaseDate = res.release_date;
      }
      this.setState({
        product: res.product,
        sku: res.sku,
        background: res.background_image.url,
        backgroundSize: res.background_size,
        relatedProducts: res.related_products,
        extraImages: res.extra_images,
        video: res.video.url ? res.video.url : "",
        releaseDate: releaseDate,
        hide_related: res.hide_related === "Yes" ? true : false,
        isLoaded: true,
        allowRestockNotification:
          res.allow_restock_subscription === "Yes" ? true : false,
        textColor: res.color,
        color: res.product.options[0].values[0],
        image: res.product.images[0].src
      });
    });
  }

  handleThumbClick = index => {
    this.items[index].scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  };

  renderImages() {
    const images = [...this.state.product.images, ...this.state.extraImages];
    console.log(images);
    return images.map((image, index) => {
      return (
        <li
          className="item"
          onClick={
            isBrowser
              ? this.handleZoom
              : () => {
                  this.setState({ image: index });
                  this.handleZoom();
                }
          }
          key={index}
          id={image.variant_ids ? image.variant_ids[0] : null}
          ref={ref => (this.items[index] = ref)}
        >
          <div className="image_container" id="index">
            <img src={image.src ? image.src : image.image.url} alt={index} />
          </div>
        </li>
      );
    });
  }

  handleColorChange = color => {
    this.setState({ color: color }, () => {
      if (this.state.sizeSelected) {
        let selectedVariant = this.state.product.variants.find(
          variant => variant.title === `${color} / ${this.state.size}`
        );
        this.setState({ variant: selectedVariant.id, variantSelected: true });
      }
    });
  };

  handleSizeChange = size => {
    this.setState({ size: size, sizeSelected: true }, () => {
      let selectedVariant = this.state.product.variants.find(
        variant => variant.title === `${this.state.color} / ${size}`
      );
      if (selectedVariant) {
        this.setState({ variant: selectedVariant.id, variantSelected: true });
      } else {
        // If we didn't find 'Color / Size', try just the size
        let selectedVariant = this.state.product.variants.find(
          variant => variant.title === `${size}`
        );
        this.setState({ variant: selectedVariant.id, variantSelected: true });
      }
    });
  };

  handleClick = () => {
    // Check if we have a checkout and cart created
    if (this.props.cart.lineItems) {
      // Set the first variant as selected
      let variantId = btoa(
        `gid://shopify/ProductVariant/${this.state.product.variants[0].id}`
      );

      // Check if there are multiple variants
      if (this.state.product.variants.length > 1) {
        // If there are then, use state to send the correct variant ID to the addToCart function
        variantId = btoa(`gid://shopify/ProductVariant/${this.state.variant}`);
      }
      this.props.addToCart(this.props.cart.id, variantId).then(() => {
        this.props.openCart();
      });
    } else {
      // Otherwise, create one
      this.props
        .createCheckout()
        .then(() => {
          // Set the first variant as selected
          let variantId = btoa(
            `gid://shopify/ProductVariant/${this.state.product.variants[0].id}`
          );

          // Check if there are multiple variants
          if (this.state.product.variants.length > 1) {
            // If there are then, use state to send the correct variant ID to the addToCart function
            variantId = btoa(
              `gid://shopify/ProductVariant/${this.state.variant}`
            );
          }

          this.props.addToCart(this.props.cart.id, variantId);
        })
        .then(() => {
          this.props.openCart();
        });
    }
  };

  render() {
    const { product, variantSelected } = this.state;

    let inventory = 0;

    if (this.state.isLoaded) {
      // if (product.variants.length > 1) {
      //   inventory = 10;
      // } else {
      //   console.log("only one variant");
      //   inventory = product.variants[0].inventory_quantity;
      //   console.log("inventory", product.options[0]);
      // }

      inventory = product.variants.reduce(
        (a, b) => +a + +b.inventory_quantity,
        0
      );
    }

    if (this.state.variantSelected) {
      inventory = product.variants.find(
        variant => variant.id === this.state.variant
      ).inventory_quantity;
    }

    const { isLoaded } = this.state;

    return (
      <Wrapper
        background={this.state.background}
        backgroundSize={this.state.backgroundSize}
      >
        <ProductWrapper>
          {isBrowser ||
          window.matchMedia("(orientation: landscape)").matches ? (
            <Images
              id="image_container"
              zoomed={this.state.zoomed}
              onClick={this.handleZoom}
            >
              {this.state.zoomed && <span onClick={this.handleZoom}>×</span>}
              {this.state.video !== "" && (
                <Video onClick={this.handleZoom} zoomed={this.state.zoomed}>
                  <ReactPlayer
                    className="player"
                    playing
                    muted
                    playsInline
                    loop
                    url={this.state.video}
                  />
                </Video>
              )}
              {isLoaded ? this.renderImages() : <ImageLoader />}
            </Images>
          ) : (
            <>
              {this.state.zoomed && (
                <MobileZoom>
                  <span onClick={this.handleZoom}>x</span>
                  <PinchZoomPan
                    initialScale={0.5}
                    position="center"
                    zoomButtons={false}
                  >
                    <img
                      src={
                        this.state.product.images[this.state.image]
                          ? this.state.product.images[this.state.image].src
                          : this.state.extraImages[0].image.url
                      }
                      alt="0"
                    />
                  </PinchZoomPan>
                </MobileZoom>
              )}
              <MobileProductSlider dots>
                {this.state.video !== "" && (
                  <Video
                    onClick={() =>
                      this.setState({ videoPlaying: !this.state.videoPlaying })
                    }
                    zoomed={this.state.zoomed}
                  >
                    <ReactPlayer
                      className="player"
                      playing={this.state.videoPlaying}
                      muted
                      playsInline
                      loop
                      config={{
                        file: {
                          attributes: {
                            playsInline: true
                          }
                        }
                      }}
                      url={this.state.video}
                    />
                  </Video>
                )}
                {isLoaded ? this.renderImages() : <ImageLoader />}
              </MobileProductSlider>
            </>
          )}
          <Details className="details">
            {isLoaded ? (
              <ProductInfo
                title={product.title}
                colorOptions={product.options[1] ? product.options[0] : null}
                sizeOptions={
                  product.options[1] ? product.options[1] : product.options[0]
                }
                sku={this.state.sku}
                variants={product.variants}
                currentValue={this.state.variant}
                textColor={this.state.textColor}
                variantSelected={variantSelected}
                description={product.body_html}
                handleThumbClick={this.handleThumbClick}
                images={this.state.product.images}
                onSizeChange={this.handleSizeChange}
                onColorChange={this.handleColorChange}
                addToCart={this.handleClick}
                inventory={inventory}
                allowRestockNotification={this.state.allowRestockNotification}
                size={this.state.size}
                color={this.state.color}
                releaseDate={this.state.releaseDate}
              />
            ) : (
              <ImageLoader />
            )}
            {isLoaded &&
              !isBrowser &&
              window.matchMedia("(orientation: landscape)").matches && (
                <MobileInfo>
                  <div
                    className="desc"
                    dangerouslySetInnerHTML={{ __html: product.body_html }}
                  />
                  <SizeChart />
                  <DeliveryInfo />
                </MobileInfo>
              )}
          </Details>
        </ProductWrapper>
        {isLoaded && !this.state.hide_related && (
          <RelatedProducts
            category={this.props.match.params.category}
            relatedProducts={this.state.relatedProducts}
          />
        )}
        <Footer padded />
      </Wrapper>
    );
  }
}

export default withOrientationChange(
  withRouter(
    connect(
      state => ({
        cart: state.cart
      }),
      { fetchSingleProduct, openCart, addToCart, createCheckout, fetchCheckout }
    )(Product)
  )
);
