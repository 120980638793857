import Client from "shopify-buy";

const client = Client.buildClient({
  domain: "checkout.foulstudios.com",
  storefrontAccessToken: "3f418e60345d08eeedc0985e59a50db2"
});

export const CREATE_CHECKOUT = "create_checkout";
export const ADD_TO_CART = "add_to_cart";
export const FETCH_CHECKOUT = "fetch_checkout";
export const REMOVE_ITEMS = "remove_items";
export const UPDATE_ITEMS = "update_items";

export function createCheckout() {
  return dispatch => {
    // Create an empty checkout
    return client.checkout.create().then(checkout => {
      return dispatch({
        type: CREATE_CHECKOUT,
        payload: checkout
      });
    });
  };
}

export function addToCart(checkoutId, item) {
  console.log(item);

  const lineItemsToAdd = [
    {
      variantId: item,
      quantity: 1
    }
  ];

  console.log(lineItemsToAdd);

  return dispatch => {
    // Create an empty checkout
    return client.checkout
      .addLineItems(checkoutId, lineItemsToAdd)
      .then(checkout => {
        return dispatch({
          type: ADD_TO_CART,
          payload: checkout
        });
      })
      .catch(err => {
        console.log(err);
      });
  };
}

export function fetchCheckout(checkoutId) {
  return dispatch => {
    // Create an empty checkout
    return client.checkout.fetch(checkoutId).then(checkout => {
      return dispatch({
        type: FETCH_CHECKOUT,
        payload: checkout
      });
    });
  };
}

export function updateLineItem(checkoutId, lineItemsToUpdate) {
  return dispatch => {
    return client.checkout
      .updateLineItems(checkoutId, lineItemsToUpdate)
      .then(checkout => {
        return dispatch({
          type: UPDATE_ITEMS,
          payload: checkout
        });
      });
  };
}

export function removeLineItem(checkoutId, lineItemIdsToRemove) {
  return dispatch => {
    return client.checkout
      .removeLineItems(checkoutId, lineItemIdsToRemove)
      .then(checkout => {
        return dispatch({
          type: REMOVE_ITEMS,
          payload: checkout
        });
      });
  };
}
