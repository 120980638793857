import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { device } from '../utils/devices'

const StyledButton = styled.button`
  outline: 0;
  padding: 1rem;
  height: 3vw;
  min-height: 60px;
  outline: 0;
  width: 100%;
  background: #3e3e3e;
  border: 2px solid #3e3e3e;
  color: #CCC;
  font-family: ${props => props.theme.type.heading};
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  grid-row: 5;
  grid-column: 0;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  position: relative;
  z-index: 10;
  box-shadow: 0px 2px 10px rgba(0,0,0,0.12);
  pointer-events: all;
  border-raduis: 0;

  @media ${device.laptop}{
    width: 10vw;
    min-width: 200px;
  }

  &:enabled {
    background: black;
    border: 2px solid black;
    color: white;

    &:hover {
      background: white;
      color: black;
    }
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

const Button = (props) => {
  return (
    <StyledButton {...props}>
      {props.children}
    </StyledButton>
  )
}

Button.propTypes = {
  children: PropTypes.node.isRequired
}

export default Button;
