import React from "react";
import Feed from "../components/Feed";
import styled from "styled-components";
import Feature from "../components/Feature";
import { Helmet } from "react-helmet";
import HomeTicker from "../components/HomeTicker";

const Main = styled.main``;

const Home = props => {
  return (
    <Main>
      <Helmet title="Foul Studios | A New Culture of Sport">
        <meta property="og:url" content="https://foulstudios.com" />
        <meta property="og:type" content="product" />
        <meta property="og:title" content="Foul Studios" />
        <meta
          property="og:description"
          content="Foul Studios is a sports lifestyle brand and multidisciplinary creative studio based in NYC"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@foulstudios" />
      </Helmet>
      <Feature />
      <Feed />
    </Main>
  );
};

export default Home;
