import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { fetchProducts } from "../redux/actions/ProductActions";
import { device } from "../utils/devices";
import { Helmet } from "react-helmet";
import ShopProduct from "../components/ShopProduct";
import Footer from "../components/Footer";
import Button from "../components/Button";
import { Link } from "react-router-dom";
import print from "../assets/court.jpg";

const Wrapper = styled.div`
  padding: 12rem 0 3rem;
  width: 90vw;
  margin: 0 auto;
  min-height: 100vh;

  @media ${device.laptop} {
    width: 70vw;
  }
`;

const Background = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;

  @media ${device.laptop} {
    /* background-size: cover;
    background-position: center center;
    background-attachment: fixed; */
  }
`;

const Grid = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  a {
    text-decoration: none;
  }
`;

const ArchiveLink = styled.div`
  background: url(${print});
  background-size: cover;
  color: white;
  padding: 2rem 5vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: ${props => props.theme.type.new};

  p {
    margin-bottom: 2rem;
    font-size: 16px;

    @media ${device.laptop} {
      font-size: 1.5vw;
    }

    @media ${device.desktop} {
      font-size: 1vw;
    }
  }

  button {
    background: transparent !important;
    border: 2px solid white !important;

    &:hover {
      background: white;
    }
  }

  h5 {
    font-size: 18px;
    text-transform: uppercase;
    margin: 0;

    @media ${device.laptop} {
      font-size: 2vw;
    }

    @media ${device.desktop} {
      font-size: 1.5vw;
    }
  }
`;

class Shop extends Component {
  state = {
    isLoaded: false,
    filter: "none"
  };

  componentDidMount() {
    this.props.fetchProducts().then(() => {
      this.setState({ isLoaded: true });
    });
  }

  renderProducts = () => {
    let products = this.props.products.results;

    return products.map((product, index) => {
      const { variants, title, images } = product.data.product;
      const { release_date, type, video } = product.data;
      const { uid } = product;

      return (
        <ShopProduct
          key={index}
          variants={variants}
          handle={uid}
          type={type.toLowerCase()}
          title={title}
          video={video}
          images={images}
          releaseDate={release_date}
          showVariants
        />
      );
    });
  };

  render() {
    return (
      <>
        <Wrapper>
          <Helmet title="Shop — Foul Studios" />
          {this.state.isLoaded ? <Grid>{this.renderProducts()}</Grid> : null}
        </Wrapper>
        <ArchiveLink>
          <h5>Foul Studios Archive</h5>
          <p>Open for Play</p>
          <Link to="/archive">
            <Button>View Archive</Button>
          </Link>
        </ArchiveLink>
        <Footer padded />
      </>
    );
  }
}

export default connect(
  state => ({
    products: state.products
  }),
  { fetchProducts }
)(Shop);
