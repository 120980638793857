import React from "react";
import Swiper from "react-id-swiper";
// Version >= 2.4.0
import "swiper/css/swiper.css";
import styled from "styled-components";

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: white;
  cursor: pointer;
  z-index: 5;

  picture {
    width: auto !important;
    height: 100vh;
    box-sizing: border-box;
    padding: 5vh;

    img {
      height: 100%;
      object-fit: contain;
    }
  }
`;

const ArticleGallery = ({ images, handleClose, startAt, onProgress }) => (
  <Wrapper onClick={handleClose}>
    <Swiper
      freeMode
      mousewheel
      slidesPerView="auto"
      initialSlide={startAt}
      on={{ progress: value => onProgress(value) }}
    >
      {images.map(image => {
        return (
          <picture key={image}>
            <img src={image} />
          </picture>
        );
      })}
    </Swiper>
  </Wrapper>
);

export default ArticleGallery;
