import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FiPlus, FiMinus } from 'react-icons/fi';
import { updateLineItem, removeLineItem } from '../redux/actions/CartActions';
import { device } from '../utils/devices';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;

  @media ${device.laptop}{
    width: 5vw;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
  }
`;

const Plus = styled.div`
  border: 1px solid black;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
  min-width: 20px;
`;

const Minus = styled.div`
  border: 1px solid black;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  cursor: pointer;
  min-width: 20px;
  opacity: ${props => props.disabled ? '.5' : '1' };
  pointer-events: ${props => props.disabled ? 'none' : 'all' };
`;

const Quantity = styled.div`
  border-top: 1px solid black;
  border-bottom: 1px solid black;
`;

class Counter extends Component {

  state = {
    quantity: this.props.quantity
  }

  handlePlus = () => {
    const checkoutId = this.props.cart.id;

    this.setState({ quantity: this.state.quantity + 1}, () => {

      const lineItemsToUpdate = [
        {id: this.props.id, quantity: this.state.quantity }
      ]

      this.props.updateLineItem(checkoutId, lineItemsToUpdate);
    })
  }

  handleMinus = () => {
    const checkoutId = this.props.cart.id;

    if(this.state.quantity === 1){
      this.props.removeLineItem(checkoutId, this.props.id)
    } else {
      this.setState({ quantity: this.state.quantity - 1}, () => {
        const lineItemsToUpdate = [
        {id: this.props.id, quantity: this.state.quantity }
      ]

      this.props.updateLineItem(checkoutId, lineItemsToUpdate);
      })
    }
  }

  render(){

    const { quantity } = this.state;

    return (
      <Wrapper>
        <Minus disabled={quantity < 1} onClick={this.handleMinus}><FiMinus/></Minus>
        <Quantity>{quantity}</Quantity>
        <Plus onClick={this.handlePlus}><FiPlus /></Plus>
      </Wrapper>
    )
  }
}

Counter.propTypes = {
  cart: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  updateLineItem: PropTypes.func.isRequired
}

export default connect(state => ({
  cart: state.cart
}), { updateLineItem, removeLineItem })(Counter);
