import Prismic from 'prismic-javascript'
import prismic from '../../utils/Prismic'

export const FETCH_BLOG_POSTS = 'fetch_blog_posts'
export const FETCH_MORE_BLOG_POSTS = 'fetch_more_blog_posts'
export const FETCH_BLOG_CATEGORY = 'fetch_blog_category'

export function fetchBlog(page){
  return dispatch => {
    return prismic
    .then(api => {
      return api.query(Prismic.Predicates.at('document.type', 'post'),
         { orderings : '[document.first_publication_date desc]', pageSize: 8, page: page || 1 },
      )
    })
    .then(res => {
      if(page === 1){
        return dispatch({
          type: FETCH_BLOG_POSTS,
          payload: res.results
        })
      } else {
        return dispatch({
          type: FETCH_MORE_BLOG_POSTS,
          payload: res.results
        })
      }

    })
    .catch(err => {
      console.log(err)
    })
  }
}

export function fetchBlogFilter(filter){
  console.log(filter)
  return dispatch => {
    return prismic.then(api => {
      return api.query([
        Prismic.Predicates.at('document.type', 'post'),
        Prismic.Predicates.at('my.post.category', filter),
      ], { orderings : '[document.first_publication_date desc]' }
      )
    })
    .then(res => {
      console.log(res)
      return dispatch({
        type: FETCH_BLOG_CATEGORY,
        payload: res.results
      })
    })
  }
}
