import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import BG from '../assets/404-new.jpg';

const Wrapper = styled.div`
  height: calc(100vh - ${props => props.theme.headerHeight});
  padding-top: ${props => props.theme.headerHeight};
  background-image: url(${BG});
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NotFound = (props) => (
  <Wrapper>
    <Helmet title='404 — Foul Studios' />
  </Wrapper>
);

export default NotFound;
