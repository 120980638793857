import React from 'react'
import styled from 'styled-components'
import { device } from '../utils/devices'

const StyledMenu = styled.div`
  display: flex;
  z-index: 300;
  cursor: pointer;
  padding: 1rem 0 1rem 1rem;
  height: 100%;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  user-select: none;

  &:media ${device.laptop}{
    &:hover {
      span {
        &:first-of-type, &:nth-of-type(2){
          margin-bottom: ${props => props.open ? '3px' : '6px' }
        }
      }
    }
  }

  span {
    width: 20px;
    display: block;
    height: 2px;
    background: #212121;
    transition: 0.2s all ease-in-out;
    margin-bottom: 3px;

    &:first-of-type{
      transform-origin: top left;
      transform: rotate(${props => props.open ? '45deg' : '0' })
    }

    &:nth-of-type(2n){
      width: 24px;
      transition: 0.2s all ease-in-out;
      opacity: ${props => props.open ? '0' : '1' };
    }

    &:last-of-type{
      margin: 0;
      transform: ${props => props.open ? `rotate(-45deg) translate(0, -5px)` : `none`}
    }
  }
`;

const Menu = (props) => (
  <StyledMenu {...props}>
    <span />
    <span />
    <span />
  </StyledMenu>
);

export default Menu;
