import React, { Component } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { fetchProject, fetchProjects } from "../redux/actions/PostActions";
import ProjectBody from "../components/ProjectBody";
import { withOrientationChange } from "react-device-detect";
import ProjectBodyMobile from "../components/ProjectBodyMobile";

const Wrapper = styled.div``;

class Project extends Component {
  state = {
    project: [],
    isLoaded: false,
    next: ""
  };

  componentDidMount() {
    const uid = this.props.match.params.uid;
    this.props
      .fetchProject(uid)
      .then(project => {
        this.setState({
          project: project
        });
      })
      .then(() => {
        return this.props.fetchProjects();
      })
      .then(res => {
        let index = res.findIndex(x => x.uid === uid) + 1;
        if (typeof res[index] === "undefined") {
          index = 0;
        }
        this.setState({
          next: res[index],
          isLoaded: true
        });
      });
  }

  renderArticle = () => {
    const { project } = this.state;
    return (
      <>
        <Helmet title={`${project.data.title[0].text} — Foul Studios`}>
          <script type="application/ld+json">
            {`
          {
            "@context": "http://schema.org",
            "@type": "Article",
            "headline": "${project.data.title[0].text}",
            "image": "${project.data.featured_image.src}",
            "author": "Foul Studios",
            "publisher": "Foul Studios",
            "url": "${window.location}",
            "datePublished": "${project.first_publication_date}"
          }
        `}
          </script>
        </Helmet>
        {window.matchMedia("(orientation: portrait)").matches ? (
          <ProjectBodyMobile
            sku={`PRJ — ${project.data.sku.padStart(3, "0")}`}
            background={project.data.background}
            title={project.data.title[0].text}
            date={project.first_publication_date}
            body={project.data.body}
            video={project.data.video_url}
            next={this.state.next}
          />
        ) : (
          <ProjectBody
            sku={`PRJ — ${project.data.sku.padStart(3, "0")}`}
            background={project.data.background}
            title={project.data.title[0].text}
            date={project.first_publication_date}
            body={project.data.body}
            video={project.data.video_url}
            next={this.state.next}
          />
        )}
      </>
    );
  };

  render() {
    return (
      <Wrapper>{this.state.isLoaded ? this.renderArticle() : null}</Wrapper>
    );
  }
}

export default withOrientationChange(
  withRouter(
    connect(
      state => ({
        posts: state.posts
      }),
      { fetchProject, fetchProjects }
    )(Project)
  )
);
