import React from "react";
import styled from "styled-components";
import { device } from "../utils/devices";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
  poiner-events: all;

  @media ${device.laptop} {
    width: 10vw;
    min-width: 200px;
  }
`;

const List = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-columns: ${props =>
    props.length > 1 ? `repeat(${props.length}, 1fr)` : `none`};
  list-style: none;
  padding: 0;
  margin: 0 0 1rem;

  h5 {
    grid-column: span 3;
    margin: 0 0 0.5rem;
    text-transform: uppercase;
  }

  @media ${device.laptop} {
    h5 {
      grid-column: span 4;
    }
  }
`;

const Item = styled.li`
  padding: 1rem 0.5rem;
  cursor: pointer;
  text-align: center;
  font-family: ${props => props.theme.type.new};
  font-weight: bold;
  font-size: 14px;
  background: ${props => (props.selected ? "rgb(51,51,51)" : "white")};
  color: ${props =>
    props.selected
      ? `white`
      : props.available
      ? `rgb(51,51,51)`
      : `rgba(51,51,51,0.3)`};
  text-transform: uppercase;
  transition: 0.2s all ease-in-out;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 1px solid rgba(0, 0, 0, 0.12);

  @media ${device.laptopL} {
    font-size: 16px;
  }
  /*
  &:hover {
    transform: translate(0, -2px);
  } */

  &:first-of-type {
    border-left: 1px solid rgba(0, 0, 0, 0.12);
  }

  &:last-of-type {
    margin: 0;
  }
`;

const SizeSelect = ({
  variants,
  currentValue,
  onChange,
  options,
  value,
  color
}) => {
  let currentVariants = [...variants];

  if (options.position === 2) {
    currentVariants = variants.filter(x => x.option1 === color);
  }

  return (
    <Wrapper className="select-wrapper">
      <List length={options.values.length}>
        {currentVariants.slice(0, options.values.length).map(option => (
          <Item
            key={option.title}
            selected={
              option.option2
                ? value === option.option2
                : value === option.option1
            }
            onClick={() =>
              onChange(option.option2 ? option.option2 : option.option1)
            }
            option={option}
            available={option.inventory_quantity > 0}
          >
            {option.title.includes("/")
              ? option.title.split("/")[2]
                ? option.title.split("/")[2]
                : option.title.split("/")[1]
              : option.title}
          </Item>
        ))}
      </List>
    </Wrapper>
  );
};

export default SizeSelect;
