import React, { useState, useEffect, createRef } from "react";
import Slider from "react-slick";
import { withOrientationChange } from "react-device-detect";

const MobileProductSlider = ({ children, dots, centerMode, isLandscape }) => {
  let firstClientX, clientX;
  let containerRef = createRef();
  const [swiper, setSwiper] = useState(null);

  useEffect(() => {
    const touchStart = e => {
      firstClientX = e.touches[0].clientX;
    };

    const preventTouch = e => {
      const minValue = 5; // threshold

      clientX = e.touches[0].clientX - firstClientX;

      // Vertical scrolling does not work when you start swiping horizontally.
      if (Math.abs(clientX) > minValue) {
        e.preventDefault();
        e.returnValue = false;

        return false;
      }
    };

    let { current } = containerRef;

    if (current) {
      current.addEventListener("touchstart", touchStart);
      current.addEventListener("touchmove", preventTouch, {
        passive: false
      });
    }

    return () => {
      if (current) {
        current.removeEventListener("touchstart", touchStart);
        current.removeEventListener("touchmove", preventTouch, {
          passive: false
        });
      }
    };
  }, [window.innerHeight, window.innerWidth, containerRef]);

  return (
    <div ref={containerRef}>
      <Slider
        slidesToShow={1}
        getSwiper={setSwiper}
        centerMode={centerMode ? true : false}
        dots={dots ? true : false}
        arrows={false}
        grabCursor={true}
        rebuildOnUpdate
        shouldSwiperUpdate
        centerPadding={isLandscape ? "250px" : "60px"}
      >
        {children}
      </Slider>
    </div>
  );
};

export default withOrientationChange(MobileProductSlider);
