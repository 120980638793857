import React, { Component } from "react";
import "./App.css";
import styled, { ThemeProvider } from "styled-components";
import Header from "./components/Header";
import { Switch, Route, withRouter } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Home from "./pages/Home";
import Product from "./pages/Product";
import Article from "./pages/Article";
import About from "./pages/About";
import Shop from "./pages/Shop";
import Archive from "./pages/Archive";
import ShopCategory from "./pages/ShopCategory";
import Project from "./pages/Project";
import Projects from "./pages/Projects";
import Foundation from "./pages/Foundation";
import Diary from "./pages/Diary";
import Community from "./pages/Community";
import NotFound from "./pages/NotFound";
import Favicon from "react-favicon";
import Terms from "./pages/Terms";
import FAQ from "./pages/FAQ";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import faviconBlack from "./assets/favicon.png";

const Wrapper = styled.div`
  min-height: -fill-available;
`;

class App extends Component {
  render() {
    const theme = {
      color: {
        primary: "#FF6",
        sunglow: "rgb(255,197,58)",
        ginger: "rgb(178,103,0)",
        body: "#212121",
        secondary: `#5BC0EB`,
        green: `rgb(62,163,134)`
      },
      type: {
        body: `JHA, Times, "Times New Roman", serif`,
        heading: "CH, Helvetica, Arial, sans-serif",
        secondary: `'Favorit', Helvetica, Arial, sans-serif`,
        new: "Circular, Helvetica, Arial, sans-serif"
      },
      layout: {
        headerHeight: "56px"
      }
    };

    return (
      <div className="App">
        <ThemeProvider theme={theme}>
          <Wrapper>
            <Header />
            <Favicon url={[faviconBlack]} />
            <Route
              render={({ location }) => (
                <TransitionGroup>
                  <CSSTransition
                    key={location.key}
                    timeout={500}
                    classNames="fade"
                  >
                    <Switch location={location}>
                      <Route exact path="/" component={Home} />
                      <Route exact path="/shop" component={Shop} />
                      <Route
                        path="/shop/:category/:handle"
                        component={Product}
                      />
                      <Route path="/shop/:category" component={ShopCategory} />
                      <Route path="/projects/:uid" component={Project} />
                      <Route path="/projects" exact component={Projects} />
                      <Route path="/archive" component={Archive} />
                      <Route exact path="/lunch-break" component={Diary} />
                      <Route path="/random/:uid" component={Article} />
                      <Route path="/about" component={About} />
                      <Route path="/foundation" component={Foundation} />
                      <Route path="/terms" component={Terms} />
                      <Route path="/faqs" component={FAQ} />
                      <Route path="/privacy-policy" component={PrivacyPolicy} />
                      <Route path="/community" component={Community} />
                      <Route component={NotFound} />
                    </Switch>
                  </CSSTransition>
                </TransitionGroup>
              )}
            />
          </Wrapper>
        </ThemeProvider>
      </div>
    );
  }
}

export default withRouter(App);
