import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { fetchProductCategory } from "../redux/actions/ProductActions";
import { device } from "../utils/devices";
import { Helmet } from "react-helmet";
import ShopProduct from "../components/ShopProduct";

const Background = styled.div`
  width: 100vw;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
`;

const Wrapper = styled.div`
  padding: 12rem 0 3rem;
  width: 90vw;
  margin: 0 auto;

  @media ${device.laptop} {
    width: 70vw;
  }
`;

const Grid = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  a {
    text-decoration: none;
  }
`;

class Shop extends Component {
  state = {
    isLoaded: false
  };

  componentDidMount() {
    const { category } = this.props.match.params;

    this.props.fetchProductCategory(category).then(() => {
      console.log();
      this.setState({ isLoaded: true });
    });
  }

  renderProducts = () => {
    let products = this.props.products.results;

    return products.map((product, index) => {
      const { variants, handle, title, images } = product.data.product;
      const { release_date } = product.data;

      return (
        <ShopProduct
          key={index}
          variants={variants}
          handle={handle}
          title={title}
          images={images}
          releaseDate={release_date}
          showVariants
        />
      );
    });
  };

  render() {
    return (
      <Background>
        <Wrapper>
          <Helmet title="Shop — Foul Studios" />
          {this.state.isLoaded ? <Grid>{this.renderProducts()}</Grid> : null}
        </Wrapper>
      </Background>
    );
  }
}

export default connect(
  state => ({
    products: state.products
  }),
  { fetchProductCategory }
)(Shop);
