import React, { useState } from "react";
import styled from "styled-components";
import { device } from "../utils/devices";
import { isMobile } from "react-device-detect";
import ReactPlayer from "react-player";
import Button from "./Button";
import Moment from "react-moment";

const Card = styled.div`
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  position: relative;

  .player {
    position: ${props => (props.videoOpen ? `fixed` : `absolute`)};
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    transform: scale(1.5);
    transition: 0.2s all ease-in-out;
    z-index: ${props => (props.videoOpen ? `99999` : `0`)};

    iframe {
      pointer-events: none;
    }

    @media ${device.laptop} {
      opacity: 0;
      z-index: ${props => (props.videoOpen ? `99999` : `50`)};

      &:hover {
        opacity: 1;
      }
    }

    video {
      width: 100%;
      height: 100%;
      cursor: pointer;
      object-fit: cover;
    }
  }

  @media ${device.laptop} {
    margin: 0;
  }

  h2 {
    margin-bottom: 4rem !important;
  }

  h3 {
    font-size: 24px;
    margin: 1rem 0;
  }

  h4 {
    font-family: ${props => props.theme.type.new};
    font-weight: 200;
    font-size: 20px;
    text-transform: uppercase;
    margin: 0;
    display: flex;

    sup {
      display: flex;
      line-height: 36px;
      margin-left: 1rem;
      flex-direction: column;
    }
  }

  div {
    font-size: 16px;
    font-family: ${props => props.theme.type.new};
    line-height: 1.5em;
    margin-bottom: 1rem;
  }

  button {
    width: 10vw;
    background: white !important;
    color: rgb(51, 51, 51) !important;
    min-width: 200px;
  }

  picture {
    margin-bottom: 1rem;
    display: flex;
    position: relative;
    overflow: hidden;
    height: 90vw;

    @media (orientation: landscape) {
      height: 35vw;
    }

    @media ${device.laptop} {
      margin: 0;
      height: calc(30vw - 1.5rem);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 1;
    }
  }
`;

const EventCard = ({ date, image, title, venue, link, recap, description }) => {
  const [videoOpen, setVideoOpen] = useState(false);

  return (
    <Card videoOpen={videoOpen}>
      <h4 className="title">
        <Moment format="MMM">{date}</Moment>{" "}
        <sup>
          <Moment format="Do">{date}</Moment>
          <Moment className="small" format="Y">
            {date}
          </Moment>
        </sup>
      </h4>
      <picture onClick={() => setVideoOpen(!videoOpen)}>
        <img src={image} alt="event" />
        {recap && (
          <ReactPlayer
            className="player"
            onClick={() => setVideoOpen(!videoOpen)}
            url={recap.embed_url}
            playing={isMobile ? videoOpen : true}
            loop
            config={{
              youtube: {
                playerVars: { modestbranding: 1, playsinline: 1 }
              }
            }}
            playsinline
            muted={!videoOpen}
          />
        )}
      </picture>
      <div>
        <h3>{title}</h3>
        <h4>{venue}</h4>
        <div>
          {description.map(p => (
            <p>{p.text}</p>
          ))}
        </div>
        {recap ? (
          <Button onClick={() => setVideoOpen(true)}>
            Watch Recap &nbsp; &nbsp; ↗
          </Button>
        ) : (
          <Button>Tickets &nbsp; &nbsp; ↗</Button>
        )}
      </div>
    </Card>
  );
};

export default EventCard;
