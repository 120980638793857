import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import { fetchPost } from "../redux/actions/PostActions";
import ArticleBody from "../components/ArticleBody";
import RelatedPosts from "../components/RelatedPosts";
import { device } from "../utils/devices";

const Wrapper = styled.div`
  margin-top: calc();
`;

const Progress = styled.progress`
  position: fixed;
  top: 72px;
  left: 1rem;
  width: calc(100vw - 2rem);
  height: 5px;
  z-index: 800;
  appearance: none;
  pointer-events: none;

  @media (orientation: landscape) {
    left: calc(50vw - 210px);
    width: 420px;
  }

  @media ${device.laptop} {
    top: 72px;
    left: calc(50vw - 210px);
    width: 420px;
  }

  &::-webkit-progress-bar {
    background: transparent;
  }

  &::-webkit-progress-value {
    background: ${props =>
      props.type === `gallery`
        ? props.theme.color.sunglow
        : props.theme.color.secondary};
  }
`;

class Article extends Component {
  state = {
    article: [],
    isLoaded: false,
    galleryOpen: false,
    galleryProgress: 0,
    progress: window.pageYOffset
  };

  componentDidMount() {
    const uid = this.props.match.params.uid;
    this.props.fetchPost(uid).then(article => {
      this.setState({
        article: article,
        isLoaded: true
      });
    });
    window.addEventListener("scroll", this.listenToScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenToScroll);
  }

  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const scrolled = winScroll / height;

    this.setState({
      progress: scrolled
    });
  };

  compnentWillUnmount() {
    window.removeEventListener("scroll");
  }

  renderArticle = () => {
    const { article } = this.state;
    return (
      <ArticleBody
        image={article.data.thumbnail.url}
        title={article.data.title[0].text}
        credit={article.data.credit}
        date={article.first_publication_date}
        body={article.data.body}
        galleryOpen={this.state.galleryOpen}
        handleGalleryOpen={() => this.setState({ galleryOpen: true })}
        handleGalleryClose={() => this.setState({ galleryOpen: false })}
        handleGalleryProgress={value =>
          this.setState({ galleryProgress: value })
        }
      />
    );
  };

  render() {
    return (
      <Wrapper>
        <Progress
          className="progress"
          type={this.state.galleryOpen ? `gallery` : `page`}
          value={
            this.state.galleryOpen
              ? this.state.galleryProgress
              : this.state.progress
          }
          max="1"
        />
        {this.state.isLoaded ? (
          <>
            <Helmet
              title={`${this.state.article.data.title[0].text} — Foul Studios`}
            />
            {this.renderArticle()}
            <RelatedPosts uid={this.props.match.params.uid} />
          </>
        ) : null}
      </Wrapper>
    );
  }
}

export default withRouter(connect(state => ({}), { fetchPost })(Article));
