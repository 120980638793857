import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import styled from 'styled-components'
import { Waypoint } from 'react-waypoint'

const Wrapper = styled.div`
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    width: 100vw;
    height: 100%;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 1;
    background-color: rgba(16, 16, 16, 0.08);
  }

  .player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 50vw;
  height: calc(100% - 10vw);
  z-index: 2;
  transition: 0.2s all ease-in-out;
  opacity: ${props => props.visible ? 1 : 0 };
  padding: 5vw;
  pointer-events: none;
  font-family: ${props => props.theme.type.heading};
`;

const Title = styled.h1`
  color: white;
  font-size: 80px;
  font-weight: 600;
  margin: 0;
`

const Meta = styled.p`
  color: white;
  font-size: 24px;
  display: flex;
  align-items: center;
`

const Progress = styled.progress`
  appearance: none;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 4px;
  transition: all .6s ease;

  &::-webkit-progress-bar{
    background: transparent;
  }

  &::-webkit-progress-value {
    background: white;
  }
`

class Video extends Component {

  constructor(props) {
    super(props);
    this.state = {
      active: false
    }
  }

  handleProgress = state => {
    this.setState(state)
  }

  handleDuration = (duration) => {
    this.setState({ duration })
  }

  handleLeave = () => {
    console.log('left')
    this.setState({ active: false })
  }

  handleClick = () => {
    if(!this.state.active){
      this.player.seekTo(0)
    }
    this.setState({
      active: !this.state.active
    })
  }

  ref = player => {
    this.player = player
  }

  render() {

    const { active } = this.state;

    return (
      <Wrapper onClick={this.handleClick}>
        <Overlay visible={!active}>
          <Title>{this.props.title}</Title>
          <Meta>Watch Video &nbsp;&#9654;</Meta>
        </Overlay>
        <ReactPlayer
          ref={this.ref}
          className='player'
          width='100%'
          height='100%'
          url={this.props.url}
          muted={!active}
          onProgress={this.handleProgress}
          volume={active ? 1 : 0 }
          playsinline
          onDuration={this.handleDuration}
          playing
          loop
        />
        {active
          ?
            <Progress max={this.state.duration} value={this.state.playedSeconds} />
          :
            null
        }
        <Waypoint topOffset='-300px' onLeave={this.handleLeave} />
      </Wrapper>
    );
  }

}

export default Video;
