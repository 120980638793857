import React from "react";
import styled from "styled-components";
import Counter from "./Counter";
import PropTypes from "prop-types";
import { device } from "../utils/devices";

const Remove = styled.p`
  cursor: pointer;
  font-family: ${props => props.theme.type.new};
  display: inline-block;

  @media ${device.laptop} {
    opacity: 0;
  }
`;

const Item = styled.li`
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  grid-gap: 5vw;
  padding-bottom: 1rem;

  @media ${device.laptop} {
    grid-gap: 1rem;

    &:hover {
      ${Remove} {
        opacity: 1;
      }
    }
  }
`;

const Thumb = styled.picture`
  width: 25vw;
  height: 30vw;
  display: block;
  border-radius: 3px;
  overflow: hidden;
  line-height: 0;
  background: white;

  @media ${device.laptop} {
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center center;
  }
`;

const Info = styled.div`
  text-align: left;
`;

const Title = styled.h3`
  font-size: 18px;
  margin: 0;
  font-weight: 400;
`;

const Size = styled.p`
  font-size: 16px;
  font-weight: 300;
`;

const CartItem = props => {
  const filename = props.item.variant.image.src
    .replace(/^.*[\\/]/, "")
    .split(".")[0];
  const medium = `${filename}_medium`;
  const newThumb = props.item.variant.image.src.replace(filename, medium);

  return (
    <Item>
      <Thumb>
        <img alt={props.item.variant.altText} src={newThumb} />
      </Thumb>
      <Info>
        <Title>{props.item.title}</Title>
        <Size>{props.item.variant.title}</Size>
        <Counter id={props.item.id} quantity={props.item.quantity} />
        <Remove
          onClick={() => {
            props.removeItem(props.item.id);
          }}
        >
          Remove
        </Remove>
      </Info>
    </Item>
  );
};

CartItem.propTypes = {
  item: PropTypes.object.isRequired
};

export default CartItem;
