import React, { Component } from 'react'
import styled from 'styled-components'
import { device } from '../utils/devices'

const Loop = styled.div`
  font-size: 36px;
  font-family: ${props => props.theme.type.heading};
  text-transform: uppercase;
  margin-bottom: 4rem;

  @media ${device.laptop}{
    font-size: 60px;
  }
`;

class TextLoop extends Component {

  state = {
    index: 0
  }

  componentDidMount() {
    const { length } = this.props.items;

    this.interval = setInterval(() => {
      const { index } = this.state;
      if(index < length - 1){
        this.setState({ index: this.state.index + 1 })
      } else {
        this.setState({ index: 0 })

      }
    }, 200);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {

    const { items } = this.props;
    const { index } = this.state;

    return (
      <Loop>
        {items[index]}
      </Loop>
    );
  }

}

export default TextLoop;
