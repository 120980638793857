import Prismic from 'prismic-javascript';
import prismic from '../../utils/Prismic';

export const FETCH_POSTS = 'fetch_posts';
export const FETCH_PROJECTS = 'fetch_projects';

export function fetchPosts(){
  return dispatch => {
    return prismic.then(api => {
      return api.query('',
         { pageSize: 50, page: 1, orderings : '[document.first_publication_date desc]' }
      )
    })
    .then(res => {
      return dispatch({
        type: FETCH_POSTS,
        payload: res
      })
    })
  }
}

export function fetchFeature(){
  return dispatch => {
    return prismic
    .then(api => {
      return api.query(Prismic.Predicates.at('document.type', 'feature')
      )
    })
    .then(res => {
      return res.results[0]
    })
    .catch(err => {
      console.log(err)
    })
  }
}

export function fetchProjects(){
  return dispatch => {
    return prismic
    .then(api => {
      return api.query(Prismic.Predicates.at('document.type', 'project'),
         { orderings : '[document.first_publication_date desc]' }
      )
    })
    .then(res => {
      return res.results
    })
    .catch(err => {
      console.log(err)
    })
  }
}

export function fetchProject(uid){
  return dispatch => {
    return prismic.then(api => {
      return api.getByUID('project', uid)
    })
    .then(res => {
      return res
    })
  }
}


export function fetchPost(uid){
  return dispatch => {
    return prismic.then(api => {
      return api.getByUID('post', uid)
    })
    .then(res => {
      return res
    })
  }
}
