export const TOGGLE_CART = 'TOGGLE_CART'
export const OPEN_CART = 'OPEN_CART'
export const CLOSE_CART = 'CLOSE_CART'
export const TOGGLE_NAV = 'TOGGLE_NAV'
export const OPEN_NAV = 'OPEN_NAV'
export const CLOSE_NAV = 'CLOSE_NAV'

export function toggleCart(){
  return dispatch => {
    return dispatch({
      type: TOGGLE_CART
    })
  }
}

export function openCart(){
  return dispatch => {
    return dispatch({
      type: OPEN_CART
    })
  }
}

export function closeCart(){
  return dispatch => {
    return dispatch({
      type: CLOSE_CART
    })
  }
}

export function toggleNav(){
  return dispatch => {
    return dispatch({
      type: TOGGLE_NAV
    })
  }
}

export function openNav(){
  return dispatch => {
    return dispatch({
      type: OPEN_NAV
    })
  }
}

export function closeNav(){
  console.log('dfsdfsfs')
  return dispatch => {
    console.log('CLOSE')
    return dispatch({
      type: CLOSE_NAV
    })
  }
}
