import { combineReducers } from 'redux'
import ProductReducer from './ProductReducer'
import CartReducer from './CartReducer'
import NavReducer from './NavReducer'
import PostReducer from './PostReducer'
import DiaryReducer from './DiaryReducer'

const rootReducer = combineReducers({
  products: ProductReducer,
  cart: CartReducer,
  nav: NavReducer,
  posts: PostReducer,
  diaryPosts: DiaryReducer
});

export default rootReducer;
