import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { device } from '../utils/devices';

const Wrapper = styled.div`
  height: calc(100% - 4rem);
  width: calc(100% - 4rem);
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 240px;
    height: 27px;
  }

  a {
    height: 24px;
  }

  @media ${device.laptop}{
    position: static;
    padding: 1rem;
    height: calc(100% - 2rem);
    width: calc(100% - 2rem);
  }
`;

const Logo = (props) => {
  return (
    <Wrapper>
      <Link to='/'>

      <svg width="215px" height="22px" viewBox="0 0 215 22" version="1.1">
          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="Main_ID_Revised" transform="translate(-277.000000, -405.000000)">
                  <path d="M312.0264,415.655 C311.7144,418.221 310.4024,420.103 308.1264,421.248 C306.4924,422.07 304.9544,422.068 303.5554,421.243 C302.4034,420.563 301.7744,419.449 301.6324,417.839 C301.6244,417.736 301.6264,417.628 301.6294,417.513 C301.6304,417.456 301.6314,417.398 301.6314,417.341 C301.6374,414.208 303.9484,411.395 307.0064,410.798 C308.7834,410.451 310.4494,411.078 311.3534,412.435 C311.9504,413.331 312.1764,414.415 312.0264,415.655 M307.0654,405.776 C304.5004,405.97 302.1914,406.991 300.2044,408.808 C296.9514,411.782 295.6104,415.338 296.2164,419.376 C296.6904,422.528 298.4814,424.818 301.3964,426.001 C302.8384,426.586 304.2674,426.878 305.6754,426.878 C307.6664,426.878 309.6164,426.294 311.5054,425.129 C315.3244,422.775 317.4474,419.342 317.6454,415.199 L317.6464,415.184 C317.6214,412.159 316.8474,410.066 315.1384,408.4 C313.0654,406.379 310.4234,405.52 307.0654,405.776" id="Fill-1" fill="#010101"></path>
                  <path d="M334.4619,406.068 L334.4079,406.268 C334.3999,406.301 334.3919,406.331 334.3839,406.359 C334.3659,406.419 334.3509,406.474 334.3389,406.535 C333.5499,410.594 332.7609,414.654 331.9729,418.714 C331.7389,419.917 331.2589,420.709 330.4589,421.206 C329.3119,421.919 328.0519,422.046 326.7149,421.584 C326.0639,421.358 325.6619,420.882 325.5549,420.207 C325.4929,419.825 325.4959,419.422 325.5629,419.07 C326.0179,416.661 326.4949,414.214 326.9559,411.847 C327.0999,411.112 327.2429,410.376 327.3869,409.64 L328.0739,406.071 C328.0739,406.071 327.6599,406.059 327.6039,406.059 L326.2789,406.059 C325.2379,406.059 323.7239,406.059 322.6649,406.053 L322.6599,406.053 C322.6519,406.052 322.5999,406.384 322.5679,406.544 C322.3679,407.577 322.1659,408.61 321.9639,409.643 C321.7169,410.9 321.4709,412.157 321.2289,413.416 C321.1309,413.924 321.0299,414.432 320.9309,414.94 C320.6329,416.454 320.3239,418.019 320.0839,419.571 C319.6919,422.089 320.8779,424.431 323.1779,425.683 C324.3019,426.296 325.5339,426.653 326.9439,426.777 C327.3239,426.81 327.6949,426.827 328.0569,426.827 C329.7489,426.827 331.2509,426.462 332.6199,425.718 C335.1309,424.357 336.7149,422.102 337.3299,419.016 C337.8379,416.462 338.3419,413.862 338.8299,411.348 C339.0209,410.367 339.2099,409.385 339.4009,408.405 C339.4899,407.951 339.5749,407.498 339.6619,407.038 L339.8459,406.068 L334.4619,406.068 Z" id="Fill-3" fill="#010101"></path>
                  <path d="M281.4375,406.0768 L277.4995,426.4778 L282.8915,426.4778 L284.3395,419.1108 L292.1375,419.1108 L293.0145,414.4028 L285.2475,414.4028 C285.3115,414.0698 285.3765,413.7358 285.4435,413.4028 C285.6015,412.6008 285.7655,411.7728 285.9055,410.9518 C285.9375,410.7638 285.9355,410.7638 286.1635,410.7648 C287.8485,410.7768 290.5535,410.7748 292.7265,410.7738 C293.3505,410.7738 293.9265,410.7728 294.4155,410.7728 L295.0005,410.7728 L295.8765,406.0768 L281.4375,406.0768 Z" id="Fill-5" fill="#010101"></path>
                  <polygon id="Fill-7" fill="#010101" points="346.9404 421.7692 349.9944 406.0752 344.5744 406.0752 340.6064 426.4832 354.7744 426.4832 355.7144 421.7692"></polygon>
                  <path d="M456.8955,416.8581 C456.9285,417.1591 456.9465,417.6221 457.0345,418.0711 C457.3485,419.6811 458.1865,420.9141 459.8045,421.4041 C461.7655,422.0001 463.6135,421.6861 465.1665,420.3001 C467.2085,418.4781 467.8435,416.1591 467.2945,413.5181 C466.9775,411.9901 466.0835,410.8761 464.5585,410.4081 C462.9575,409.9151 461.3745,410.0541 459.9565,410.9761 C457.9045,412.3101 456.9515,414.2731 456.8955,416.8581 Z M463.7585,405.3331 C464.2145,405.3781 464.6745,405.4001 465.1275,405.4711 C468.7495,406.0411 471.1675,408.1171 472.3075,411.5461 C473.6075,415.4551 472.8365,420.0661 469.0985,423.3931 C466.5805,425.6331 463.6275,426.5811 460.2885,426.4561 C455.4205,426.2731 452.4265,422.7651 451.7445,419.0641 C451.2495,416.3761 451.4965,413.7701 452.8995,411.3871 C454.8105,408.1431 457.6345,406.1481 461.3775,405.5231 C461.9165,405.4331 462.4675,405.4161 463.0125,405.3621 C463.0525,405.3591 463.0905,405.3431 463.1285,405.3331 L463.7585,405.3331 Z" id="Fill-9" fill="#000000"></path>
                  <path d="M373.2275,405.3327 C373.6615,405.3647 374.0955,405.3887 374.5285,405.4327 C376.6075,405.6397 378.5335,406.2547 380.1785,407.5957 C380.3215,407.7117 380.4575,407.8357 380.6025,407.9617 C379.6485,409.2677 378.7065,410.5567 377.7505,411.8647 C377.6565,411.7927 377.5705,411.7247 377.4815,411.6597 C375.9455,410.5487 374.2455,409.9787 372.3345,410.1287 C371.8475,410.1667 371.3895,410.3107 370.9675,410.5717 C370.2065,411.0437 369.9245,412.4177 371.0885,413.0117 C371.7365,413.3427 372.4425,413.4747 373.1465,413.6137 C374.3595,413.8537 375.5495,414.1667 376.6445,414.7657 C378.2615,415.6477 379.0805,417.0447 379.2245,418.8507 C379.3255,420.1067 379.1885,421.3397 378.5865,422.4717 C377.6665,424.2027 376.2355,425.3247 374.3755,425.9167 C372.4455,426.5317 370.4765,426.5827 368.4875,426.3197 C366.9665,426.1197 365.5245,425.6747 364.2295,424.8397 C363.6235,424.4477 363.0705,423.9767 362.4705,423.5227 C363.5415,422.1547 364.5695,420.8437 365.6095,419.5157 C365.7105,419.6237 365.8005,419.7237 365.8945,419.8197 C366.7895,420.7367 367.9185,421.2087 369.1325,421.5047 C370.1955,421.7647 371.2725,421.8327 372.3405,421.5527 C372.6545,421.4707 372.9705,421.3297 373.2335,421.1437 C374.0905,420.5347 374.0225,419.3337 373.1115,418.8107 C372.4745,418.4437 371.7615,418.2877 371.0555,418.1217 C369.9145,417.8547 368.7975,417.5237 367.7555,416.9747 C365.7585,415.9187 364.8685,414.0977 365.0115,411.8197 C365.2215,408.4857 367.8325,406.0727 370.9445,405.5307 C371.4905,405.4367 372.0465,405.3967 372.5975,405.3327 L373.2275,405.3327 Z" id="Fill-11" fill="#000000"></path>
                  <path d="M483.9473,405.3327 C484.3813,405.3647 484.8153,405.3887 485.2483,405.4327 C487.3273,405.6397 489.2533,406.2547 490.8983,407.5957 C491.0173,407.6917 491.1323,407.7947 491.2463,407.8957 C491.2693,407.9167 491.2873,407.9437 491.3123,407.9747 C490.3683,409.2677 489.4263,410.5567 488.4703,411.8647 C488.3763,411.7927 488.2903,411.7247 488.2023,411.6597 C486.6653,410.5487 484.9653,409.9787 483.0543,410.1287 C482.5673,410.1667 482.1093,410.3107 481.6873,410.5717 C480.9263,411.0437 480.6443,412.4177 481.8083,413.0127 C482.4563,413.3427 483.1623,413.4747 483.8663,413.6137 C485.0793,413.8537 486.2693,414.1667 487.3643,414.7657 C488.9813,415.6477 489.8013,417.0447 489.9443,418.8507 C490.0453,420.1067 489.9083,421.3397 489.3063,422.4717 C488.3863,424.2027 486.9553,425.3247 485.0953,425.9167 C483.1653,426.5317 481.1963,426.5827 479.2073,426.3197 C477.6863,426.1197 476.2443,425.6747 474.9493,424.8397 C474.3433,424.4477 473.7903,423.9767 473.1903,423.5227 C474.2613,422.1547 475.2893,420.8437 476.3293,419.5157 C476.4303,419.6237 476.5203,419.7247 476.6143,419.8197 C477.5093,420.7367 478.6383,421.2087 479.8523,421.5047 C480.9163,421.7647 481.9923,421.8327 483.0613,421.5527 C483.3743,421.4707 483.6903,421.3297 483.9543,421.1437 C484.8103,420.5347 484.7423,419.3337 483.8313,418.8107 C483.1943,418.4437 482.4813,418.2877 481.7753,418.1217 C480.6343,417.8547 479.5173,417.5237 478.4753,416.9737 C476.4783,415.9187 475.5883,414.0967 475.7313,411.8197 C475.9413,408.4867 478.5513,406.0727 481.6643,405.5307 C482.2113,405.4367 482.7663,405.3967 483.3183,405.3327 L483.9473,405.3327 Z" id="Fill-13" fill="#000000"></path>
                  <path d="M426.2109,421.3073 C427.3369,421.3163 428.4249,421.3523 429.4989,421.1163 C430.9149,420.8043 432.1619,420.1713 433.1299,419.0783 C434.4469,417.5943 434.7919,415.8173 434.5309,413.9053 C434.3319,412.4473 433.4829,411.4783 432.1109,410.9673 C431.8779,410.8803 431.6359,410.8153 431.3939,410.7553 C430.3949,410.5093 429.3749,410.5503 428.3589,410.5383 C428.1579,410.5363 428.1069,410.6123 428.0739,410.7943 C427.5699,413.6603 427.0579,416.5253 426.5489,419.3893 C426.4379,420.0153 426.3279,420.6423 426.2109,421.3073 M420.1069,426.0883 C421.2979,419.3013 422.4879,412.5223 423.6809,405.7283 C423.7579,405.7243 423.8289,405.7163 423.8989,405.7163 C425.9409,405.7173 427.9839,405.6963 430.0249,405.7273 C432.0539,405.7573 433.9999,406.1633 435.7659,407.2183 C438.1539,408.6453 439.5649,410.7313 439.9019,413.5043 C440.1889,415.8543 439.8569,418.1113 438.7199,420.2073 C437.6069,422.2593 435.9039,423.6933 433.8279,424.7023 C432.0629,425.5593 430.1799,426.0083 428.2269,426.0553 C425.5489,426.1193 422.8679,426.0943 420.1899,426.1083 C420.1739,426.1083 420.1579,426.1003 420.1069,426.0883" id="Fill-15" fill="#000000"></path>
                  <path d="M419.8184,405.7526 C419.7094,406.3756 419.5994,407.0026 419.4904,407.6286 C418.9984,410.4316 418.5124,413.2346 418.0094,416.0356 C417.7504,417.4856 417.5814,418.9556 417.1354,420.3686 C416.5924,422.0916 415.7184,423.5986 414.2584,424.7146 C413.1584,425.5566 411.9044,426.0406 410.5494,426.2706 C408.8794,426.5526 407.2094,426.5766 405.5544,426.1586 C403.9264,425.7466 402.5714,424.9106 401.6144,423.5026 C400.8764,422.4156 400.6324,421.1866 400.6474,419.8936 C400.6624,418.7076 400.9354,417.5586 401.1354,416.3996 C401.6044,413.6836 402.0864,410.9696 402.5634,408.2546 C402.7114,407.4146 402.8584,406.5746 403.0074,405.7306 L408.2784,405.7306 C408.0914,406.7936 407.9084,407.8406 407.7234,408.8876 C407.2304,411.6816 406.7354,414.4756 406.2474,417.2706 C406.1474,417.8416 406.0484,418.4146 405.9974,418.9906 C405.9694,419.3066 406.0044,419.6406 406.0774,419.9496 C406.3224,420.9816 407.1194,421.5736 408.2444,421.6576 C410.6494,421.8366 411.9534,420.2246 412.2874,418.5006 C412.6404,416.6816 412.9424,414.8526 413.2654,413.0256 C413.6864,410.6456 414.1034,408.2636 414.5304,405.8836 C414.5424,405.8196 414.6424,405.7236 414.7014,405.7236 C416.3884,405.7166 418.0754,405.7196 419.7614,405.7216 C419.7684,405.7226 419.7754,405.7296 419.8184,405.7526" id="Fill-17" fill="#000000"></path>
                  <path d="M388.1992,410.3532 L381.9882,410.3532 C382.0572,409.9352 382.1212,409.5412 382.1892,409.1492 C382.3762,408.0782 382.5732,407.0072 382.7462,405.9332 C382.7792,405.7322 382.8642,405.7132 383.0292,405.7132 C386.3872,405.7162 389.7432,405.7152 393.1002,405.7152 L400.1932,405.7152 L400.4932,405.7152 C400.3182,406.7222 400.1502,407.6992 399.9802,408.6742 C399.8962,409.1592 399.7972,409.6412 399.7312,410.1272 C399.7052,410.3292 399.6152,410.3512 399.4482,410.3512 C397.5672,410.3472 395.6872,410.3482 393.8072,410.3482 L393.4732,410.3482 C392.5472,415.6082 391.6242,420.8502 390.7002,426.0972 L385.4282,426.0972 C386.3502,420.8542 387.2712,415.6242 388.1992,410.3532" id="Fill-19" fill="#000000"></path>
                  <path d="M441.3105,426.0993 C442.5035,419.2983 443.6945,412.5213 444.8855,405.7303 L450.1585,405.7303 C448.9655,412.5243 447.7755,419.3073 446.5835,426.0993 L441.3105,426.0993 Z" id="Fill-21" fill="#000000"></path>
              </g>
          </g>
      </svg>
      </Link>
    </Wrapper>

  )
}

export default Logo;
