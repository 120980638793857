import Prismic from "prismic-javascript";
import prismic from "../../utils/Prismic";

export const FETCH_PRODUCTS = "fetch_products";

export function fetchProducts() {
  return dispatch => {
    return prismic
      .then(api => {
        return api.query(Prismic.Predicates.at("document.type", "product"), {
          orderings: "[document.first_publication_date desc]"
        });
      })
      .then(res => {
        return dispatch({
          type: FETCH_PRODUCTS,
          payload: res
        });
      });
  };
}

export function fetchProductCategory(category) {
  return dispatch => {
    return prismic
      .then(api => {
        switch (category) {
          case "apparel":
            return api.query(
              [
                Prismic.Predicates.at("document.type", "product"),
                Prismic.Predicates.not("my.product.type", "Equipment")
              ],
              { orderings: "[document.first_publication_date desc]" }
            );
          case "tops":
            return api.query(
              [
                Prismic.Predicates.at("document.type", "product"),
                Prismic.Predicates.at("my.product.type", "Tees")
              ],
              { orderings: "[document.first_publication_date desc]" }
            );
          case "bottoms":
            return api.query(
              [
                Prismic.Predicates.at("document.type", "product"),
                Prismic.Predicates.at("my.product.type", "Shorts")
              ],
              { orderings: "[document.first_publication_date desc]" }
            );
          case "accessories":
            return api.query(
              [
                Prismic.Predicates.at("document.type", "product"),
                Prismic.Predicates.at("my.product.type", "Equipment")
              ],
              { orderings: "[document.first_publication_date desc]" }
            );
          case "equipment":
            return api.query(
              [
                Prismic.Predicates.at("document.type", "product"),
                Prismic.Predicates.at("my.product.type", "Equipment")
              ],
              { orderings: "[document.first_publication_date desc]" }
            );
          default:
            return api.query(
              [Prismic.Predicates.at("document.type", "product")],
              { orderings: "[document.first_publication_date desc]" }
            );
        }
      })
      .then(res => {
        console.log(res.results);
        return dispatch({
          type: FETCH_PRODUCTS,
          payload: res
        });
      });
  };
}

export function fetchSingleProduct(uid) {
  return dispatch => {
    return prismic
      .then(api => {
        return api.getByUID("product", uid);
      })
      .then(res => {
        return res.data;
      });
  };
}
