import React, { useState, useEffect } from "react";
import Prismic from "prismic-javascript";
import { Link, RichText, Date } from "prismic-reactjs";
import Page from "../components/Page";
import styled from "styled-components";

const Section = styled.section`
  margin-bottom: 4rem;
  border-bottom: 2rem;
  padding-bottom: 2rem;
`;

const PrivacyPolicy = () => {
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const apiEndpoint = "https://foul-studios.prismic.io/api/v2";
      const client = Prismic.client(apiEndpoint);

      const response = await client.query(
        Prismic.Predicates.at("document.type", "settings")
      );
      if (response) {
        const { privacy_policy, terms } = response.results[0].data;
        setData({ terms: terms, privacy_policy: privacy_policy });
        setLoaded(true);
      }
    };

    if (!loaded) {
      fetchData();
    }
  }, [loaded]);

  return (
    <Page>
      <Section>
        <h2>Privacy Policy</h2>
        {loaded && (
          <div>
            <RichText render={data.privacy_policy} />
          </div>
        )}
      </Section>
      <Section>
        <h2>Terms</h2>
        {loaded && (
          <div>
            <RichText render={data.terms} />
          </div>
        )}
      </Section>
    </Page>
  );
};

export default PrivacyPolicy;
