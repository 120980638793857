import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { device } from "../utils/devices";
import { Link } from "react-router-dom";
import Moment from "react-moment";

const Image = styled.picture`
  display: block;
  overflow: hidden;
  height: 60vh;

  @media (orientation: landscape) {
    height: 80vh;
  }

  @media ${device.laptop} {
    height: 80vh;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.15s all ease-in-out;
  }
`;

const Text = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 0 1rem;
  box-sizing: border-box;
  text-align: center;
  flex-direction: column;
  transition: 0.2s all ease-in-out;

  @media ${device.laptop} {
    opacity: 0;
    transform: translate(0, 20px);
  }
`;

const Post = styled.div`
  position: relative;
  grid-column: span 4;

  @media ${device.laptop} {
    margin-bottom: 0;
  }

  &:hover {
    ${Text} {
      opacity: 1;
      transform: translate(0, 0);
    }
  }

  a {
    display: block;
    height: 100%;
    color: black;
    text-decoration: none;
  }
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: 600;
  font-weight: 600;
  font-family: ${props => props.theme.type.heading};
  text-transform: uppercase;
  margin: 0;

  @media ${device.laptop} {
    font-size: 4vw;
  }
`;

const Date = styled.span`
  display: block;
  margin: 1.5rem 0 0.5rem;
  font-family: ${props => props.theme.type.new};

  @media ${device.laptop} {
    font-size: 1.5vw;
  }
`;

const DiaryPost = ({ post }) => {
  return (
    <Post>
      <Link to={`/random/${post.uid}`}>
        <Image>
          <img src={post.data.thumbnail.url} alt={post.uid} />
        </Image>
        <Text>
          <Date>
            <Moment format="MMMM DD, Y">{post.first_publication_date}</Moment>
          </Date>
          <Title>{post.data.title[0].text}</Title>
        </Text>
      </Link>
    </Post>
  );
};

DiaryPost.propTypes = {
  post: PropTypes.object.isRequired
};

export default DiaryPost;
