import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { fetchFeature } from "../redux/actions/PostActions";
import { device } from "../utils/devices";
import ReactPlayer from "react-player";
import { FaVolumeMute, FaVolumeUp } from "react-icons/fa";
import { isMobile } from "react-device-detect";

const Title = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  pointer-events: none;
  z-index: 3;
  font-size: 48px;
  line-height: 48px;
  padding: 0 5vw;
  font-family: ${props => props.theme.type.heading};
  text-transform: uppercase;
  box-sizing: border-box;
  text-align: ${props => (props.type === "audio" ? `left` : `center`)};

  @media ${device.laptop} {
    font-size: 5vw;
    line-height: 5vw;
    padding: 0;
  }
`;

const Wrapper = styled.div`
  height: ${props => (props.loaded ? `70vh` : `80vh`)};
  box-sizing: border-box;
  position: relative;
  width: 100vw;
  overflow: hidden;
  background: black;

  ${Title} {
    position: ${props => (props.type === "video" ? `absolute` : `static`)};
    height: ${props => (props.type === "video" ? `100%` : `auto`)};
    margin-bottom: ${props => (props.type === "video" ? `0` : `2rem`)};
  }

  @media (orientation: landscape) {
    height: ${props => (props.loaded ? `70vh` : `80vh`)};
  }

  @media ${device.laptop} {
    height: ${props =>
      props.loaded
        ? props.type === "video"
          ? `calc(100vw * 0.5625)`
          : `50vh`
        : `80vh`};
  }

  a {
    display: block;
    height: 100%;
  }

  picture {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    top: 0;
    left: 0;
    transition: 0.15s all ease-in-out;

    .video {
      opacity: ${props => (props.playing ? 0 : 1)};
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .audio-player {
    width: 90vw !important;
    height: auto !important;
    max-width: 900px;

    @media ${device.laptop} {
      width: 100% !important;
      max-width: none;

      iframe {
        height: 10vw;
      }
    }
  }

  .video-player {
    pointer-events: none;
    position: absolute;
    top: -25vw;
    left: 0;
    width: 100vw !important;
    height: 100vh !important;
    z-index: 2;

    @media (orientation: landscape) {
      top: 0;
      width: 100% !important;
      transform: none;
    }

    @media ${device.laptop} {
      transform: none;
      width: 100% !important;
      height: 100% !important;
    }

    iframe,
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

const Controls = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 1rem;
  font-size: 30px;
  z-index: 3;
  color: white;
  cursor: pointer;
`;

const Audio = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5vw;
  box-sizing: border-box;

  .audio-player {
    z-index: 3;
  }

  @media ${device.laptop} {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: 2fr 1fr;
    padding: 100px 2rem 2rem 2rem;
  }
`;

function parseURL(url) {
  var parser = document.createElement("a"),
    searchObject = {},
    queries,
    split,
    i;
  // Let the browser do the work
  parser.href = url;
  // Convert query string to object
  queries = parser.search.replace(/^\?/, "").split("&");
  for (i = 0; i < queries.length; i++) {
    split = queries[i].split("=");
    searchObject[split[0]] = split[1];
  }
  if (parser.hostname.includes("www.")) {
    parser.hostname = parser.hostname.replace("www.", "");
  }
  return {
    protocol: parser.protocol,
    host: parser.host,
    hostname: parser.hostname,
    port: parser.port,
    pathname: parser.pathname,
    search: parser.search,
    searchObject: searchObject,
    hash: parser.hash
  };
}

class Feature extends Component {
  state = {
    feature: {},
    type: "",
    active: false,
    isLoaded: false,
    playing: true
  };

  componentDidMount() {
    this.props
      .fetchFeature()
      .then(res => {
        if (res) {
          let type = "unknown";
          const url = parseURL(res.data.video.url).hostname;
          switch (url) {
            case "youtube.com":
              type = "video";
              break;
            case "vimeo.com":
              type = "video";
              break;
            case "soundcloud.com":
              type = "audio";
              break;
            case "mixcloud.com":
              type = "audio";
              break;
            default:
              type = "video";
          }
          this.setState({ feature: res.data, type: type, isLoaded: true });
        }
      })
      .catch(err => console.log(err));
  }

  render() {
    console.log(this.state.feature.image);
    return (
      <Wrapper
        playing={this.state.playing}
        loaded={this.state.loaded}
        type={this.state.type}
      >
        {this.state.isLoaded ? (
          this.state.feature.video.url ? (
            this.state.type === "video" ? (
              <>
                {!this.state.active && (
                  <Title type={this.state.type}>
                    {this.state.feature.text[0].text}
                  </Title>
                )}
                <Controls
                  onClick={() => {
                    this.setState({ active: !this.state.active });
                  }}
                >
                  {this.state.active ? <FaVolumeUp /> : <FaVolumeMute />}
                </Controls>
                {this.state.feature.url.url ? (
                  <a href={this.state.feature.url.url}>
                    {typeof this.state.feature.image.url !== "undefined" && (
                      <picture className="video">
                        <img
                          src={this.state.feature.image.url}
                          alt={this.state.feature.text}
                        />
                      </picture>
                    )}
                    <ReactPlayer
                      className="video-player"
                      playing
                      playsInline
                      playsinline
                      muted={!this.state.active}
                      loop
                      url={
                        isMobile
                          ? this.state.feature.mobile_video.url
                          : this.state.feature.video.url
                      }
                      onPlay={() => this.setState({ playing: true })}
                      config={{
                        youtube: {
                          preload: true
                        },
                        vimeo: {
                          preload: true
                        }
                      }}
                    />
                  </a>
                ) : (
                  <div
                    onClick={() => {
                      this.setState({ playing: !this.state.playing });
                    }}
                  >
                    {this.state.image?.url && (
                      <picture className="video">
                        <img
                          src={this.state.feature.image.url}
                          alt={this.state.feature.text}
                        />
                      </picture>
                    )}
                    <ReactPlayer
                      className="video-player"
                      playing={this.state.playing}
                      playsInline
                      playsinline
                      muted={!this.state.active}
                      loop
                      url={
                        isMobile
                          ? this.state.feature.mobile_video.url
                          : this.state.feature.video.url
                      }
                      onPlay={() => this.setState({ playing: true })}
                      config={{
                        youtube: {
                          preload: true
                        },
                        vimeo: {
                          preload: true
                        }
                      }}
                    />
                  </div>
                )}
              </>
            ) : (
              <Audio>
                <ReactPlayer
                  className="audio-player"
                  playing
                  playsInline
                  playsinline
                  muted
                  loop
                  url={this.state.feature.video.url}
                  onPlay={() => this.setState({ playing: true })}
                  config={{
                    youtube: {
                      preload: true
                    },
                    vimeo: {
                      preload: true
                    }
                  }}
                />
                <Title>{this.state.feature.text[0].text}</Title>
                <picture>
                  <img
                    src={this.state.feature.image.url}
                    alt={this.state.feature.text}
                  />
                </picture>
              </Audio>
            )
          ) : (
            <>
              <Title>{this.state.feature.text[0].text}</Title>
              <a href={this.state.feature.url.url}>
                <picture>
                  <img
                    src={this.state.feature.image.url}
                    alt={this.state.feature.text}
                  />
                </picture>
              </a>
            </>
          )
        ) : null}
      </Wrapper>
    );
  }
}
export default connect(state => ({}), { fetchFeature })(Feature);
