import React, { Component } from "react";
import styled from "styled-components";
import { device } from "../utils/devices";
import TextLoop from "../components/TextLoop";
import { Helmet } from "react-helmet";
import ReactPlayer from "react-player";
import {
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaFacebookSquare,
  FaSoundcloud
} from "react-icons/fa";

const Wrapper = styled.div`
  padding: 0 5vw;
  min-height: 100vh;
  box-sizing: border-box;
  will-change: background;
  display: flex;
  background: black;
  flex-direction: column;
  justify-content: center;

  @media ${device.laptop} {
    padding: 0;
  }

  .player {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw !important;
    height: 100vh !important;
    pointer-events: none;

    iframe,
    video {
      object-fit: cover;
      opacity: 0.8;
    }
  }
`;

const Content = styled.div`
  color: white;
  position: relative;
  z-index: 2;
  padding-top: 6rem;
  width: 90vw;
  margin: 0 auto;

  @media ${device.laptop} {
    display: grid;
    width: 80vw;
    padding-top: 8rem;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "heading heading" "info info" "list .";
  }

  h2 {
    grid-area: heading;
    text-transform: uppercase;
    font-size: 36px;
    line-height: 42px;
    font-family: ${props => props.theme.type.heading};
    margin: 0;

    @media ${device.laptop} {
      font-size: 48px;
      line-height: 48px;
    }

    @media ${device.laptopL} {
      font-size: 7vh;
      line-height: 8.5vh;
    }
  }

  h4 {
    margin: 2rem 0 0;
    font-size: 36px;
    text-transform: uppercase;
    color: transparent;
    -webkit-text-stroke: ${props => (props.hover ? "2px white" : "1px white")};
    font-family: ${props => props.theme.type.heading};

    @media ${device.laptop} {
      font-size: 60px;
      margin: 1rem 0 0;
      -webkit-text-stroke: ${props =>
        props.hover ? "2px white" : "2px white"};
    }
  }

  span {
    font-size: 36px;
    text-transform: uppercase;
    font-family: ${props => props.theme.type.heading};

    @media ${device.laptop} {
      font-size: 60px;
    }
  }

  .secondary {
    grid-area: info;
    line-height: 1.4em;
    font-size: 24px;
    font-family: ${props => props.theme.type.new};
    margin: 1rem 0;
    white-space: normal;

    @media ${device.laptop} {
      font-size: 4vh;
      font-weight: 100;
      margin: 2vh 0;
    }
  }

  div {
    white-space: nowrap;
    grid-area: list;
  }
`;

const Contact = styled.div`
  width: 90vw;
  margin: 0 auto;
  color: white;
  z-index: 1;
  font-size: 3vh;
  font-family: ${props => props.theme.type.new};
  margin-bottom: 4rem;

  @media (orientation: landscape) {
    font-size: 5vh;
  }

  @media ${device.laptop} {
    width: 80vw;
    font-size: 3vh;
  }

  h5 {
    font-family: ${props => props.theme.type.heading};
    text-transform: uppercase;
    margin: 0 0 1rem;
    font-size: 36px;
  }

  p {
    margin: 0 0 1rem;
  }

  ul {
    display: flex;
    list-style: none;
    padding: 0;

    li {
      margin-right: 2rem;

      &:last-of-type {
        margin: 0;
      }
    }
  }

  div {
    margin-bottom: 2rem;
  }

  a {
    color: white;
    font-size: 18px;
    text-decoration: none;

    @media ${device.laptop} {
      font-size: 24px;
    }

    &:hover {
      color: rgb(228, 94, 97);
    }
  }
`;

class About extends Component {
  state = {
    currentColor: 0
  };

  componentDidMount() {
    this.myInterval = setInterval(() => {
      const { currentColor } = this.state;
      if (currentColor < 4) {
        this.setState({
          currentColor: currentColor + 1
        });
      } else {
        this.setState({ currentColor: 0 });
      }
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.myInterval);
  }

  render() {
    const colors = [
      `rgb(62,163,134)`,
      `rgb(178,103,0)`,
      `rgb(255,197,58)`,
      `rgb(92,135,65)`,
      `rgb(228,94,97)`
    ];

    return (
      <Wrapper background={colors[this.state.currentColor]}>
        <Helmet title="About — Foul Studios" />
        <ReactPlayer
          className="player"
          url="https://prismic-io.s3.amazonaws.com/foul-studios/601602bf-1665-4044-b5c9-e0cbb95353bf_FOUL+STUDIOS+Launch+Video+%284%3A5%29+without+typo.mov"
          playing
          muted
          controls={false}
          playsinline
          allowFullScreen
          config={{
            file: {
              controls: false,
              playsinline: true,
              allowFullScreen: false
            }
          }}
          loop
        />
        <Content>
          <h2>
            Foul Studios is a sports lifestyle brand and multidisciplinary
            creative studio based in NYC.
          </h2>
          <div className="secondary">
            <p>
              We’re a community bonded by the love of sport. Foul exists to
              connect cultures and inspire us to explore, create and play
              outside the boundaries.
            </p>
            <p>
              Established in 2018, Foul is rooted in the fun and adventure we
              had playing multiple sports growing up. We started Foul as a way
              to bring that energy to our present, focusing not only on athletic
              performance but also on creative expression and community. We host
              events, design products, create content and collaborate with
              friends. But more than that our goal is to build a bridge that
              connects like-minded people here in NYC and globally.
            </p>
          </div>
          <div>
            <h4>Things we do</h4>
            <TextLoop
              items={[
                "Apparel",
                "Advertising",
                "Graphic Design",
                "Strategy",
                "Sporting Events",
                "Cultural Events",
                "Competitions",
                "Publications",
                "Films",
                "Interactive",
                "Production"
              ]}
            />
          </div>
        </Content>
        <Contact>
          <div>
            <h5>HQ</h5>
            <p>Somewhere in Brooklyn</p>
          </div>
          <div>
            <h5>Drop us a line</h5>
            <p>
              <a href="tel:8177910756">(917) 791-0756</a>
            </p>
            <p>
              <a href="mailto:yo@foulstudios.com">yo@foulstudios.com</a>
            </p>
          </div>
          <div>
            <h5>More Bullshit</h5>
            <ul>
              <li>
                <a href="https://twitter.com/foulstudios">
                  <FaTwitter size={30} />
                </a>
              </li>
              <li>
                <a href="https://instagram.com/foulstudios">
                  <FaInstagram size={30} />
                </a>
              </li>
              <li>
                <a href="https://facebook.com/foulstudios">
                  <FaFacebookSquare size={30} />
                </a>
              </li>
              <li>
                <a href="https://soundcloud.com/foulstudios">
                  <FaSoundcloud size={30} />
                </a>
              </li>
              <li>
                <a href="https://www.youtube.com/channel/UC7_0uRvoM0k93G9rV4pdCfA">
                  <FaYoutube size={30} />
                </a>
              </li>
            </ul>
          </div>
        </Contact>
      </Wrapper>
    );
  }
}

export default About;
