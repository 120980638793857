import React from "react";
import styled from "styled-components";
import { device } from "../utils/devices";
import { Link } from "react-router-dom";
import logo from "../assets/logo-upside-down.svg";

const Foot = styled.footer`
  font-size: 14px;
  color: rgb(51, 51, 51);
  font-family: ${props => props.theme.type.new};
  justify-content: space-between;
  padding: 1rem 2rem;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  background: white;
  position: relative;
  z-index: 2;

  @media ${device.laptop} {
    padding: ${props => (props.padded ? `1rem 2rem` : `1rem 0`)};
  }

  .footer-logo {
    display: flex;
    align-items: center;
    justify-content: center;

    @media ${device.laptop} {
      justify-content: flex-start;
    }

    img {
      width: 24px;
      margin-right: 1rem;
    }
  }

  div {
    text-align: center;
    margin-bottom: 1rem;

    @media ${device.laptop} {
      margin: 0;
      text-align: left;

      &:nth-of-type(2) {
        text-align: center;
      }
    }
  }

  @media ${device.laptop} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;

    div:nth-of-type(2) {
      text-align: center;
    }
  }

  ul {
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;

    @media ${device.laptop} {
      justify-content: flex-end;
    }

    li {
      margin-right: 1rem;

      a {
        color: black;
        text-decoration: none;
      }

      &:last-of-type {
        margin: 0;
      }
    }
  }
`;

const Footer = ({ padded }) => (
  <Foot padded={padded}>
    <div className="footer-logo">
      <img src={logo} alt="logo" />
      <span>A New Culture of Sport</span>
    </div>
    <div>&copy; 2020 FOUL NY</div>
    <div>
      <ul>
        <li>
          <Link to="/about">About</Link>
        </li>
        <li>
          <Link to="/terms">Terms</Link>
        </li>
        <li>
          <Link to="/faqs">FAQs</Link>
        </li>
        <li>
          <a href="https://instagram.com/foulstudios" rel="noopener">
            Instagram
          </a>
        </li>
      </ul>
    </div>
  </Foot>
);

export default Footer;
