import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter, Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { applyMiddleware, createStore, compose } from "redux";
import thunk from "redux-thunk";
import reducers from "./redux/reducers";
import ScrollToTop from "./utils/ScrollToTop";
import { Provider } from "react-redux";
import { loadState, saveState } from "./utils/loadState";
import ReactGA from "react-ga";

const persistedState = loadState();

ReactGA.initialize("UA-169710157-1");

const history = createBrowserHistory();
history.listen((location, action) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducers,
  persistedState,
  composeEnhancers(applyMiddleware(thunk))
);

store.subscribe(() => {
  saveState({
    cart: store.getState().cart
  });
});

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Router history={history}>
        <ScrollToTop>
          <App />
        </ScrollToTop>
      </Router>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
