import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const Preview = styled.div`
  width: 20vw !important;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${props => props.visible ? 1 : -1 };
  font-family: ${props => props.theme.type.new};
  text-rendering: geometricPrecision;
  pointer-events: ${props => props.visible ? `auto` : `none` };
  opacity: ${props => props.visible ? `1` : `0` };
  transition: ${props => props.visible ? `0.2s all ease-in-out` : `0s` };

  a {
    color: rgb(51,51,51);
    text-decoration: none;
  }

  span {
    text-transform: uppercase;
  }

  aside {
    transition: transform 650ms cubic-bezier(0.19, 1, 0.22, 1);
  }

  &:hover {
    aside {
      transform: translate(-5vw, 0)
    }
  }

  section {
    width: 100vh;
    text-align: center;
    position: absolute;
    top: 100vh;
    padding: 2rem 0;
    left: 0%;
    transform-origin: top left;
    transform: rotate(-90deg) translateY(-100%) translateX(0%);
    white-space: nowrap;
    zoom: 1;
  }

  picture {
    min-width: 300px;
    width: 30vw;
    overflow: hidden;
    height: 100vh;
    display: block;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

`;

const ProjectNext = ({ url, title, sku, image, visible }) => (
  <Preview visible={visible}>
    <Link to={url}>
      <aside>
        <section>
          <div>
            <span>Next Project</span>
            <h2>{title}</h2>
            <span>PRJ — {sku}</span>
          </div>
        </section>
        <picture>
          <img src={image} alt={title} />
        </picture>
      </aside>
    </Link>
  </Preview>
);

export default ProjectNext;
