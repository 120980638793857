import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { fetchProductCategory } from "../redux/actions/ProductActions";
import { device } from "../utils/devices";
import { withRouter } from "react-router-dom";
import { isMobile } from "react-device-detect";
import Prismic from "prismic-javascript";
import { Link, NavLink } from "react-router-dom";
import { ReactComponent as SelectIcon } from "../assets/select.svg";

const Wrapper = styled.div`
  background: #5bc0eb;
  font-family: ${props => props.theme.type.heading};

  @media (orientation: landscape) {
    width: 420px;
  }
`;

const List = styled.ul`
  width: 100%;
  padding: 0;
  margin: 0;
  display: grid;

  grid-template-columns: repeat(3, 1fr);
  color: white;
  align-items: center;
  height: 100%;
  list-style: none;
  text-transform: uppercase;
  font-size: 14px;
  box-sizing: border-box;
`;

const Item = styled.li`
  cursor: pointer;
  display: flex;
  align-items: center;
  height: calc(100% - 2rem);
  box-sizing: border-box;
  justify-content: center;
  position: relative;
  height: 56px;

  span {
    pointer-events: none;
  }

  a {
    color: white;
    text-decoration: none;
  }

  &:hover {
    opacity: 0.6;
  }

  &.dropdown {
    &:hover {
      opacity: 1;

      span {
        opacity: 0.6;
      }
    }

    a {
      &:hover {
        opacity: 0.6;
      }
    }
  }

  select {
    appearance: none;
    border: none;
    padding: 0;
    user-select: none;
    border-radius: 0;
    cursor: context-menu;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0px;
    width: 25px !important;
    overflow: hidden;
    z-index: 10;
    height: 100%;
  }

  &:first-of-type {
    border-right: 2px solid rgba(255, 255, 255, 0.2);
  }

  &:last-of-type {
    border-left: 2px solid rgba(255, 255, 255, 0.2);
  }
`;

const SelectButton = styled.div`
  pointer-events: none;
  position: absolute;
  width: 15px;
  height: 100%;
  top: -2px;
  right: 10px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`;

const Dropdown = styled.div`
  position: absolute;
  background: #5bc0eb;
  top: 95%;
  left: 0;
  width: 100%;
  font-weight: 100;
  font-size: 12px;
  line-height: 2em;
  padding: 0 0 1rem;

  .active {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      left: -10px;
      width: 5px;
      height: 5px;
      background: ${props => props.theme.color.primary};
      border-radius: 50%;
      top: 50%;
      transform: translate(0, -3px);

      @media ${device.laptop} {
        width: 10px;
        height: 10px;
        left: -20px;
        transform: translate(0, -7px);
      }
    }
  }
`;

const apparelTabs = ["All", "Tops", "Bottoms", "Accessories"];

class ShopHeader extends Component {
  state = {
    filter: "none",
    navData: [],
    showDropdown: false
  };

  fetchData = async () => {
    const apiEndpoint = "https://foul-studios.prismic.io/api/v2";
    const client = Prismic.client(apiEndpoint);

    const response = await client.query(
      Prismic.Predicates.at("document.type", "settings")
    );

    const { apparel_links } = response.results[0].data;

    this.setState({ navData: apparel_links });
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.fetchData();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = e => {
    this.setState({
      showDropdown: false
    });
  };

  componentDidUpdate(prevProps) {
    // Close dropdown on route change
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({ showDropdown: false });
    }
  }

  handleChange = e => {
    this.props.history.push(e.target.value);
  };

  toggleDropdown = () => {
    console.log("clicked");
    this.setState({ showDropdown: !this.state.showDropdown });
  };

  render() {
    return (
      <Wrapper>
        <List>
          <Item>
            <Link to="/shop">All</Link>
          </Item>
          <Item
            className="dropdown"
            onMouseEnter={!isMobile ? this.toggleDropdown : null}
            onMouseLeave={!isMobile ? this.toggleDropdown : null}
            onClick={
              isMobile
                ? !this.state.showDropdown
                  ? this.toggleDropdown
                  : null
                : null
            }
          >
            {!isMobile ? (
              <Link to="/shop/apparel">
                <span>Apparel</span>
              </Link>
            ) : (
              <span>Apparel</span>
            )}

            {this.state.showDropdown && (
              <Dropdown className="shop-dropdown">
                {this.state.navData.map(item => {
                  const { url, title } = item;

                  return (
                    <li key={url}>
                      <NavLink to={`/shop/${url}`} activeClassName="active">
                        {title[0].text}
                      </NavLink>
                    </li>
                  );
                })}
              </Dropdown>
            )}
          </Item>
          <Item>
            <Link to="/shop/equipment">Equipment</Link>
          </Item>
        </List>
      </Wrapper>
    );
  }
}

export default withRouter(
  connect(state => ({}), { fetchProductCategory })(ShopHeader)
);
