import React, { Component } from "react";
import styled from "styled-components";
import ReactPlayer from "react-player";
import { device } from "../utils/devices";

const Wrapper = styled.div`
  padding: 1rem;
  background: ${props => props.theme.color.sunglow};
  align-items: center;
  overflow: hidden;

  @media ${device.laptop} {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 2rem;
  }

  .player {
    width: calc(100vw - 2rem) !important;

    @media ${device.laptop} {
      position: relative;
      transform: none;
      width: 100% !important;
      height: auto !important;
      min-height: 20vh;
    }

    iframe {
      @media ${device.laptop} {
        min-height: 20vw;
      }
    }
  }

  h1 {
    font-size: 36px;
    text-align: center;
    text-transform: uppercase;
    font-family: ${props => props.theme.type.heading};

    @media ${device.laptop} {
      margin: 0;
      font-size: 48px;
      text-align: left;
    }
  }
`;

class AudioFeature extends Component {
  render() {
    return (
      <Wrapper>
        <ReactPlayer
          className="player"
          url={this.props.url ? this.props.url : this.props.fallback}
        />
        <h1>{this.props.title}</h1>
      </Wrapper>
    );
  }
}

export default AudioFeature;
