import React from "react";
import Ticker from "react-ticker";
import styled from "styled-components";
import { device } from "../utils/devices";

const Wrapper = styled.div`
  width: 100vw;
  z-index: 5;
  margin-top: -2px;
  background: ${props => props.theme.color.ginger};

  .ticker {
    bottom: 0;
    left: 0;
    width: 100vw;
  }
`;

const Text = styled.h1`
  font-family: ${props => props.theme.type.new};
  font-size: 7vw;
  font-weight: 600;
  color: white;
  white-space: nowrap;
  line-height: 0;
  text-transform: uppercase;
  padding: 0.5rem 2rem;

  @media (orientation: landscape) {
    font-size: 3vw;
  }

  @media ${device.laptop} {
    font-size: 36px;
  }
`;

const HomeTicker = () => (
  <Wrapper>
    <Ticker speed={7} className="ticker">
      {({ index }) => <Text>Free Shipping on all Orders over $100</Text>}
    </Ticker>
  </Wrapper>
);

export default HomeTicker;
