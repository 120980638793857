import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { device } from '../utils/devices';
import Moment from 'react-moment'

const Wrapper = styled.article`
  height: ${props => props.large ? `70vh`: `40vh`};
  background: url(${props => props.thumb});
  background-size: cover;
  position: relative;
  display: flex;
  background-position: center;
  justify-content: center;
  flex-direction: column;
  padding: 4rem 0;
  box-sizing: border-box;

  @media ${device.laptop}{
    height: ${props => props.large ? `100vh`: `50vh`};
  }
`;

const Title = styled.h1`
  width: 90vw;
  color: white;
  font-size: 36px;
  font-family: ${props => props.theme.type.heading};
  text-transform: uppercase;
  font-weight: 600;
  padding: 5vw 5vw 0;
  box-sizing: border-box;
  margin: 0;

  @media ${device.laptop}{
    font-size: 80px;
    width: 50vw;
    padding: 0 2rem;
  }
`;

const Meta = styled.p`
  color: white;
  font-size: 24px;
  display: flex;
  align-items: center;
  padding: 0 5vw;
  font-family: ${props => props.theme.type.secondary};

  @media ${device.laptop}{
    padding: 0 2rem;
  }
`

const Date = styled.p`
  padding: 0 5vw;
  font-size: 24px;
  color: white;
  margin-bottom: 2rem;
  font-family: ${props => props.theme.type.secondary};

  @media ${device.laptop}{
    padding: 0 2rem;
  }
`;

const PostLink = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;

  a {
    text-indent: -999rem;
    display: block;
    width: 100%;
    height: 100%;
  }
`;

const Article = ({ uid, date, title, thumb, large }) => {

  return (
    <Wrapper thumb={thumb} large={large}>
      <PostLink><Link to={`/random/${uid}`}>View Product</Link></PostLink>
      <Date><Moment format='MMMM Do, YYYY'>{date}</Moment></Date>
      <Title>{title}</Title>
      <Meta>Read More ↗</Meta>
    </Wrapper>
  )
}

Article.propTypes = {
  uid: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  thumb: PropTypes.string.isRequired
}

export default Article;
