import React, { useState, useEffect, createRef } from "react";
import styled from "styled-components";
import Prismic from "prismic-javascript";
import prismic from "../utils/Prismic";
import ShopProduct from "./ShopProduct";
import { device } from "../utils/devices";
import { Link, useParams } from "react-router-dom";
import Slider from "react-slick";
import { isMobile } from "react-device-detect";

const Wrapper = styled.div`
  @media ${device.laptop} {
    padding: 2rem;
  }

  .slick-track {
    display: flex;
    align-items: center;
    height: 70vh;

    @media (orientation: landscape) {
      height: auto;
    }
  }

  h2 {
    text-align: center;
    font-family: ${props => props.theme.type.new};
    text-transform: uppercase;
    font-weight: 300;
    margin: 0 0 2rem;
    font-size: 16px;
    padding-top: 1rem;
    border-top: 1px solid rgba(0, 0, 0, 0.12);

    @media ${device.laptop} {
      font-size: 24px;
    }

    a {
      color: black;
      text-decoration: none;
      font-weight: 600;
    }
  }
`;

const List = styled.div`
  @media ${device.laptop} {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .slick-slide {
    > div {
      /* height: 100%; */
      padding: 0 1rem;
    }
  }

  .info {
    padding: 0;
  }

  .single-image {
    margin: 0;
    height: 100%;
    /* height: 90vw; */

    @media ${device.laptop} {
      height: auto;
    }

    img {
      height: calc(100% - 70px) !important;
      min-height: 300px;
    }

    @media ${device.laptop} {
      margin: 0 2rem 0 0;
    }

    p {
      font-size: 18px;

      @media ${device.laptop} {
        font-size: 24px;
      }
    }

    span {
      font-size: 18px;
      margin: 0;

      @media ${device.laptop} {
        font-size: 24px;
      }
    }

    a {
      text-decoration: none;
      display: flex;
      height: 100%;
      justify-content: space-between;
      flex-direction: column;
    }

    &:last-of-type {
      margin: 0;
    }
  }
`;

const RelatedProducts = ({ category, relatedProducts }) => {
  const [products, setProducts] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const { handle } = useParams();

  let firstClientX, clientX;
  let containerRef = createRef();

  const preventTouch = e => {
    const minValue = 5; // threshold

    clientX = e.touches[0].clientX - firstClientX;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;

      return false;
    }
  };

  const touchStart = e => {
    firstClientX = e.touches[0].clientX;
  };

  useEffect(() => {
    const fetchRelated = async () => {
      const api = await prismic;
      const response = await api.query(
        [
          Prismic.Predicates.at("document.type", "product"),
          Prismic.Predicates.not("my.product.uid", handle),
          Prismic.Predicates.at(
            "my.product.type",
            category.charAt(0).toUpperCase() + category.slice(1)
          )
        ],
        { orderings: "[document.first_publication_date desc]" }
      );
      if (response.results) {
        setProducts(response.results);
      }
    };

    const fetchManualRelated = async () => {
      const api = await prismic;
      const ids = [];
      relatedProducts.map(product => ids.push(product.related_product.id));
      console.log(relatedProducts);
      const response = await api.getByIDs(ids);
      // if (response.results) {
      //   setProducts(response.results);
      // }
    };

    if (!loaded) {
      if (relatedProducts.length < 1) {
        fetchRelated();
        setLoaded(true);
      } else {
        if (relatedProducts.length > 1) {
          fetchManualRelated();
          setLoaded(true);
        }
      }
    }

    let { current } = containerRef;

    if (current) {
      current.addEventListener("touchstart", touchStart);
      current.addEventListener("touchmove", preventTouch, {
        passive: false
      });
    }

    return () => {
      if (current) {
        current.removeEventListener("touchstart", touchStart);
        current.removeEventListener("touchmove", preventTouch, {
          passive: false
        });
      }
    };
  }, [
    loaded,
    containerRef,
    preventTouch,
    touchStart,
    category,
    relatedProducts
  ]);

  return (
    <Wrapper>
      {products.length > 0 && (
        <>
          {relatedProducts.length > 0 ? (
            <h2>More Products</h2>
          ) : (
            <h2>
              More Products in <Link to={`/shop/${category}`}>{category}</Link>
            </h2>
          )}
          {isMobile && products.length > 1 ? (
            <List ref={containerRef}>
              <Slider
                slidesToShow={1}
                centerMode
                arrows={false}
                centerPadding="60px"
              >
                {products.slice(0, 4).map((product, index) => {
                  const { variants, title, images } = product.data.product;
                  const { release_date, type, video } = product.data;
                  const { uid } = product;

                  return (
                    <ShopProduct
                      key={index}
                      variants={variants}
                      handle={uid}
                      type={type.toLowerCase()}
                      title={title}
                      video={video}
                      images={images}
                      releaseDate={release_date}
                    />
                  );
                })}
              </Slider>
            </List>
          ) : (
            <List>
              {products.slice(0, 4).map((product, index) => {
                const { variants, title, images } = product.data.product;
                const { release_date, type, video } = product.data;
                const { uid } = product;

                return (
                  <ShopProduct
                    key={index}
                    variants={variants}
                    handle={uid}
                    type={type.toLowerCase()}
                    title={title}
                    video={video}
                    images={images}
                    releaseDate={release_date}
                  />
                );
              })}
            </List>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default RelatedProducts;
