import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import Select from "./Select";
import Button from "./Button";
import Countdown, { zeroPad } from "react-countdown-now";
import moment from "moment";
import Moment from "react-moment";
import { openCart } from "../redux/actions/NavActions";
import { Link } from "react-router-dom";
import {
  createCheckout,
  addToCart,
  fetchCheckout
} from "../redux/actions/CartActions";
import { device } from "../utils/devices";

const Wrapper = styled.div`
  position: relative;
  background: ${props => props.background};
  overflow: hidden;
  display: flex;
  flex-direction: column-reverse;

  @media ${device.laptop} {
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-column: 1 / span 3;
    overflow: hidden;
    min-height: 320px;
  }
`;

const Info = styled.div`
  position: relative;
  padding: 5vw;
  z-index: 10;
  pointer-events: none;
  grid-column: 1;
  grid-row: 1;

  @media ${device.laptop} {
    padding: 2rem;
  }
`;

const Title = styled.h1`
  font-family: ${props => props.theme.type.heading};
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 400;
  margin: 0;

  @media ${device.laptop} {
    font-size: 12vh;
    line-height: 12vh;
  }
`;

const Sku = styled.div`
  left: 0;
  font-family: ${props => props.theme.type.new};
  color: ${props => props.theme.color.body};
  margin-bottom: 2rem;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 0.25em;

  &.countdown {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: 4rem;
  }

  span {
    display: block;
  }

  strong {
    color: rgb(228, 94, 97);
  }
`;

const Image = styled.div`
  height: 50vh;
  grid-column: ${props => (props.productStyle === "Hero" ? 2 : `1 / span 2`)};
  grid-row: 1;
  padding-top: 1rem;

  @media (orientation: landscape) {
    height: 80vh;
  }

  @media ${device.laptop} {
    height: 100vh;
  }

  img {
    width: 100%;
    height: 100%;
    transition: 0.2s all ease-in-out;
    object-fit: ${props =>
      props.productStyle === "Hero" ? "cover" : "contain"};
    object-position: center center;
  }
`;

const PostLink = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;

  a {
    text-indent: -999rem;
    display: block;
    width: 100%;
    height: 100%;
  }
`;

const Price = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 1rem 0;
  font-family: ${props => props.theme.type.new};

  span {
    font-weight: 500;
    font-size: 24px;
    position: relative;
    margin: 1rem 0;
    padding-left: ${props => (props.sale ? `2rem` : 0)};

    @media ${device.laptop} {
      font-size: 3.5vh;
    }
  }

  @media ${device.laptop} {
    margin: 0 0 2rem 0;
  }

  aside {
    font-weight: 500;
    font-size: 24px;
    position: relative;
    /* color: rgb(228,94,97); */

    &:after {
      position: absolute;
      top: 50%;
      left: -10%;
      /* left: -10%; */
      content: ${props => (props.sale ? `""` : `none`)};
      width: 120%;
      /* transform: rotate(15deg); */
      /* transform-origin: top left; */
      height: 4px;
      background: black;
    }

    @media ${device.laptop} {
      font-size: 3vh;
    }
  }
`;

const Release = styled.div`
  padding: 5vw;
  height: 100%;
  position: ${props =>
    props.productStyle === "Full-Width Hero" ? "absolute" : "relative"};
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;

  strong {
    color: ${props => props.color};
  }

  @media ${device.laptop} {
    padding: 2rem;
    width: ${props =>
      props.productStyle === "Full-Width Hero" ? `50vw` : `auto`};
  }

  h1 {
    margin-bottom: 1rem;
  }

  p {
    opacity: 0.4;
  }
`;

const Form = styled.form`
  position: relative;
  z-index: 8;

  @media ${device.laptop} {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-gap: 2rem;
  }

  input {
    border: 2px solid rgb(51, 51, 51);
    padding: 1rem;
    width: 100%;
    appearance: none;
    border-radius: 0;
    background: transparent;
    border-raduis: 0;
    color: rgb(51, 51, 51);
    box-sizing: border-box;
    font-family: ${props => props.theme.type.new};
    font-size: 16px;
    outline: 0;
    font-weight: 200;
    transition: 0.2s all ease-in-out;
    margin-bottom: 1rem;

    @media ${device.laptop} {
      margin: 0;
      height: 100%;
      font-size: 20px;
    }

    &:focus {
      background: white;
    }

    &::placeholder {
      color: rgb(51, 51, 51);
      font-family: ${props => props.theme.type.new};
      font-weight: 200;
      font-size: 16px;

      @media ${device.laptop} {
        font-size: 20px;
      }
    }
  }

  button {
    grid-row: auto;

    @media ${device.laptop} {
      height: 100%;
    }
  }
`;

const Drop = styled.div`
  font-family: ${props => props.theme.type.new};
  padding: 0;
  margin: 0 0 2rem;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 6vh;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;

  @media ${device.laptop} {
    padding: 2rem 0 1rem;
    font-size: 3vh;
    line-height: 6vh;
  }

  span {
    text-align: center;

    @media ${device.laptop} {
      text-align: left;
    }
  }

  strong {
    display: block;
    font-size: 36px;

    @media ${device.laptop} {
      font-size: 6vh;
    }
  }
`;

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  return (
    <Drop className="countdown">
      <span>
        <strong>{zeroPad(days, 2)}</strong> days
      </span>
      <span>
        <strong>{zeroPad(hours, 2)}</strong> hours
      </span>
      <span>
        <strong>{zeroPad(minutes, 2)}</strong> mins
      </span>
      <span>
        <strong>{zeroPad(seconds, 2)}</strong> secs
      </span>
    </Drop>
  );
};

class ProductHero extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    handle: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    image: PropTypes.string.isRequired,
    variants: PropTypes.array.isRequired,
    options: PropTypes.array.isRequired,
    createCheckout: PropTypes.func.isRequired,
    addToCart: PropTypes.func.isRequired,
    fetchCheckout: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      variant: "",
      variantSelected: false
    };
  }

  handleClick = () => {
    // Create a checkout if cart is empty

    const variantId = btoa(
      `gid://shopify/ProductVariant/${this.state.variant}`
    );

    if (this.props.cart.lineItems) {
      this.props.addToCart(this.props.cart.id, variantId).then(() => {
        this.props.openCart();
      });
    } else {
      this.props
        .createCheckout()
        .then(() => {
          this.props.addToCart(this.props.cart.id, variantId);
        })
        .then(() => {
          this.props.openCart();
        });
    }
  };

  handleChange = e => {
    this.setState({ variant: e.target.value });

    if (e.target.value !== "") {
      this.setState({ variantSelected: true });
    }
  };

  render() {
    const {
      sku,
      title,
      variants,
      handle,
      image,
      options,
      productStyle,
      type,
      releaseDate,
      color
    } = this.props;

    return (
      <Wrapper {...this.props}>
        <PostLink>
          <Link to={`/shop/${type.toLowerCase()}/${handle}`}>View Product</Link>
        </PostLink>
        {releaseDate ? (
          <Release productStyle={productStyle} color={color}>
            <Sku>OBJ — {sku.padStart(3, "0")}</Sku>
            <Title>{title}</Title>
            <Sku>
              Drops <Moment format="llll">{releaseDate}</Moment>
            </Sku>
            <Countdown date={moment(releaseDate)} renderer={renderer} />
            <Form>
              <input
                type="email"
                placeholder="Enter Email Address to be notified"
              />
              <Button>Notify Me</Button>
            </Form>
          </Release>
        ) : (
          <Info>
            <Sku>OBJ — {sku.padStart(3, "0")}</Sku>
            <Title>{title}</Title>
            <Price sale={variants[0].compare_at_price ? true : false}>
              {variants[0].compare_at_price ? (
                <>
                  <aside>${variants[0].compare_at_price}</aside>
                  <span>${variants[0].price}</span>
                </>
              ) : (
                <span>${variants[0].price}</span>
              )}
            </Price>
            <Select
              name={
                options.length > 1
                  ? `Select Style`
                  : `Select ${options[0].name}`
              }
              value={this.state.variant}
              variants={variants}
              onChange={this.handleChange}
            />
            <Button
              disabled={!this.state.variantSelected}
              onClick={this.handleClick}
            >
              Add to Cart
            </Button>
          </Info>
        )}
        <Image productStyle={productStyle}>
          <img alt={title} src={image} />
        </Image>
      </Wrapper>
    );
  }
}

export default connect(
  state => ({
    cart: state.cart
  }),
  { createCheckout, addToCart, fetchCheckout, openCart }
)(ProductHero);
