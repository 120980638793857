import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import Button from "../components/Button";
import { device } from "../utils/devices";
import { isMobile } from "react-device-detect";
import MailchimpSubscribe from "react-mailchimp-subscribe";

const cursor = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

const Wrapper = styled.section`
  background: rgb(62, 163, 134);
  font-family: ${props => props.theme.type.new};
  font-size: 18px;
  color: white;
  padding: 2.5vw;
  position: relative;

  @media ${device.laptop} {
    padding: 1rem 2rem;
  }

  button {
    grid-row: auto;
    appearance: none;
    border-radius: 0;
  }

  div {
    padding: 0.5rem;
  }

  h3 {
    font-family: ${props => props.theme.type.heading};
    text-transform: uppercase;
    font-size: 36px;
    margin: 0;
  }

  p {
    margin: 0;
  }
`;

const Form = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 3fr 1fr;
  margin-top: 1rem;
  grid-gap: 1rem;

  button {
    height: 50px;
    width: 100%;
    font-size: 14px;
    padding: 1rem;
    min-height: 40px;
    background: transparent !important;
    border: 2px solid white !important;
    font-family: ${props => props.theme.type.heading};
    grid-row: span 2;
    align-self: end;
    margin-bottom: 8vw;

    @media ${device.laptop} {
      width: 100px;
      height: auto;
      margin-bottom: 1vw;
      right: 0;
      font-size: 24px;
      justify-self: end;
    }
  }

  button {
    font-family: ${props => props.theme.type.heading};
  }

  input {
    border: 0;
    padding: 0;
    width: 100%;
    height: 30vw;
    appearance: none;
    background: transparent;
    border-radius: 0;
    color: white;
    box-sizing: border-box;
    font-family: ${props => props.theme.type.heading};
    font-size: 15vw;
    outline: 0;

    @media (orientation: landscape) {
      height: 20vw;
      font-size: 5vw;
    }

    @media ${device.laptop} {
      height: 7vw;
      font-size: 5vw;
      text-align: left;
      padding-top: 1vw;
    }

    &::placeholder {
      color: ${props => (props.focused ? `rgba(255,255,255,0.3)` : `white`)};
      font-family: ${props => props.theme.type.heading};
      font-weight: 600;
      font-size: 15vw;
      transition: 0.2s all ease-in-out;
      overflow: visible;

      @media (orientation: landscape) {
        font-size: 10vw;
      }

      @media ${device.laptop} {
        font-size: 5vw;
      }
    }
  }
`;

const Input = styled.div`
  position: relative;
  padding: 0 !important;

  &:focus {
    &:after {
      visibility: hidden;
    }
  }

  &:after {
    content: ${props => (props.focused ? `none` : `''`)};
    display: inline-block;
    position: absolute;
    width: 3px;
    height: 50%;
    left: 50vw;
    top: 55%;
    transform: translateY(-50%);
    background-color: white;
    animation: ${cursor} 1s steps(2, start) infinite;

    @media (orientation: landscape) {
      left: 30vw;
      top: 40%;
    }

    @media ${device.laptop} {
      left: 13vw;
      top: 55%;
    }
  }
`;

const Success = styled.div`
  color: white;
  padding: 2rem;
  font-family: ${props => props.theme.type.heading};
  font-weight: 600;
  font-size: 2vw;
  text-transform: uppercase;
  display: flex;
  align-items: center;
`;

const CustomForm = ({ status, message, onValidated, focused, setFocus }) => {
  let email;
  const submit = () =>
    email &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value
    });

  return (
    <Form focused={focused}>
      {isMobile ? (
        <span>Get the scoop on the latest from the Foul team.</span>
      ) : (
        <span>
          Subscribe to the Foul newsletter to get the scope on the latest
          releases, events, and upcoming projects from the Foul team.
        </span>
      )}
      <Button onClick={submit}>Subscribe</Button>
      {status === "success" ? (
        <Success>Thank you for Subscribing!</Success>
      ) : (
        <Input focused={focused}>
          <input
            type="email"
            ref={node => (email = node)}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            placeholder="Email"
          />
        </Input>
      )}
    </Form>
  );
};

class Subscribe extends Component {
  state = {
    focused: false
  };

  render() {
    const url = `https://foulstudios.us10.list-manage.com/subscribe/post?u=bbf1d609e390463d071ef1b4e&amp;id=6dbae4c864`;

    return (
      <Wrapper>
        <MailchimpSubscribe
          url={url}
          render={({ subscribe, status, message }) => (
            <CustomForm
              status={status}
              message={message}
              focused={this.state.focused}
              setFocus={value => this.setState({ focus: value })}
              onValidated={formData => subscribe(formData)}
            />
          )}
        />
      </Wrapper>
    );
  }
}
export default Subscribe;
