import React from "react";
import styled from "styled-components";
import { device } from "../utils/devices";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import PropTypes from "prop-types";
import moment from "moment";

const Info = styled.div`
  transition: all 0.2s ease-in-out;
  font-size: 18px;
  color: black;
  font-family: ${props => props.theme.type.heading};
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  padding: 1rem 0;

  @media ${device.laptop} {
    background: rgba(255, 255, 255, 0.6);
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    font-size: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    z-index: 2;
  }

  p {
    margin: 1rem 0;
  }

  span {
    font-family: ${props => props.theme.type.new};
  }
`;

const Product = styled.div`
  margin-bottom: 2rem;
  position: relative;

  @media ${device.laptop} {
    max-width: 30vw;

    &.single-image {
      &:hover {
        ${Info} {
          opacity: 1;
        }
      }
    }

    &.multi {
      max-width: 90vw;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    padding: 0;
    list-style-type: none;

    @media (orientation: landscape) {
      flex-direction: row;
      justify-content: center;
    }

    li {
      position: relative;

      @media ${device.laptop} {
        margin-right: 3vw;

        &:last-of-type {
          margin: 0;
        }
      }

      &:hover {
        ${Info} {
          opacity: 1;
        }
      }
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;

    @media (orientation: landscape) {
      max-height: 70vh;
    }

    @media ${device.laptop} {
      max-height: 100vh;
    }
  }
`;

const Price = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 1rem 0;
  font-family: ${props => props.theme.type.new};

  span {
    font-weight: 500;
    font-size: 24px;
    position: relative;
    margin: 1rem 0;
    color: black;
    padding-left: ${props => (props.sale ? `2rem` : 0)};

    @media ${device.laptop} {
      font-size: 3.5vh;
    }
  }

  @media ${device.laptop} {
    margin: 0;
  }

  aside {
    font-weight: 500;
    font-size: 18px;
    position: relative;

    &:after {
      position: absolute;
      top: 50%;
      left: -10%;
      /* left: -10%; */
      content: ${props => (props.sale ? `""` : `none`)};
      width: 120%;
      /* transform: rotate(15deg); */
      /* transform-origin: top left; */
      height: 4px;
      background: black;
    }

    @media ${device.laptop} {
      font-size: 3vh;
    }
  }
`;

const ShopProduct = ({
  handle,
  type,
  title,
  images,
  variants,
  releaseDate,
  showVariants
}) => {
  let releaseDateUpcoming = false;

  if (moment(releaseDate).valueOf() > Date.now()) {
    releaseDateUpcoming = true;
  }

  const renderImages = () => {
    return images.slice(0, 3).map((image, index) => {
      const { variant_ids } = image;

      let totalVariantInventory = 0;

      let imageVariants = variants.filter(variant => {
        return variant_ids.indexOf(variant.id) !== -1;
      });

      console.log(imageVariants);

      totalVariantInventory = imageVariants.reduce(
        (a, { inventory_quantity }) => a + inventory_quantity,
        0
      );

      return (
        <li key={index}>
          <img alt={title} src={image.src} />
          <Info className="info">
            <p>{title}</p>
            <Price
              className="price"
              sale={variants[0].compare_at_price ? true : false}
            >
              {variant_ids.length > 0 && totalVariantInventory === 0 ? (
                <span>Sold Out</span>
              ) : variants[0].compare_at_price ? (
                <>
                  <aside>${variants[0].compare_at_price}</aside>
                  <span>${variants[0].price}</span>
                </>
              ) : (
                <span>${variants[0].price}</span>
              )}
            </Price>
          </Info>
        </li>
      );
    });
  };

  if (showVariants && images[1] && images[0].src !== images[1].src) {
    return (
      <Product className="multi">
        <Link to={`/shop/${type}/${handle}`}>
          <ul>{renderImages()}</ul>
        </Link>
      </Product>
    );
  } else {
    let soldOut = true;

    variants.map(variant => {
      if (variant.inventory_quantity > 0) {
        return (soldOut = false);
      } else {
        return soldOut;
      }
    });

    return (
      <Product className="single-image">
        <Link to={`/shop/${type}/${handle}`}>
          <img alt={title} src={images[0].src} />
          <Info className="info" releaseDateUpcoming={true}>
            {releaseDateUpcoming ? (
              <>
                <p>{title}</p>
                <Price className="price">
                  <span>
                    Drops <Moment fromNow>{releaseDate}</Moment>
                  </span>
                </Price>
              </>
            ) : (
              <>
                <p>{title}</p>
                <Price
                  className="price"
                  sale={variants[0].compare_at_price ? true : false}
                >
                  {soldOut ? (
                    <span>Sold Out</span>
                  ) : variants[0].compare_at_price ? (
                    <>
                      <aside>${variants[0].compare_at_price}</aside>
                      <span>${variants[0].price}</span>
                    </>
                  ) : (
                    <span>${variants[0].price}</span>
                  )}
                </Price>
              </>
            )}
          </Info>
        </Link>
      </Product>
    );
  }
};

ShopProduct.propTypes = {
  handle: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  images: PropTypes.array.isRequired,
  variants: PropTypes.array.isRequired,
  showVariants: PropTypes.bool
};

export default ShopProduct;
