import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import ExpandableMenu from "./ExpandableMenu";

const Chart = styled.div`
  display: grid;
  color: rgb(51, 51, 51);
  grid-template-columns: ${props => `repeat(${props.cols}, 1fr)`};
  grid-column-gap: 1rem;
  grid-template-rows: 2rem 2rem;

  @media (orientation: landscape) {
    margin-top: 1rem;
  }
`;

const SizeChart = () => {
  const { category } = useParams();

  return (
    <ExpandableMenu border title="Size & Fit">
      <Chart cols={category === "shorts" ? 6 : 5}>
        {(() => {
          switch (category) {
            case "tees":
              return (
                <>
                  <p>
                    <strong>SIZE</strong>
                  </p>
                  <p>
                    <strong>S</strong>
                  </p>
                  <p>
                    <strong>M</strong>
                  </p>
                  <p>
                    <strong>L</strong>
                  </p>
                  <p>
                    <strong>XL</strong>
                  </p>
                  <p>
                    <strong>Width</strong>
                  </p>
                  <p>18"</p>
                  <p>20"</p>
                  <p>22"</p>
                  <p>24"</p>
                  <p>
                    <strong>Length</strong>
                  </p>
                  <p>28"</p>
                  <p>29"</p>
                  <p>30"</p>
                  <p>31"</p>
                  <p>
                    <strong>Sleeve</strong>
                  </p>
                  <p>33.5"</p>
                  <p>35"</p>
                  <p>36.5"</p>
                  <p>38"</p>
                </>
              );
            case "sweaters":
              return (
                <>
                  <p>
                    <strong>SIZE</strong>
                  </p>
                  <p>
                    <strong>S</strong>
                  </p>
                  <p>
                    <strong>M</strong>
                  </p>
                  <p>
                    <strong>L</strong>
                  </p>
                  <p>
                    <strong>XL</strong>
                  </p>
                  <p>
                    <strong>WAIST</strong>
                  </p>
                  <p>30"</p>
                  <p>32"</p>
                  <p>34"</p>
                  <p>36"</p>
                  <p>
                    <strong>LENGTH</strong>
                  </p>
                  <p>24"</p>
                  <p>24.25"</p>
                  <p>24.25"</p>
                  <p>25"</p>
                </>
              );
            case "shorts":
              return (
                <>
                  <p>
                    <strong>SIZE</strong>
                  </p>
                  <p>
                    <strong>XS</strong>
                  </p>
                  <p>
                    <strong>S</strong>
                  </p>
                  <p>
                    <strong>M</strong>
                  </p>
                  <p>
                    <strong>L</strong>
                  </p>
                  <p>
                    <strong>XL</strong>
                  </p>
                  <p>
                    <strong>WAIST</strong>
                  </p>
                  <p>23"</p>
                  <p>25"</p>
                  <p>27"</p>
                  <p>30"</p>
                  <p>33"</p>
                </>
              );

            default:
              // Bottoms
              return (
                <>
                  <p>
                    <strong>SIZE</strong>
                  </p>
                  <p>
                    <strong>S</strong>
                  </p>
                  <p>
                    <strong>M</strong>
                  </p>
                  <p>
                    <strong>L</strong>
                  </p>
                  <p>
                    <strong>XL</strong>
                  </p>
                  <p>
                    <strong>WAIST</strong>
                  </p>
                  <p>30"</p>
                  <p>32"</p>
                  <p>34"</p>
                  <p>36"</p>
                  <p>
                    <strong>LENGTH</strong>
                  </p>
                  <p>30"</p>
                  <p>32"</p>
                  <p>34"</p>
                  <p>36"</p>
                </>
              );
          }
        })()}
      </Chart>
    </ExpandableMenu>
  );
};

export default SizeChart;
