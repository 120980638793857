import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Picture } from "react-responsive-picture";

const Wrapper = styled.div``;

const Title = styled.h2`
  color: white;
  position: absolute;
  top: 100px;
  width: 100vw;
  pointer-events: none;
  font-family: ${props => props.theme.type.heading};
  font-size: 48px;
  padding: 2rem;
  box-sizing: border-box;
  text-align: center;
  text-transform: uppercase;
  z-index: 10;
  opacity: ${props => (props.hide ? 0 : 1)};
`;

const Video = styled.div`
  height: auto;
  background: black;
  display: flex;
  align-items: center;
  position: relative;

  .player {
    width: auto !important;
    z-index: ${props => (props.active ? `999` : `auto`)};
    height: 80vh !important;

    video {
      object-fit: cover;
      object-position: center;
    }
  }

  p {
    color: white;
    z-index: 10;
    position: absolute;
    bottom: 2rem;
    font-size: 24px;
    pointer-events: none;
    font-family: ${props => props.theme.type.new};
    width: 100vw;
    box-sizing: border-box;
    text-align: center;
    text-transform: uppercase;
  }

  span {
    color: white;
    position: absolute;
    bottom: 10vw;
    font-weight: 100;
    font-size: 15vw;
    font-family: ${props => props.theme.type.new};
    width: 100vw;
    text-align: center;
  }
`;

const TextSlide = styled.div`
  width: 90vw;
  margin: 4rem auto;
  box-sizing: border-box;
  display: flex;
  align-items: center;
`;

const Text = styled.div`
  font-family: ${props => props.theme.type.new};

  span {
    text-transform: uppercase;
  }

  h2 {
    font-size: 4vh;
  }

  p {
    font-size: 12px;
    margin: 0;
    line-height: 1.5em;
  }
`;

const GallerySlide = styled.div`
  display: flex;

  img {
    width: 100vw;
    height: intrinsic;
  }
`;

const Preview = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding-top: 2rem;
  font-family: ${props => props.theme.type.new};

  a {
    color: rgb(51, 51, 51);
    text-decoration: none;
  }

  span {
    text-transform: uppercase;
  }

  section {
    text-align: center;
    padding: 2rem 0;
  }

  picture {
    width: 100vw;
    height: 60vw;
    overflow: hidden;
    display: block;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

const Progress = styled.progress`
  position: fixed;
  top: 72px;
  left: 1rem;
  width: calc(100vw - 2rem);
  height: 5px;
  z-index: 800;
  appearance: none;
  pointer-events: none;

  &::-webkit-progress-bar {
    background: transparent;
  }

  &::-webkit-progress-value {
    background: #5bc0eb;
  }
`;

const ProjectBody = ({ video, body, title, sku, next }) => {
  const [progress, setProgress] = useState(0);
  const [videoActive, activateVideo] = useState(false);
  const [videoPlaying, playVideo] = useState(true);

  const player = useRef(null);

  const handleActivateVideo = () => {
    activateVideo(!videoActive);
    playVideo(!videoPlaying);
  };

  const listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const scrolled = winScroll / height;
    setProgress(scrolled);
    activateVideo(false);
  };

  const renderGallery = items => {
    return items.map((image, index) => {
      const { small, medium, large, url } = image.gallery_image;

      return (
        <GallerySlide key={index}>
          <Picture
            src={`${small.url} 480w,
            ${medium.url} 960w,
            ${large.url} 1440w,
            ${url}`}
            sizes="480w, 960w, 1440w, 2000w"
          />
        </GallerySlide>
      );
    });
  };

  const renderText = items => {
    return items.map((node, index) => {
      switch (node.type) {
        case "heading2":
          return <h2 key={index}>{node.text}</h2>;
        default:
          return <p key={index}>{node.text}</p>;
      }
    });
  };

  const renderBody = () => {
    return body.map((node, index) => {
      switch (node.slice_type) {
        case "text":
          return (
            <TextSlide className="textblock" key={index}>
              <Text>
                <span>
                  {sku} / {title}
                </span>
                {renderText(node.primary.text)}
              </Text>
            </TextSlide>
          );
        case "gallery":
          return renderGallery(node.items);
        case "video":
          return (
            <Video active={videoActive} key={index}>
              <ReactPlayer
                ref={player}
                className="player"
                playsinline
                controls={videoActive}
                onClick={() => handleActivateVideo()}
                playing
                loop
                url={node.primary.video.url}
                muted={!videoActive}
              />
              {videoActive ? (
                <span onClick={handleActivateVideo}>×</span>
              ) : (
                <p>Play Video</p>
              )}
            </Video>
          );
        default:
          return false;
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);

    return () => window.removeEventListener("scroll", listenToScroll);
  });

  return (
    <Wrapper className="wrapper">
      <Progress className="progress" value={progress} max="1" />
      {body.length > 0 && (
        <div>
          <Title hide={videoActive}>{title}</Title>
          {renderBody()}
          {typeof next !== "undefined" && (
            <div>
              <Preview>
                <Link to={`/projects/${next.uid}`}>
                  <aside>
                    <section>
                      <div>
                        <span>Next Project</span>
                        <h2>{next.data.title[0].text}</h2>
                        <span>PRJ — {next.data.sku.padStart(3, "0")}</span>
                      </div>
                    </section>
                    <picture>
                      <img
                        src={next.data.featured_image.url}
                        alt={next.data.title[0].text}
                      />
                    </picture>
                  </aside>
                </Link>
              </Preview>
            </div>
          )}
        </div>
      )}
    </Wrapper>
  );
};

ProjectBody.propTypes = {
  video: PropTypes.string,
  body: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  sku: PropTypes.string.isRequired,
  next: PropTypes.object.isRequired
};

export default ProjectBody;
