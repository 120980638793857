import React from "react";
import styled from "styled-components";
import Button from "./Button";
import ColorSelect from "./ColorSelect";
import SizeSelect from "./SizeSelect";
import Countdown, { zeroPad } from "react-countdown-now";
import { device } from "../utils/devices";
import { isBrowser } from "react-device-detect";
import SizeChart from "./SizeChart";
import DeliveryInfo from "./DeliveryInfo";
import moment from "moment";
import { Helmet } from "react-helmet";
import Moment from "react-moment";
import stripHtml from "string-strip-html";
import { withOrientationChange } from "react-device-detect";
import { useLocation } from "react-router-dom";
const Info = styled.div`
  grid-area: info;
  position: relative;
  width: 100%;

  @media ${device.laptop} {
    padding: 0 2rem;
    position: relative;
  }

  .select-wrapper,
  button {
    width: 100% !important;
  }

  .image_zoom {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    overflow: hidden;
    z-index: 9999;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
`;

const Title = styled.h2`
  font-family: ${props => props.theme.type.heading};
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
  margin: 0 0 1rem 0;
  text-transform: uppercase;

  @media (orientation: landscape) {
    font-size: 20px;
    margin-bottom: 0;
  }

  @media ${device.laptop} {
    font-size: 7vh;
    margin-bottom: 1rem;
    line-height: 8vh;
  }
`;

const Price = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 1rem 0;
  font-family: ${props => props.theme.type.new};

  h5 {
    font-weight: 500;
    font-size: 24px;
    position: relative;
    margin: 0;
    padding-left: ${props => (props.sale ? `2rem` : 0)};
    color: rgb(62, 163, 134);

    @media (orientation: landscape) {
      font-size: 18px;
    }

    @media ${device.laptop} {
      font-size: 3vh;
    }

    @media ${device.laptopL} {
      font-size: 3.5vh;
    }
  }

  @media ${device.laptop} {
    margin: 0;
  }

  aside {
    font-weight: 500;
    font-size: 24px;
    position: relative;
    /* color: rgb(228,94,97); */

    &:after {
      position: absolute;
      top: 50%;
      left: -10%;
      /* left: -10%; */
      content: ${props => (props.sale ? `""` : `none`)};
      width: 120%;
      /* transform: rotate(15deg); */
      /* transform-origin: top left; */
      height: 4px;
      background: black;
    }

    @media (orientation: landscape) {
      font-size: 18px;
    }

    @media ${device.laptop} {
      font-size: 3vh;
    }
  }

  span {
    margin-left: 1rem;
    font-size: 12px;
    border: 2px solid black;
    padding: 0.5rem;
    font-weight: 600;
    text-transform: uppercase;
  }
`;

const Desc = styled.p`
  margin: 1rem 0;
  font-size: 16px;
  line-height: 20px;
  font-weight: 100;
  font-family: ${props => props.theme.type.new};

  @media (orientation: landscape) {
    margin: 1rem 0 2rem;
  }

  p {
    margin: 0 0 1rem;

    &:last-of-type {
      margin: 0;
    }
  }

  @media ${device.laptopL} {
    font-size: 18px;
  }

  ul {
    padding: 0 0 0 1rem;
  }
`;

const Release = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding-top: 2rem;

  p {
    font-family: ${props => props.theme.type.new};
    text-transform: uppercase;
    font-size: 18px;
    margin-bottom: 2rem;

    @media ${device.laptop} {
      font-size: 1.5vw;
      letter-spacing: 0.2rem;
      margin: 0;
    }

    time {
      display: inline-block;
    }
  }

  span strong {
    color: ${props => props.color};
  }

  time {
    padding-top: 0.5rem;
    display: block;
  }
`;

const Notify = styled.div`
  @media ${device.laptop} {
    display: flex;
  }

  p {
    opacity: 0.4;
    font-size: 14px;
  }

  input {
    border: 2px solid black;
    padding: 1rem;
    width: 100%;
    appearance: none;
    border-raduis: 0;
    box-sizing: border-box;
    font-family: ${props => props.theme.type.new};
    font-size: 14px;

    @media ${device.laptop} {
      border-right: 0;
    }
  }

  &.countdown {
    button {
      box-sizing: border-box;
      height: auto !important;
      min-height: 0;
    }
  }
`;

const Meta = styled.div`
  margin-top: 1.5rem;

  @media ${device.laptopL} {
    margin-top: 3rem;
  }
`;

const Drop = styled.div`
  font-family: ${props => props.theme.type.new};
  padding: 0;
  margin: 0 0 2rem;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 6vh;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;

  @media ${device.laptop} {
    display: flex;
    padding: 1rem 0;
    font-size: 1.5vw;
    line-height: 6vh;
  }

  span {
    text-align: center;

    @media ${device.laptop} {
      text-align: left;
      margin-right: 2vw;
    }
  }

  strong {
    display: block;
    font-size: 13vw;

    @media (orientation: landscape) {
      font-size: 5vw;
      padding-bottom: 1rem;
    }

    @media ${device.laptop} {
      font-size: 4vw;
      padding: 0;
    }
  }
`;

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  return (
    <Drop className="countdown">
      <span>
        <strong>{zeroPad(days, 2)}</strong> days
      </span>
      <span>
        <strong>{zeroPad(hours, 2)}</strong> hours
      </span>
      <span>
        <strong>{zeroPad(minutes, 2)}</strong> mins
      </span>
      <span>
        <strong>{zeroPad(seconds, 2)}</strong> secs
      </span>
    </Drop>
  );
};

const ProductInfo = ({
  images,
  handleThumbClick,
  title,
  variants,
  variantSelected,
  addToCart,
  description,
  size,
  currentValue,
  sizeOptions,
  colorOptions,
  onSizeChange,
  onColorChange,
  inventory,
  releaseDate,
  sku,
  color,
  allowRestockNotification,
  textColor
}) => {
  const { pathname } = useLocation();

  return (
    <Info id="product_info">
      <Helmet title={`${title} — Foul Studios`}>
        <meta
          property="og:url"
          content={`https://unruffled-northcutt-779348.netlify.com/${pathname}`}
        />
        <meta property="og:type" content="product" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={stripHtml(description)} />
        <meta property="og:image" content={images[0].src} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@foulstudios" />
        <script type="application/ld+json">
          {`
          {
            "@context": "http://www.schema.org",
            "@type": "product",
            "brand": "Foul Studios",
            "sku": "${sku}",
            "logo": "http://www.example.com/logo.png",
            "name": "${title}",
            "description": "${stripHtml(description)}",
            "image": "${images[0].src}",
            "offers": {
              "@type": "Offer",
                "price": "${variants[0].price}",
                "priceCurrency": "USD"
            }
          }
        `}
        </script>
      </Helmet>
      <Title>{title}</Title>
      <Price
        sale={
          variants.find(variant => variant.option1 === color).compare_at_price
            ? true
            : false
        }
      >
        {variants.find(variant => variant.option1 === color)
          .compare_at_price ? (
          <>
            <aside>
              $
              {
                variants.find(variant => variant.option1 === color)
                  .compare_at_price
              }
            </aside>
            <h5>
              ${variants.find(variant => variant.option1 === color).price}
            </h5>
          </>
        ) : (
          <h5>${variants.find(variant => variant.option1 === color).price}</h5>
        )}
        {inventory < 5 && inventory > 1 && <span>Few Left</span>}
        {inventory < 2 && inventory > 0 && <span>Only One Left</span>}
        {inventory < 1 && <span>Sold Out</span>}
      </Price>
      {(isBrowser || window.matchMedia("(orientation: portrait)").matches) && (
        <Desc
          dangerouslySetInnerHTML={{ __html: description }}
          className="test"
        />
      )}
      {releaseDate ? (
        <Release color={textColor}>
          <p>
            Drops <Moment format="llll">{releaseDate}</Moment>
          </p>
          <Countdown date={moment(releaseDate)} renderer={renderer} />
          <Notify>
            <input type="email" placeholder="Enter email to be notified" />
            <Button>Notify Me</Button>
          </Notify>
        </Release>
      ) : (
        <>
          {colorOptions && (
            <ColorSelect
              value={color}
              size={size}
              images={images}
              handleThumbClick={handleThumbClick}
              variants={variants}
              onChange={onColorChange}
              options={colorOptions}
            />
          )}
          {sizeOptions && (
            <SizeSelect
              value={size}
              color={color}
              variants={variants}
              options={sizeOptions}
              onChange={onSizeChange}
            />
          )}

          {inventory > 0 ? (
            variants.length > 1 ? (
              <Button disabled={!variantSelected} onClick={addToCart}>
                Add to Cart
              </Button>
            ) : (
              <>
                <br />
                <Button onClick={addToCart}>Add to Cart</Button>
              </>
            )
          ) : allowRestockNotification ? (
            <Notify>
              <input
                type="email"
                placeholder="Enter email to be notified of restock"
              />
              <Button>Notify Me</Button>
            </Notify>
          ) : (
            <Button disabled>Sold Out</Button>
          )}
          <Meta>
            {(isBrowser ||
              window.matchMedia("(orientation: portrait)").matches) && (
              <>
                <SizeChart />
              </>
            )}
          </Meta>
        </>
      )}
    </Info>
  );
};

export default withOrientationChange(ProductInfo);
