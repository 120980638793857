import React from "react";
import Footer from "./Footer";
import styled from "styled-components";
import { device } from "../utils/devices";

const Wrapper = styled.div`
  width: 90vw;
  max-width: 900px;
  margin: 2rem auto 0;
  font-family: ${props => props.theme.type.new};
  color: rgb(51, 51, 51);
  line-height: 1.4em;
  padding-bottom: 4rem;

  @media ${device.laptop} {
    margin: 8rem auto 4rem;
  }

  h2 {
    margin-bottom: 2rem;
  }

  a {
    color: black;
  }
`;

const Bottom = styled.div`
  left: 0;
  box-sizing: border-box;
`;

const Page = ({ children }) => (
  <Wrapper>
    {children}
    <Bottom>
      <Footer />
    </Bottom>
  </Wrapper>
);

export default Page;
