import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: absolute;
  left: 10vw;
  z-index: 2;
  border: ${props => props.open ? `1px solid rgba(0,0,0,0.12)` : 0 };
  padding: 1rem;
  box-sizing: border-box;
  font-size: 18px;
  font-family: ${props => props.theme.type.new};
  text-transform: uppercase;
  line-height: 2em;
  width: 80vw;
  margin-top: 2rem;
  background: white;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  p {
    margin: 0;
    font-size: 30px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    &:after {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 6px 12px 6px;
      margin-left: 1rem;
      border-color: transparent transparent #000000 transparent;
      content: "";
      transform: ${props => props.open ? 'rotate(0deg)' : 'rotate(180deg)'};
    }
  }
`;

const DiarySelect = ({ open, current, openSelect, handleFilter }) => (
  <Wrapper open={open} onClick={openSelect}>
    <p>{current === '' ? 'All' : current}</p>
    {open
      &&
        <ul>
          <li onClick={() => handleFilter('')}>All</li>
          <li id="events" onClick={() => handleFilter('Events')}>Events</li>
          <li id="music" onClick={() => handleFilter('Music')}>Music</li>
          <li id="news" onClick={() => handleFilter('News')}>News</li>
          <li id="art-and-design" onClick={() => handleFilter('Art & Design')}>Art & Design</li>
          <li id="photography" onClick={() => handleFilter('Photography')}>Photography</li>
          <li id="travel" onClick={() => handleFilter('Travel')}>Travel</li>
          <li id="releases" onClick={() => handleFilter('Releases')}>Releases</li>
        </ul>
    }
  </Wrapper>
);

export default DiarySelect;
