import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { fetchPosts } from "../redux/actions/PostActions";
import Product from "./Product";
import ProductHero from "./ProductHero";
import moment from "moment";
import Article from "./Article";
import Event from "./Event";
import Video from "./Video";
import Project from "./Project";
import Subscribe from "./Subscribe";
import AudioFeature from "./AudioFeature";

const Loader = styled.div`
  div {
    height: 30vh;
    padding: 2vw;
    min-height: 700px;
    background: rgba(0, 0, 0, 0.1);

    &:nth-of-type(2n) {
      background: rgba(0, 0, 0, 0.2);
    }
  }
`;

class Feed extends Component {
  state = {
    isLoaded: false
  };

  componentDidMount() {
    this.props.fetchPosts().then(res => {
      this.setState({ isLoaded: true });
    });
  }

  renderFeed() {
    if (this.props.posts.results) {
      let posts = [...this.props.posts.results];

      posts.splice(8, 0, { type: "subscribe" });

      return posts.map((post, index) => {
        switch (post.type) {
          case "project":
            // Set the video variable
            let video = null;

            // Check if the body has a video
            post.data.body.map(node => {
              if (node.slice_type === "video") {
                return (video = node.primary.video.url);
              } else {
                return false;
              }
            });

            return (
              <Project
                key={index}
                size={post.data.size}
                background={post.data.background}
                uid={post.uid}
                video={video}
                sku={post.data.sku}
                title={post.data.title[0].text}
                image={
                  post.data.featured_image.url
                    ? post.data.featured_image.url
                    : null
                }
              />
            );
          case "video":
            return (
              <Video
                key={index}
                url={post.data.video_url.url}
                title={post.data.title[0].text}
              />
            );
          case "product":
            const {
              product,
              style,
              background,
              release_date,
              type,
              show_on_homepage
            } = post.data;

            if (show_on_homepage === "No") {
              return;
            }

            // Figure out if the release date is upcoming or passed
            let productReleaseDate = null;
            if (moment(release_date).valueOf() > Date.now()) {
              productReleaseDate = moment(release_date).valueOf();
            }
            if (style === "Hero" || style === "Full-Width Hero") {
              return (
                <ProductHero
                  key={index}
                  sku={post.data.sku}
                  handle={post.uid}
                  id={product.id}
                  type={type}
                  releaseDate={productReleaseDate}
                  color={post.data.color}
                  productStyle={style}
                  background={background}
                  price={product.variants[0].price}
                  title={product.title}
                  description={product.body_html}
                  image={product.image.src}
                  images={product.images}
                  variants={product.variants}
                  options={product.options}
                />
              );
            } else {
              return (
                <Product
                  key={index}
                  sku={post.data.sku}
                  imageLimit={post.data.shopify_image_limit}
                  overrideImages={post.data.images}
                  shouldOverride={post.data.override_shopify_images}
                  handle={post.uid}
                  id={product.id}
                  type={type}
                  price={product.variants[0].price}
                  title={product.title}
                  description={product.body_html}
                  image={product.image.src}
                  images={product.images}
                  variants={product.variants}
                  options={product.options}
                  releaseDate={productReleaseDate}
                />
              );
            }
          case "audio_feature":
            return (
              <AudioFeature
                key={index}
                url={post.data.url.embed_url}
                fallback={post.data.fallback_url.url}
                title={post.data.title[0].text}
              />
            );
          case "subscribe":
            return <Subscribe key={index} />;
          case "event":
            const { title, venue, image, link, time } = post.data;
            if (moment(time).valueOf() > Date.now()) {
              return (
                <Event
                  key={index}
                  venue={venue[0].text}
                  title={title[0].text}
                  time={time}
                  link={link}
                  video={post.data.video}
                  image={image.url ? image.url : null}
                />
              );
            } else {
              return false;
            }

          default:
            if (post.data.shown_on_homepage === "Yes") {
              return (
                <div key={index}>
                  <Article
                    uid={post.uid}
                    date={post.first_publication_date}
                    large={post.data.size}
                    title={post.data.title[0].text}
                    thumb={post.data.thumbnail.url}
                  />
                </div>
              );
            } else {
              return null;
            }
        }
      });
    }
  }

  render() {
    return (
      <div>
        {this.state.isLoaded ? (
          this.renderFeed()
        ) : (
          <Loader>
            <div />
            <div />
            <div />
          </Loader>
        )}
      </div>
    );
  }
}

export default connect(
  state => ({
    posts: state.posts
  }),
  { fetchPosts }
)(Feed);
