import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { device } from '../utils/devices'
import { Link } from 'react-router-dom'
import Moment from 'react-moment'
import ReactPlayer from 'react-player'
import { isBrowser } from 'react-device-detect'

const Image = styled.picture`
  display: block;
  height: 60vw;

  @media (orientation: landscape){
    height: 40vw;
  }

  @media ${device.laptop}{
    height: 15vw;
    overflow: hidden;
  }

  img {
    display: block;
    width: 100% !important;
    height: 100%;
    object-fit: cover !important;
    transition: 0.3s all ease-in-out;
  }
`;

const Video = styled.div`
  width: 100%;
  height: 15vw;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  transition: 0.2s all ease-in-out;

  @media ${device.laptop}{
    overflow: hidden;
  }

  .player {
    width: 150% !important;
    height: 150% !important;
    pointer-events: none;
    margin-top: -5vw;

    video, iframe {
      object-fit: cover;
    }
  }
`;

const Post = styled.div`
  position: relative;
  margin-bottom: 2rem;
  text-align: center;
  overflow: hidden;

  @media ${device.laptop}{
    margin-bottom: 0;
  }

  &:hover {
    ${Image} {
      img {
        transform: scale(1.05);
      }
    }

    ${Video} {
      opacity: 1;
    }
  }

  a {
    display: block;
    height: 100%;
    color: black;
    text-decoration: none;
  }
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: 600;
  font-weight: 600;
  font-family: ${props => props.theme.type.new};
  text-transform: uppercase;
  margin: 0;
  padding: 0 1rem;

  @media (orientation: landscape){
    font-size: 18px;
  }

  @media ${device.laptop}{
    font-size: 1vw;
  }
`;

const Date = styled.span`
  opacity: 0.5;
  display: block;
  margin: 1rem 0 .5rem;
  font-weight: bold;
  font-family: ${props => props.theme.type.new};
  text-transform: uppercase;
  letter-spacing: 0.05vw;

  @media ${device.laptop}{
    font-size: .8vw;
  }
`;

const DiaryPost = ({ uid, date, thumbnail, title, category, video }) => {
  return (
    <Post>
      <Link to={`/random/${uid}`}>
        {video && isBrowser &&
          <Video>
          <ReactPlayer
            className='player'
            playing
            loop
            playsinline
            playsInline
            url={video}
            muted
          />
          </Video>
        }
        <Image><img src={thumbnail.url} alt={uid} /></Image>
        <Date><Moment format='MMMM DD, Y'>{date}</Moment></Date>
        <Title>{title}</Title>
      </Link>
    </Post>
  )
}

DiaryPost.propTypes = {
  date: PropTypes.string,
  title: PropTypes.string
}

export default DiaryPost;
