import { useEffect, useState } from 'react'
import Prismic from 'prismic-javascript'

const apiEndpoint = 'https://foul-studios.cdn.prismic.io/api/v2'
const apiToken = 'MC5XNmZsdnljQUFDVUE0NHJv.77-977-977-9He-_vUdVfBnvv73vv70BD--_vRN8b--_vTbvv73vv70RMO-_ve-_ve-_vStZ77-977-9OGM';

const Client = Prismic.client(apiEndpoint, { apiToken })

const useProducts = (category) => {

  const [products, setProducts] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [pageSize, setPageSize] = useState(20)

  useEffect(() => {

    const fetchProducts = async () => {
      const response = await Client.query(
        Prismic.Predicates.at('document.type', 'product'),
      )

      if(response){
        setProducts(response.results)
        setLoaded(true)
      }
    }

    const fetchEquipment = async () => {
      const response = await Client.query([
        Prismic.Predicates.at('document.type', 'product'),
        Prismic.Predicates.at('my.product.type', 'Equipment'),
      ]
      )

      if(response){
        setProducts(response.results)
        setLoaded(true)
      }
    }

    const fetchApparel = async () => {
      const response = await Client.query([
        Prismic.Predicates.at('document.type', 'product'),
        Prismic.Predicates.not('my.product.type', 'Equipment'),
      ]
      )

      if(response){
        setProducts(response.results)
        setLoaded(true)
      }
    }

    switch (category) {
      case 'Equipment':
        fetchEquipment()
        break;
      case 'Apparel':
        fetchApparel()
        break;
      default:
        fetchProducts()
    }
  }, [pageSize, category])

  return {
    loaded,
    products,
    pageSize,
    setPageSize
  }
}

export default useProducts
