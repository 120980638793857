import React, { Component } from 'react'
import styled from 'styled-components'
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, PinterestShareButton, PinterestIcon } from 'react-share'
import { withRouter } from 'react-router-dom'

const Wrapper = styled.div`
  margin: 2rem auto;
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;

  li {
    cursor: pointer;
    margin-right: 1rem;

    a {
      color: black;
    }

    &:last-of-type {
      margin: 0;
    }
  }
`;

class Share extends Component {

  render() {
    return (
      <Wrapper>
        <List>
          <li><FacebookShareButton url={`https://unruffled-northcutt-779348.netlify.com/${this.props.location.pathname}`}><FacebookIcon iconBgStyle={{ fill: 'white' }} logoFillColor='black' size={36} /></FacebookShareButton></li>
          <li><TwitterShareButton via='foulstudios' title={this.props.title} url={`https://unruffled-northcutt-779348.netlify.com/${this.props.location.pathname}`}><TwitterIcon iconBgStyle={{ fill: 'white'}} logoFillColor='black' size={36} /></TwitterShareButton></li>
          <li><PinterestShareButton media={this.props.image} description={this.props.title} url={`https://unruffled-northcutt-779348.netlify.com/${this.props.location.pathname}`}><PinterestIcon iconBgStyle={{ fill: 'white'}} logoFillColor='black' size={36} /></PinterestShareButton></li>
        </List>
      </Wrapper>
    );
  }

}

export default withRouter(Share);
