import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { device } from "../utils/devices";

const Wrapper = styled.nav`
  position: fixed;
  top: calc(${props => props.theme.layout.headerHeight} + 1rem);
  left: 1rem;
  width: calc(100vw - 2rem);
  font-size: 16px;
  font-family: ${props => props.theme.type.heading};
  font-weight: 800;
  color: ${props => props.theme.color.body};
  background: ${props => props.theme.color.primary};
  padding: 2rem 1rem 0;
  box-shadow: 0px 1rem 10px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  transition: 0.2s all ease-in-out;
  opacity: ${props => (props.open ? 1 : 0)};
  z-index: ${props => (props.open ? 200 : -1)};
  pointer-events: ${props => (props.open ? "auto" : "none")};

  @media (orientation: landscape) {
    width: 420px;
    top: calc(${props => props.theme.layout.headerHeight});
    left: calc(50% - 210px);
  }

  @media ${device.laptop} {
    top: calc(${props => props.theme.layout.headerHeight} + 1rem);
  }
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  height: 100%;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 30px;

  @media (orientation: landscape) {
    font-size: 24px;
  }

  @media ${device.laptop} {
    font-size: 30px;
  }
`;

const Item = styled.li`
  margin: 0 0 1rem;
  width: 100%;

  @media (orientation: landscape) {
    margin: 0 0 0.5rem;
  }

  @media ${device.laptop} {
    margin: 0 0 1rem;
  }

  a {
    color: ${props => props.theme.color.body};
    text-decoration: none;

    &:hover {
      opacity: 0.5;
    }

    &.active {
      span {
        position: relative;
        color: #78bb5d;

        /* &:before {
          content: "";
          width: 10px;
          height: 10px;
          position: absolute;
          left: -2rem;
          top: 7px;
          border-radius: 50%;
          background: #5BC0EB;
        } */
      }
    }
  }

  &:last-of-type {
    margin: 0;
  }
`;

const Secondary = styled.ul`
  margin: 4rem 0 0;
  padding: 0;
  font-size: 11px;
  display: flex;
  justify-content: center;
  list-style: none;
  text-transform: uppercase;
  font-family: ${props => props.theme.type.new};
  font-weight: 800;
  text-align: center;

  @media (orientation: landscape) {
    margin: 2rem 0 0;
  }

  @media ${device.laptop} {
    font-size: 14px;
    margin: 4rem 0 0;
  }

  li {
    width: auto;
    text-align: center;
    margin: 0 1rem 2rem 0;
  }
`;

const Nav = props => {
  // console.log(props)
  return (
    <Wrapper {...props}>
      <List>
        <Item>
          <NavLink to="/shop">
            <span>Shop</span>
          </NavLink>
        </Item>
        <Item>
          <NavLink to="/projects">
            <span>Projects</span>
          </NavLink>
        </Item>
        <Item>
          <NavLink to="/lunch-break">
            <span>Lunch Break</span>
          </NavLink>
        </Item>
        <Item>
          <NavLink to="/community">
            <span>Community</span>
          </NavLink>
        </Item>
        <Item>
          <NavLink to="/about">
            <span>About</span>
          </NavLink>
        </Item>
      </List>
      <Secondary>
        <Item>
          <NavLink to="/terms">Terms</NavLink>
        </Item>
        <Item>
          <NavLink to="/faqs">FAQs</NavLink>
        </Item>
        <Item>
          <a href="https://instagram.com/foulstudios">Instagram</a>
        </Item>
        <Item>
          <NavLink to="/archive">Archive</NavLink>
        </Item>
        <Item>
          <a href="mailto:yo@foulstudios.com">Contact</a>
        </Item>
      </Secondary>
    </Wrapper>
  );
};
export default Nav;
