import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { fetchBlog } from '../redux/actions/DiaryActions'
import DiaryPost from '../components/DiaryPost'
import { device } from '../utils/devices'
import { withRouter, Link } from 'react-router-dom'

const Wrapper = styled.div`
  border-top: 1px solid rgba(0,0,0,0.12);
  font-family: ${props => props.theme.type.new};
  padding: 2rem 1rem;
  text-align: center;

  @media ${device.laptop}{
    padding: 2rem;
    margin: 3rem auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2rem;
  }
`;

const Head = styled.div`
  display: flex;
  grid-column: span 4;
  font-family: ${props => props.theme.type.new};
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;

  a {
    color: black;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;

    @media ${device.laptop}{
      font-size: 1.5rem;
    }
  }

  h2 {
    font-size: 16px;

    @media ${device.laptop}{
      font-size: 1.5rem;
    }
  }
`;

class RelatedPosts extends Component {

  state = {
    isLoaded: false,
  }

  componentDidMount(){
    this.props.fetchBlog()
    .then(res => {
      console.log(res)
      this.setState({ isLoaded: true })
    })
  }

  renderFeed = () => {

    const { diaryPosts } = this.props;
    const _diaryPosts = [...diaryPosts];
    const related = _diaryPosts.splice(_diaryPosts.findIndex(({uid}) => uid !== this.props.uid), 4);
    return related.map((post, index) => {
      return (
        <DiaryPost
          key={index}
          uid={post.uid}
          title={post.data.title[0].text}
          date={post.first_publication_date}
          thumbnail={post.data.thumbnail}
        />
      )
    })
  }

  render() {

    let uids = [];

    Object.keys(this.props.diaryPosts).map(post => {
      return uids = [this.props.diaryPosts[post].uid, ...uids]
    })

    let currentIndex = uids.indexOf(this.props.uid);
    let prev = uids[currentIndex + 1];
    let next = uids[currentIndex - 1];

    return (
      <Wrapper>
        <Head>
          {typeof prev !== 'undefined'
            ? <Link to={`/random/${prev}`}>Prev</Link>
            : <span> </span>
          }
          <h2>You May Also Like</h2>
          {typeof next !== 'undefined'
            ? <Link to={`/random/${next}`}>Next</Link>
            : <span> </span>
          }
        </Head>
        {this.state.isLoaded &&
          this.renderFeed()
        }
      </Wrapper>
    );
  }

}

export default withRouter(connect(state => ({
  diaryPosts: state.diaryPosts
}), { fetchBlog })(RelatedPosts));
