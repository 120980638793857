import React from 'react';
import styled from 'styled-components';
import { device } from '../utils/devices';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
  poiner-events: all;

  @media ${device.laptop}{
    width: 10vw;
    min-width: 200px;
  }

  &:after {
    font-family: ${props => props.theme.type.heading};
    content: '\\2935\uFE0E';
    position: absolute;
    top: 18px;
    right: 1rem;
    font-weight: 500;
    font-size: 20px;
    pointer-events: none;
    z-index: 10;
  }
`;

const StyledSelect = styled.select`
  appearance: none;
  border: 2px solid black;
  padding: 1rem;
  width: 100%;
  border-radius: 0;
  height: 3vw;
  min-height: 60px;
  outline: 0;
  font-family: ${props => props.theme.type.secondary};
  font-size: 18px;
  font-weight: 500;
  position: relative;
  box-shadow: 0px 2px 10px rgba(0,0,0,0.12);
  z-index: 5;
  pointer-events: all;
  background: white;
`;

const Select = (props) => {

  const renderVariants = props.variants.map((variant, index) => {
    const { id, inventory_quantity, title} = variant;
    return (
      <option key={index} value={id} disabled={inventory_quantity === 0}>
        {title}
      </option>
    )
  })

  return (
    <Wrapper className='select-wrapper'>
      <StyledSelect value={props.value} onChange={props.onChange}>
        <option>{props.name}</option>
        {renderVariants}
      </StyledSelect>
    </Wrapper>
  )
}

export default Select;
