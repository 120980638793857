import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import Logo from './Logo'
import Nav from './Nav'
import Cart from './Cart'
import CartTray from './CartTray'
import { CSSTransition } from 'react-transition-group'
import { toggleCart, openCart, closeCart, openNav, closeNav, toggleNav } from '../redux/actions/NavActions'
import { device } from '../utils/devices'
import Menu from './Menu'
import { isBrowser } from 'react-device-detect'
import { withRouter } from 'react-router-dom'
import ShopHeader from './ShopHeader'
import Headroom from 'react-headroom'

const Container = styled.div`
  font-family: ${props => props.theme.type.heading};
  z-index: 999;
  top: 0;
  text-align: center;
  align-items: center;
  position: fixed;
  padding: 1rem;
  box-sizing: border-box;
  width: 100vw;

  @media (orientation: landscape){
    width: calc(420px + 2rem);
    left: calc(50% - 210px - 1rem);
  }
`;

const Head = styled.header`
  display: flex;
  position: relative;
  z-index: 999;
  height: 56px;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  background: ${props => props.theme.color.primary};
`;

class Header extends Component {

  state = {
    showShopHeader: false,
    scrollPos: window.pageYOffset
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = e => {

    const { scrollPos } = this.state;
    const currentScrollPos = window.pageYOffset;
    const offset = Math.abs(currentScrollPos - scrollPos);

    if(offset > 15){
      if(this.props.nav.navOpen){
        this.props.closeNav()
      }

      if(this.props.nav.cartOpen){
        this.props.closeCart()
      }
    }

    this.setState({
      scrollPos: currentScrollPos
    })
  }

  handleClick = () => {
    const { cart } = this.props;
    // const path = location.pathname.split('/')[0];
    // console.log(path)
    console.log(typeof cart.lineItems)
    if(typeof cart.lineItems === 'undefined' || cart.lineItems.length === 0){
      this.props.history.push('/shop')
    } else {
      if(this.props.nav.navOpen){
        this.props.toggleNav()
      }
      this.props.toggleCart()
    }
  }

  handleNavClick = () => {
    if(this.props.nav.cartOpen){
      this.props.toggleCart()
    }
    this.props.toggleNav()
  }

  render(){

    return (
      <Container>
        <Head
          className='header'
          onMouseEnter={() => {
            if(isBrowser && this.props.location.pathname === '/shop' && !this.state.showShopHeader){
              this.setState({ showShopHeader: true })
            }
          }}
          onMouseLeave={() => {
            if(isBrowser && this.props.location.pathname === '/shop' && this.state.showShopHeader){
              this.setState({ showShopHeader: false })
            }
          }}
        >
          <Menu onClick={() => {this.handleNavClick()}} open={this.props.nav.navOpen} />
          <Nav open={this.props.nav.navOpen} onClick={this.handleNavClick}/>
          <Logo />
          <Cart onClick={this.handleClick}/>
          <CSSTransition classNames="tray" in={this.props.nav.cartOpen} timeout={0}>
            <>
              {this.props.nav.cartOpen && <CartTray className="tray" />}
            </>
          </CSSTransition>
        </Head>
        {this.props.location.pathname.split('/')[1] === 'shop' && !this.props.location.pathname.split('/')[3] &&
          <Headroom disableInlineStyles disable={this.state.showShopHeader}>
            <ShopHeader open={this.state.showShopHeader} />
          </Headroom>
        }
      </Container>
    )
  }
}

export default withRouter(connect(state => ({
  nav: state.nav,
  cart: state.cart
}), { toggleCart, openCart, closeCart, openNav, closeNav, toggleNav })(Header));
