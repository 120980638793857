import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { fetchProjects } from "../redux/actions/PostActions";
import ReactPlayer from "react-player";
import { device } from "../utils/devices";
import { isBrowser } from "react-device-detect";
import Project from "../components/Project";
import { Helmet } from "react-helmet";
import { closeNav, closeCart } from "../redux/actions/NavActions";

const Wrapper = styled.div`
  height: 100vh;

  @media ${device.laptop} {
    height: 100vh;
    margin: 0;
    box-sizing: border-box;
  }

  &:after {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: black;
    z-index: 1;
  }
`;

const SKU = styled.div`
  font-size: 15vw;
  line-height: 8vw;
  font-weight: 600;
  display: block;
  min-width: 20vw;
  z-index: 11;
  text-align: center;
  font-family: ${props => props.theme.type.new};
  opacity: ${props => (props.active ? 0.7 : 1)};
  transition: 0.2s all ease-in-out;
`;

const Info = styled.div`
  font-family: ${props => props.theme.type.heading};
  text-transform: uppercase;
  line-height: 2vw;
  font-size: 2vw;
  height: 100%;
  opacity: ${props => (props.active ? 0.7 : 1)};
  z-index: 11;
  display: flex;
  justify-content: center;
  flex-direction: column;
  transition: 0.2s all ease-in-out;

  span {
    font-size: 18px;
    font-family: ${props => props.theme.type.new};
  }

  p {
    margin: 0;
    width: 20vw;
    display: flex;
    align-items: center;
  }
`;

const Index = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 2rem;
  box-sizing: border-box;
  margin: 0;
  z-index: 10;
  height: fill-available;
  line-height: 6em;
  position: relative;

  li {
    font-family: ${props => props.theme.type.new};
    font-weight: 300;
    font-size: 1vw;
    display: flex;
    align-items: center;
    margin-bottom: 6rem;
    box-sizing: border-box;
    transition: 1s all ease-in-out;

    &:first-of-type {
      margin-top: 10vh;
    }

    a {
      color: white;
      text-decoration: none;
      display: flex;
      align-items: center;
      height: 100%;
      box-sizing: border-box;
    }
  }
`;

const Image = styled.div`
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: black;
  opacity: ${props => (props.active ? 1 : 0)};
  transition: 0.2s all ease-in-out;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.9;
  }
`;

const Video = styled.div`
  pointer-events: none;
  background: black;
  opacity: ${props => (props.active ? 1 : 0)};
  transition: 0.2s all ease-in-out;

  .player {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw !important;
    height: 100vh !important;
    z-index: ${props => (props.active ? 5 : 1)};

    iframe,
    video {
      object-fit: cover;
      opacity: 0.8;
      appearance: none;

      &::media-controls {
        display: none !important;
        -webkit-appearance: none;
        opacity: 0;
      }

      &::media-controls-play-button {
        display: none !important;
        -webkit-appearance: none;
        opacity: 0;
      }

      &::-webkit-media-controls-overlay-enclosure {
        display: none !important;
        -webkit-appearance: none;
        opacity: 0;
      }

      &::media-controls-overlay-play-button {
        display: none !important;
        -webkit-appearance: none;
        opacity: 0;
      }

      &::media-controls-start-playback-button {
        display: none !important;
        -webkit-appearance: none;
        opacity: 0;
      }
    }
  }
`;

const MobileGrid = styled.div`
  a {
    color: white;
    text-decoration: none;
  }

  li {
    list-style: none;
    position: relative;
    color: white;
    height: calc(100vh - 2rem);
    background: black;

    div {
      position: absolute;
      box-sizing: border-box;
      padding: 1rem;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      z-index: 1;
      display: flex;
      align-items: center;
      font-family: ${props => props.theme.type.new};

      span {
        font-size: 200px;
        margin-right: 2rem;
      }

      p {
        margin: 0;
        font-size: 36px;
        line-height: 36px;
        font-family: ${props => props.theme.type.heading};
        text-transform: uppercase;
      }
    }

    picture {
      padding: 0;
      margin: 0;
      font-size: 0;
      line-height: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
        opacity: 0.9;
      }
    }
  }
`;

class Projects extends Component {
  state = {
    projects: [],
    isLoaded: false,
    active: 0
  };

  handleEnter = index => {
    if (this.props.nav.cartOpen) {
      this.props.closeCart();
    }

    if (this.props.nav.navOpen) {
      this.props.closeNav();
    }

    this.setState({ active: index });
  };

  componentDidMount() {
    this.props.fetchProjects().then(res => {
      this.setState({ projects: res, isLoaded: true });
    });
  }

  renderOverlay = (project, index) => {
    let video = null;

    project.data.body.map(node => {
      if (node.slice_type === "video") {
        return (video = node.primary.video.url);
      } else {
        return false;
      }
    });

    if (video) {
      return (
        <Video active={this.state.active === index}>
          <ReactPlayer
            loop
            className="player"
            playing
            muted
            playsinline
            url={video}
            controls={false}
            config={{
              file: {
                attributes: {
                  controls: false,
                  poster: project.data.featured_image.url
                }
              }
            }}
          />
        </Video>
      );
    } else {
      return (
        <Image active={this.state.active === index}>
          <img
            alt={project.data.title[0].text}
            src={project.data.featured_image.url}
          />
        </Image>
      );
    }
  };

  render() {
    const { isLoaded, projects, active } = this.state;

    return (
      <div>
        {isLoaded && (
          <Wrapper>
            <Helmet title="Projects — Foul Studios" />
            {isBrowser ? (
              <>
                <Index>
                  {projects.map((project, index) => (
                    <li
                      onMouseEnter={() => this.handleEnter(index)}
                      key={project.data.sku}
                    >
                      <Link to={`/projects/${project.uid}`}>
                        <SKU active={index === active}>{project.data.sku}</SKU>
                        <Info active={index === active}>
                          <p>{project.data.title[0].text}</p>
                          <span>View Project &rarr;</span>
                        </Info>
                        {this.renderOverlay(project, index)}
                      </Link>
                    </li>
                  ))}
                </Index>
              </>
            ) : (
              <MobileGrid>
                {projects.map((project, index) => {
                  let video = null;
                  project.data.body.map(node => {
                    if (node.slice_type === "video") {
                      console.log(node);
                      return (video = node.primary.video.url);
                    } else {
                      return false;
                    }
                  });

                  return (
                    <Project
                      key={index}
                      size="XLarge"
                      background={project.data.background}
                      uid={project.uid}
                      sku={project.data.sku}
                      image={
                        project.data.featured_image.url
                          ? project.data.featured_image.url
                          : null
                      }
                      video={video}
                      title={project.data.title[0].text}
                    />
                  );
                })}
              </MobileGrid>
            )}
          </Wrapper>
        )}
      </div>
    );
  }
}

export default connect(
  state => ({
    nav: state.nav,
    posts: state.posts
  }),
  { fetchProjects, closeCart, closeNav }
)(Projects);
