import React from 'react'
import ExpandableMenu from './ExpandableMenu'

const DeliveryInfo = () => (
  <ExpandableMenu title='Delivery & Shipping'>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut viverra eleifend orci, et tincidunt massa semper a. Praesent aliquam mi id felis posuere malesuada. Donec est est, gravida semper nibh tempus, rhoncus blandit nisi.</p>
  </ExpandableMenu>
);

export default DeliveryInfo;
